import { IFetch } from 'constants/types';

export const WS_GL_POSTING = 'WS_GL_POSTING';
export const WS_FILE_UPLOAD = 'WS_FILE_UPLOAD';
export const WS_CLEAR_DATA = 'WS_CLEAR_DATA';

export interface WebsocketState extends IFetch {
  posting: Array<IGLPostingProgress>;
  upload: Array<IFileUploadProgress>
}

export type IGLPostingProgress = {
  code: string;
  progress: number;
  message?: string;
  company_id: string;
};

export type IGLPosting = {
  post: Array<IGLPostingProgress>;
};

export type  IFileUploadProgress = {
  requestId: string;
  type: string;
  filename: string;
  progress: number;
  message?: string;
  errors?: Array<string>;
  company_id: string;
}

export type IFileUpload = {
  upload: Array<IFileUploadProgress>;
}
