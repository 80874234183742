import React from 'react';
import Moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';

import { format } from 'utilities/parser';
import Lagoon from 'components/SaleInfo/Lagoon';
import SaleItems from 'components/SaleInfo/SaleItems';
import { ISale } from 'types';

const theme = 'primary';

interface IProps {
  sale?: ISale;
  show: boolean;
  handleExit?: () => void;
}

const SaleInfo: React.FC<IProps> = ({
  sale,
  show,
  handleExit,
}) => (
  <Modal
    show={show}
    backdrop="static"
    keyboard={true}
    onHide={handleExit}
    dialogClassName={`modal-dialog-right`}
  >
    <Modal.Header closeButton={true}>
      <Modal.Title>
        JO <span className={`fw-700 color-primary-500`}>#{sale?.sale_code}</span>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className={`card border-top-0`}>
        <div className="card-body">
          <div className="row">
            <div className="col col-md-4 fw-700">Date:</div>
            <div className="col col-md-8">{Moment(sale?.sale_date).tz('Asia/Manila').format('MMMM DD, YYYY h:mm A')}</div>
          </div>
          <div className="row">
            <div className="col col-md-4 fw-700">Customer:</div>
            <div className="col col-md-8">{sale?.customer_name}</div>
          </div>
          <div className="row">
            <div className="col col-md-4 fw-700">Staff:</div>
            <div className="col col-md-8">{sale?.staff_name}</div>
          </div>
          <div className="row">
            <div className={`col col-md-4 h4 color-primary-500`}>Total Amount:</div>
            <div className={`col col-md-8 h4 color-primary-500`}>₱ {sale?.total_amount ? format(sale?.total_amount) : 0.0}</div>
          </div>
          {sale?.service_items && sale?.service_items?.length > 0 && (
            <SaleItems items={sale?.service_items} theme={theme} itemType="Services" />
          )}
          {sale?.lagoon_items && sale?.lagoon_items?.length > 0 && <Lagoon items={sale?.lagoon_items} theme={theme} itemType="Lagoons" />}
          {sale?.product_items && sale?.product_items?.length > 0 && (
            <SaleItems items={sale?.product_items} theme={theme} itemType="Products" />
          )}
        </div>
      </div>
    </Modal.Body>
  </Modal>
);


export default SaleInfo;
