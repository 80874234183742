import React, { memo } from 'react';

import Logo from 'assets/images/pesakan-logo.png';

const LoginHeader: React.FC = () => (
  <div className="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
    <div className="d-flex align-items-center container p-0">
      <div className="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9">
        <div className="page-logo-link press-scale-down d-flex align-items-center">
          <img src={Logo} alt="Pesakan Laundry Services" aria-roledescription="logo" />
        </div>
      </div>
    </div>
  </div>
);

export default memo(LoginHeader);
