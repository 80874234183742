import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  IUOMConvert,
  IUnitOfMeasure,
  IUnitOfMeasures,
  IUnitOfMeasureCreate,
  IUnitOfMeasureUpdate,
  IUnitConversionComplete,
  UOM_CREATE,
  UOM_UPDATE,
  UOM_FIND_BY_ID,
  UOM_FIND_BY_CODE,
  UOM_FIND_FIRST,
  UOM_FIND_LAST,
  UOM_FIND_ALL,
  UOM_CONVERT,
  UOM_CLEAR_DATA,
} from 'types/uom.types';

const uomCreate = createAsyncThunk<IUnitOfMeasure, IUnitOfMeasureCreate>(UOM_CREATE, async (data) => request.post('/uoms', { ...data }));
const uomUpdate = createAsyncThunk<IUnitOfMeasure, IUnitOfMeasureUpdate>(UOM_UPDATE, async (data) =>
  request.put(`/uoms/id/${data.id}`, { ...data })
);
const uomFindByID = createAsyncThunk<IUnitOfMeasure, number, any>(UOM_FIND_BY_ID, async (id, query) =>
  request.get(`/uoms/id/${id}`, query)
);
const uomFindByCode = createAsyncThunk<IUnitOfMeasure, string>(UOM_FIND_BY_CODE, async (code) => request.get(`/uoms/${code}`));
const uomFindFirst = createAsyncThunk<IUnitOfMeasure>(UOM_FIND_FIRST, async () => request.get('/uoms/find/first'));
const uomFindLast = createAsyncThunk<IUnitOfMeasure>(UOM_FIND_LAST, async () => request.get('/uoms/find/last'));
const uomFindAll = createAsyncThunk<IUnitOfMeasures, Partial<ITable>>(UOM_FIND_ALL, async (query) => request.get('/uoms', query));
const uomClearData = createAction(UOM_CLEAR_DATA);

const uomConversion = createAsyncThunk<IUnitConversionComplete, IUOMConvert>(UOM_CONVERT, async (data) => request.post('/uoms/conversion', data));

const UnitOfMeasureActions = {
  uomCreate,
  uomUpdate,
  uomFindByID,
  uomFindByCode,
  uomFindFirst,
  uomFindLast,
  uomFindAll,
  uomClearData,
  uomConversion,
};

export default UnitOfMeasureActions;
