import { createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';

import { IAccountDefault, CreateDefaultAccount, ACCOUNT_DEFAULT_SAVE, ACCOUNT_DEFAULT_GET } from 'types/account.default.types';

const save = createAsyncThunk<IAccountDefault, CreateDefaultAccount>(ACCOUNT_DEFAULT_SAVE, async (data) =>
  request.post(`/account_default`, data)
);
const get = createAsyncThunk<IAccountDefault>(ACCOUNT_DEFAULT_GET, async () => request.get(`/account_default`));

const AccountDefaultActions = { save, get };

export default AccountDefaultActions;
