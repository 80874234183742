import { IFetch } from 'constants/types';

export const ACCOUNT_MAPPING_GET = 'ACCOUNT_MAPPING_GET';
export const ACCOUNT_MAPPING_CREATE = 'ACCOUNT_MAPPING_CREATE';
export const ACCOUNT_MAPPING_UPDATE = 'ACCOUNT_MAPPING_UPDATE';
export const ACCOUNT_MAPPING_FIND_BY_ID = 'ACCOUNT_MAPPING_FIND_BY_ID';
export const ACCOUNT_MAPPING_FIND_BY_CODE = 'ACCOUNT_MAPPING_FIND_BY_CODE';
export const ACCOUNT_MAPPING_FIND_FIRST = 'ACCOUNT_MAPPING_FIND_FIRST';
export const ACCOUNT_MAPPING_FIND_LAST = 'ACCOUNT_MAPPING_FIND_LAST';
export const ACCOUNT_MAPPING_FIND_ALL = 'ACCOUNT_MAPPING_FIND_ALL';
export const ACCOUNT_MAPPING_CLEAR_DATA = 'ACCOUNT_MAPPING_CLEAR_DATA';

export interface AccountMappingState extends IFetch {
  data: IAccountMapping | undefined;
  list: IAccountMapping[];
  total: number;
}

export type IAccountMapping = {
  id: number;
  mapping_code: string;
  document_id: string;
  document_code?: string;
  document_description?: string;
  bank_id: number;
  bank_code?: string;
  bank_name?: string;
  item_id: string;
  item_code?: string;
  item_name?: string;
  customer_id: string;
  customer_code?: string;
  customer_name?: string;
  supplier_id: number;
  supplier_code?: string;
  supplier_name?: string;
  account_id: number;
  combination_code?: string;
  combination_name?: string;
  company_id: string;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};

export type IAccountMappingCreate = {
  document_id: string;
  item_id: string;
  bank_id: number;
  customer_id: string;
  supplier_id: number;
  account_id: number;
};

export interface IAccountMappingUpdate extends IAccountMappingCreate {
  id: number;
}

export type IAccountMappings = {
  mappings: Array<IAccountMapping>;
  total: number;
};

export type IGetAccountMapping = {
  document_id: string;
  bank_id?: number;
  customer_id?: string;
  supplier_id?: number;
};
