import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { AccountCombinationState } from 'types/account.combination.types';
import AccountCombinationAction from 'store/actions/account.combination.action';

export const AccountCombinationInitialState: AccountCombinationState = {
  list: [],
  summary: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type AccountCombinationType = typeof AccountCombinationInitialState;

const handleAccountCombination = (state: AccountCombinationState, action: any) => {
  const { accountCombination = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: accountCombination,
    next,
    previous,
  };
};

const accountCombinationReducer = createSlice({
  name: 'AccountCombination',
  initialState: AccountCombinationInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AccountCombinationAction.accountCombinationFindAll.fulfilled, (state, action) => {
        const { accounts: list = [], total = 0 } = get(action, 'payload.data', { accounts: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(AccountCombinationAction.accountCombinationSummary.fulfilled, (state, action) => ({
        ...state,
        summary: get(action, 'payload.data', []),
      }))
      .addCase(AccountCombinationAction.accountCombinationCreate.fulfilled, handleAccountCombination)
      .addCase(AccountCombinationAction.accountCombinationUpdate.fulfilled, handleAccountCombination)
      .addCase(AccountCombinationAction.accountCombinationFindByID.fulfilled, handleAccountCombination)
      .addCase(AccountCombinationAction.accountCombinationFindByCode.fulfilled, handleAccountCombination)
      .addCase(AccountCombinationAction.accountCombinationFindFirst.fulfilled, handleAccountCombination)
      .addCase(AccountCombinationAction.accountCombinationFindLast.fulfilled, handleAccountCombination)
      .addCase(AccountCombinationAction.accountCombinationClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default accountCombinationReducer.reducer;
