import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { AccountMappingState } from 'types/account.mapping.types';
import AccountMappingAction from 'store/actions/account.mapping.action';

export const AccountMappingInitialState: AccountMappingState = {
  data: undefined,
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type AccountMappingType = typeof AccountMappingInitialState;

const handleAccountMapping = (state: AccountMappingState, action: any) => {
  const { mapping = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: mapping,
    next,
    previous,
  };
};

const mappingReducer = createSlice({
  name: 'AccountMapping',
  initialState: AccountMappingInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AccountMappingAction.mappingFindAll.fulfilled, (state, action) => {
        const { mappings: list = [], total = 0 } = get(action, 'payload.data', { mappings: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(AccountMappingAction.mappingCreate.fulfilled, handleAccountMapping)
      .addCase(AccountMappingAction.mappingUpdate.fulfilled, handleAccountMapping)
      .addCase(AccountMappingAction.mappingFindByID.fulfilled, handleAccountMapping)
      .addCase(AccountMappingAction.mappingFindByCode.fulfilled, handleAccountMapping)
      .addCase(AccountMappingAction.mappingFindFirst.fulfilled, handleAccountMapping)
      .addCase(AccountMappingAction.mappingFindLast.fulfilled, handleAccountMapping)
      .addCase(AccountMappingAction.mappingClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default mappingReducer.reducer;
