import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';
import {
  IUser,
  JwtIuser,
  IUserLogin,
  IUserCreate,
  IUserUpdate,
  LMS_TOKEN,
  USER_LOGIN,
  USER_SESSION,
  USER_FIND_DATA,
  USER_CREATE,
  USER_UPDATE,
  USER_FIND_FIRST,
  USER_FIND_LAST,
  USER_FIND_ALL,
  USER_DATA_INFO,
  USER_MENU_POSITION,
  USER_CLEAR_DATA,
  USER_CLEAR_TOKEN,
  USER_SET_COMPANY,
  USER_REFRESH_TOKEN,
} from 'types/user.types';

const setLmsToken = createAction<any>(LMS_TOKEN);
const userLogin = createAsyncThunk<IUser, IUserLogin>(USER_LOGIN, async (params) =>
  request.post('/token', { grant_type: 'password', ...params })
);
// const userLogout = createAsyncThunk<any, Partial<number>>(USER_LOGOUT, (userID) => rpc('Logout', { user_id: userID }));
const userLogout = createAction(USER_CLEAR_TOKEN);
const userUpdateSession = createAction<JwtIuser>(USER_SESSION);
const userFindData = createAsyncThunk<IUser, Partial<string>>(USER_FIND_DATA, (code) => request.get(`/users/${code}`));
const userCreate = createAsyncThunk<IUser, IUserCreate>(USER_CREATE, (data) => request.post('/users', data));
const userUpdate = createAsyncThunk<IUser, IUserUpdate>(USER_UPDATE, (data) => request.put(`/users/id/${data.id}`, { ...data }));
const userFindFirst = createAsyncThunk<IUser>(USER_FIND_FIRST, async () => request.get('/users/find/first'));
const userFindLast = createAsyncThunk<IUser>(USER_FIND_LAST, async () => request.get('/users/find/last'));
const userFindAll = createAsyncThunk<IUser, ITable>(USER_FIND_ALL, async (query) => request.get('/users', query));
const userDataInfo = createAsyncThunk<IUser, { created_by: string; last_saved_by: string }>(USER_DATA_INFO, async (data) =>
  request.post('/users/info', { ...data })
);
const userMenuPosition = createAsyncThunk<IUser, { id: string; position: 'top' | 'left' }>(USER_MENU_POSITION, async ({ id, position }) =>
  request.put(`/users/menu/${id}`, { position })
);
const userClearData = createAction(USER_CLEAR_DATA);
const clearToken = createAction(USER_CLEAR_TOKEN);
const setCompany = createAction<string | undefined>(USER_SET_COMPANY);
const refreshToken = createAction<any, any>(USER_REFRESH_TOKEN);

const UserActions = {
  setLmsToken,
  userLogin,
  userLogout,
  userUpdateSession,
  userFindData,
  userCreate,
  userUpdate,
  userFindFirst,
  userFindLast,
  userFindAll,
  userDataInfo,
  userMenuPosition,
  userClearData,
  clearToken,
  setCompany,
  refreshToken,
};

export default UserActions;
