import { findIndex } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

import { WebsocketState, IGLPostingProgress, IFileUploadProgress } from 'types/websocket.types';
import WebSocketAction from 'store/actions/websocket.action';

export const WebSocketInitialState: WebsocketState = {
  posting: [],
  upload: [],
  fetching: false,
  fetched: false,
  error: null,
};

export type WebSocketType = typeof WebSocketInitialState;

const voucherReducer = createSlice({
  name: 'WebSocket',
  initialState: WebSocketInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(WebSocketAction.glPosting, (state, action) => {
        const progress: IGLPostingProgress = action.payload;
        const key = findIndex(state.posting, { code: progress.code });
        const posting = [...state.posting];
        if (key > -1) {
          posting[key] = progress;
        } else {
          posting.push(progress);
        }
        return { ...state, posting };
      })
      .addCase(WebSocketAction.fileUpload, (state, action) => {
        const progress: IFileUploadProgress = action.payload;
        const key = findIndex(state.upload, { requestId: progress.requestId });
        const upload = [...state.upload];
        if (key > -1) {
          upload[key] = progress;
        } else {
          upload.push(progress);
        }
        return { ...state, upload };
      })
      .addCase(WebSocketAction.wsClearData, () => ({ ...WebSocketInitialState }));
  },
});

export default voucherReducer.reducer;
