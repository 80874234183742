import React, { memo } from 'react';

interface IUnauthorized {
  message?: string;
}

const Unauthorized: React.FC<IUnauthorized> = ({ message }) => {
  return (
    <div className="h-alt-f d-flex flex-column align-items-center justify-content-center text-center">
      <h1 className="page-error color-fusion-500">
        ERROR <span className="text-gradient">403</span>
        <small className="fw-500">FORBIDDEN! {message || ''}</small>
      </h1>
      <h3 className="fw-500 mb-5">Sorry, you are not authorized to view this page. Please contact Pesakan Admin for authorization.</h3>
    </div>
  );
};

export default memo(Unauthorized);
