import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  IAccountCompany,
  ICreateAccountCompany,
  IUpdateAccountCompany,
  ACCOUNT_COMPANY_CREATE,
  ACCOUNT_COMPANY_UPDATE,
  ACCOUNT_COMPANY_FIND_BY_ID,
  ACCOUNT_COMPANY_FIND_BY_CODE,
  ACCOUNT_COMPANY_FIND_FIRST,
  ACCOUNT_COMPANY_FIND_LAST,
  ACCOUNT_COMPANY_FIND_ALL,
  ACCOUNT_COMPANY_CLEAR_DATA,
} from 'types/account.types';

const accountCompanyCreate = createAsyncThunk<IAccountCompany, ICreateAccountCompany>(ACCOUNT_COMPANY_CREATE, async (data) =>
  request.post(`/account_company`, data)
);
const accountCompanyUpdate = createAsyncThunk<IAccountCompany, IUpdateAccountCompany>(ACCOUNT_COMPANY_UPDATE, async (data) =>
  request.put(`/account_company/id/${data.id}`, data)
);
const accountCompanyFindByID = createAsyncThunk<IAccountCompany, string | number>(ACCOUNT_COMPANY_FIND_BY_ID, async (id) =>
  request.get(`/account_company/id/${id}`)
);
const accountCompanyFindByCode = createAsyncThunk<IAccountCompany, string>(ACCOUNT_COMPANY_FIND_BY_CODE, async (code) =>
  request.get(`/account_company/${code}`)
);
const accountCompanyFindFirst = createAsyncThunk<IAccountCompany>(ACCOUNT_COMPANY_FIND_FIRST, async () =>
  request.get('/account_company/find/first')
);
const accountCompanyFindLast = createAsyncThunk<IAccountCompany>(ACCOUNT_COMPANY_FIND_LAST, async () =>
  request.get('/account_company/find/last')
);
const accountCompanyFindAll = createAsyncThunk<IAccountCompany[], Partial<ITable>>(ACCOUNT_COMPANY_FIND_ALL, async (query) =>
  request.get('/account_company', query)
);
const accountCompanyClearData = createAction(ACCOUNT_COMPANY_CLEAR_DATA);

const AccountCompanyActions = {
  accountCompanyCreate,
  accountCompanyUpdate,
  accountCompanyFindByID,
  accountCompanyFindByCode,
  accountCompanyFindFirst,
  accountCompanyFindLast,
  accountCompanyFindAll,
  accountCompanyClearData,
};

export default AccountCompanyActions;
