import { IFetch } from 'constants/types';

export const ITEM_CATEGORY_CREATE = 'ITEM_CATEGORY_CREATE';
export const ITEM_CATEGORY_UPDATE = 'ITEM_CATEGORY_UPDATE';
export const ITEM_CATEGORY_FIND_BY_ID = 'ITEM_CATEGORY_FIND_BY_ID';
export const ITEM_CATEGORY_FIND_BY_CODE = 'ITEM_CATEGORY_FIND_BY_CODE';
export const ITEM_CATEGORY_FIND_FIRST = 'ITEM_CATEGORY_FIND_FIRST';
export const ITEM_CATEGORY_FIND_LAST = 'ITEM_CATEGORY_FIND_LAST';
export const ITEM_CATEGORY_FIND_ALL = 'ITEM_CATEGORY_FIND_ALL';
export const ITEM_CATEGORY_CLEAR_DATA = 'ITEM_CATEGORY_CLEAR_DATA';

export interface ItemCategoryState extends IFetch {
  data: IItemCategory | undefined;
  list: IItemCategory[];
  total: number;
}

export type IItemCategory = {
  id: number;
  category_code: string;
  category_name: string;
  is_valid: number;
  company_id: number;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};

export type IItemCategoryCreate = {
  category_code: string;
  category_name: string;
  is_valid: number;
};

export interface IItemCategoryUpdate extends IItemCategoryCreate {
  id: number;
};

export type IItemCategories = {
  item_categories: Array<IItemCategory>;
  total: number;
};
