import React, { Suspense, useEffect, useState } from 'react';
import { useRoutes, navigate, useMatch } from 'raviger';
import { useDispatch, useSelector } from 'react-redux';
import { find, forEach, map, flatten, get } from 'lodash';

import { RootState, AppDispatch } from 'store';
import { IRoute } from 'constants/interface';

import AppLinks from 'router/AppLinks';
import AdminRouter from 'router/admin.route';
import HomeRouter from 'router/home.route';
import MasterRouter from 'router/master.route';
// import ReportRouter from 'router/reports.route';
import SetupRouter from 'router/setup.route';
import TransactionRouter from 'router/transaction.route';

import MainLayout from 'containers/MainContainer';
import Login from 'containers/LoginContainer';
import Logout from 'containers/LogoutContainer';
import Home from 'containers/HomeContainer';
import NotFound from 'pages/Layout/NotFound';
import Unauthorized from 'pages/Layout/Unauthorized';
import CustomerLoyaltyContainer from 'containers/Public/CustomerLoyaltyContainer';
import { AccessActions, RoleActions, CompanyActions } from 'store/actions';
import { IAccess } from 'types/access.types';

const PrivateRoutes: any[] = [...AdminRouter, ...HomeRouter, ...MasterRouter, ...SetupRouter, ...TransactionRouter];

const Router: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { access, auth, role, token, company_id } = useSelector((state: RootState) => ({
    access: state.access.access,
    auth: state.user.auth,
    token: state.user.token,
    role: state.role.role,
    company_id: state.user.company_id,
  }));
  const [userRole, setUserRole] = useState<string>();
  const isLoggedIn = token !== '' ? true : false;

  const publicRoutes = ['/customer/loyalty/:customerId'];
  const matchPublicRoute = useMatch(publicRoutes);

  let routes = {
    '/customer/loyalty/:customerId': ({ customerId }: { customerId: string }) => <CustomerLoyaltyContainer customer_id={customerId} />,
    '/': () =>
      handleAuthentication({
        path: '/',
        component: Home,
      }),
    '/unauthorized': () =>
      handleAuthentication({
        path: '/unauthorized',
        component: Unauthorized,
      }),
    '/login': () => <Login />,
    '/logout': () => <Logout />,
  };

  useEffect(() => {
    const userId = auth?.user_id ?? 0;
    const userRoleId = auth?.role_id ?? 0;
    const roleId = role?.id ?? 0;

    const init = async () => {
      const newUserRole = `${userId}-${userRoleId}-${roleId}`;
      if (token && userId === 0) {
        // @TODO: Clear token when no session found
      }

      // Fetch current user role and access
      if (userRole !== newUserRole) {
        if (Number(userId) > 0 && Number(userRoleId) > 0 && Number(roleId) === 0) {
          setUserRole(newUserRole);
          dispatch(RoleActions.roleUserSession(userRoleId));
          dispatch(AccessActions.accessUserSession(userRoleId));
        }
      }
    };

    init();
  }, [auth, token, role, userRole, dispatch]);

  useEffect(() => {
    !auth && !matchPublicRoute && navigate('/', { replace: true });
  }, [auth, matchPublicRoute]);

  useEffect(() => {
    const pathname = window.location.pathname.split('/');
    const currentPath = window.location.pathname;

    if (access.length > 0 && currentPath !== '/' && currentPath !== '/profile') {
      let screenId = '';
      let isValid = false;

      if (pathname[2]) {
        screenId = `${pathname[1]}_${pathname[2]}`;
        isValid = !!find(access, (o: IAccess) => o.screen_id === screenId);
      }

      // Redirect to dashboard if unauthorized
      !isValid && navigate('/unauthorized', { replace: true });
    }
  }, [access]);

  useEffect(() => {
    if (company_id) {
      dispatch(CompanyActions.companySelect(company_id));
    }
  }, [company_id, dispatch]);

  const handleAuthentication = (route: IRoute, params?: any) => {
    if (isLoggedIn) {
      let routeLink = find(
        flatten(map(AppLinks, 'routes')).filter((value) => !!value),
        { path: route.path }
      );
      const isRestricted = get(routeLink, 'companyRestricted', false);
      const screenID = get(routeLink, 'id', route.path);
      const component = route.component && (
        <Suspense fallback={<div>Loading...</div>}>
          {isRestricted && !company_id ? <Unauthorized /> : <route.component screenID={screenID} {...params} />}
        </Suspense>
      );

      return <MainLayout screenID={screenID}>{route.component ? component : <div>{route.path}</div>}</MainLayout>;
    } else {
      return <Login />;
    }
  };

  forEach(PrivateRoutes, (route: IRoute) => {
    routes = {
      ...routes,
      [route.path]: (params?: any) => handleAuthentication(route, params),
    };
  });

  return useRoutes(routes) || <NotFound />;
};

export default Router;
