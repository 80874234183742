import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { AccountDivisionState } from 'types/account.types';
import AccountDivisionAction from 'store/actions/account.division.action';

export const AccountDivisionInitialState: AccountDivisionState = {
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type AccountDivisionType = typeof AccountDivisionInitialState;

const handleAccountDivision = (state: AccountDivisionState, action: any) => {
  const { accountDivision = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: accountDivision,
    next,
    previous,
  };
};

const accountDivisionReducer = createSlice({
  name: 'AccountDivision',
  initialState: AccountDivisionInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AccountDivisionAction.accountDivisionFindAll.fulfilled, (state, action) => {
        const { divisions: list = [], total = 0 } = get(action, 'payload.data', { divisions: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(AccountDivisionAction.accountDivisionCreate.fulfilled, handleAccountDivision)
      .addCase(AccountDivisionAction.accountDivisionUpdate.fulfilled, handleAccountDivision)
      .addCase(AccountDivisionAction.accountDivisionFindByID.fulfilled, handleAccountDivision)
      .addCase(AccountDivisionAction.accountDivisionFindByCode.fulfilled, handleAccountDivision)
      .addCase(AccountDivisionAction.accountDivisionFindFirst.fulfilled, handleAccountDivision)
      .addCase(AccountDivisionAction.accountDivisionFindLast.fulfilled, handleAccountDivision)
      .addCase(AccountDivisionAction.accountDivisionClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default accountDivisionReducer.reducer;
