import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  ISupplier,
  ISuppliers,
  ISupplierCreate,
  ISupplierUpdate,
  SUPPLIER_CREATE,
  SUPPLIER_UPDATE,
  SUPPLIER_FIND_BY_ID,
  SUPPLIER_FIND_BY_CODE,
  SUPPLIER_FIND_FIRST,
  SUPPLIER_FIND_LAST,
  SUPPLIER_FIND_ALL,
  SUPPLIER_CLEAR_DATA,
} from 'types/supplier.types';

const supplierCreate = createAsyncThunk<ISupplier, ISupplierCreate>(SUPPLIER_CREATE, async (data) => request.post('/suppliers', { ...data }));
const supplierUpdate = createAsyncThunk<ISupplier, ISupplierUpdate>(SUPPLIER_UPDATE, async (data) =>
  request.put(`/suppliers/id/${data.id}`, { ...data })
);
const supplierFindByID = createAsyncThunk<ISupplier, number, any>(SUPPLIER_FIND_BY_ID, async (id, query) =>
  request.get(`/suppliers/id/${id}`, query)
);
const supplierFindByCode = createAsyncThunk<ISupplier, string>(SUPPLIER_FIND_BY_CODE, async (code) => request.get(`/suppliers/${code}`));
const supplierFindFirst = createAsyncThunk<ISupplier>(SUPPLIER_FIND_FIRST, async () => request.get('/suppliers/find/first'));
const supplierFindLast = createAsyncThunk<ISupplier>(SUPPLIER_FIND_LAST, async () => request.get('/suppliers/find/last'));
const supplierFindAll = createAsyncThunk<ISuppliers, Partial<ITable>>(SUPPLIER_FIND_ALL, async (query) => request.get('/suppliers', query));
const supplierClearData = createAction(SUPPLIER_CLEAR_DATA);

const SupplierActions = {
  supplierCreate,
  supplierUpdate,
  supplierFindByID,
  supplierFindByCode,
  supplierFindFirst,
  supplierFindLast,
  supplierFindAll,
  supplierClearData,
};

export default SupplierActions;
