import React, { memo } from 'react';

const Footer: React.FC = () => (
  <footer className="page-footer" role="contentinfo">
    <div className="d-flex align-items-center flex-1 text-muted">
      <span className="hidden-md-down fw-700">All Rights Reserved © Copyright 2023</span>
    </div>
  </footer>
);

export default memo(Footer);
