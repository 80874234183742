import { IFetch } from 'constants/types';

export const VOUCHER_CREATE = 'VOUCHER_CREATE';
export const VOUCHER_UPDATE = 'VOUCHER_UPDATE';
export const VOUCHER_FIND_BY_ID = 'VOUCHER_FIND_BY_ID';
export const VOUCHER_FIND_BY_CODE = 'VOUCHER_FIND_BY_CODE';
export const VOUCHER_FIND_FIRST = 'VOUCHER_FIND_FIRST';
export const VOUCHER_FIND_LAST = 'VOUCHER_FIND_LAST';
export const VOUCHER_FIND_ALL = 'VOUCHER_FIND_ALL';
export const VOUCHER_CLEAR_DATA = 'VOUCHER_CLEAR_DATA';

export type VoucherStatus = 'applied' | 'unapplied' | 'posted' | 'cancelled';

export interface VoucherState extends IFetch {
  data: IVoucher | undefined;
  list: IVoucher[];
  total: number;
}

export type IVoucher = {
  id: number;
  voucher_code: string;
  voucher_date: string;
  voucher_status: VoucherStatus;
  total_amount: number;
  purchase_id?: number;
  purchase_code?: string;
  supplier_id: number;
  supplier_code?: string;
  supplier_name?: string;
  supplier_address?: string;
  account_id: number;
  combination_code?: string;
  combination_name?: string;
  notes?: string;
  company_id: string;
  created_by: string;
  created_date: string;
  last_saved_by: string;
  last_saved_date: string;
  details?: Array<IVoucherDetail>;
};

export type IVoucherDetail = {
  id: number;
  line: number;
  toDelete?: boolean | number;
  voucher_id: number;
  voucher_status: VoucherStatus;
  item_id?: string;
  item_code?: string;
  item_name?: string;
  purchase_id?: number
  particulars?: string;
  bank_id?: number;
  bank_code?: string;
  bank_name?: string;
  voucher_amount: number;
  check_number?: string;
  check_date?: string;
  account_id: number;
  combination_code?: string;
  combination_name?: string;
  debit_account_id?: number;
  debit_combination_code?: string;
  debit_combination_name?: string;
  company_id: string;
  created_by: string;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};

export interface CreateVoucherDetail
  extends Pick<
    IVoucherDetail,
    'line' | 'voucher_id' | 'item_id' | 'particulars' | 'bank_id' | 'voucher_amount' | 'voucher_status' | 'check_number' | 'check_date' | 'account_id' | 'debit_account_id'
  > {
  id?: number;
}

export interface UpdateVoucherDetail extends CreateVoucherDetail {
  id?: number;
}

export interface IVoucherCreate
  extends Pick<IVoucher, 'voucher_code' | 'voucher_date' | 'voucher_status' | 'total_amount' | 'supplier_id' | 'account_id' | 'notes'> {
  details: Array<CreateVoucherDetail>;
}

export interface IVoucherUpdate extends IVoucherCreate {
  id?: number;
  details: Array<UpdateVoucherDetail>;
  toDelete: Array<number>;
}

export interface VoucherForm extends IVoucher {
  voucher_status_label: string;
  combination_code: string;
  combination_name: string;
}
