import React, { memo } from 'react';
import { Link } from 'raviger';
import map from 'lodash/map';
import Dropdown from 'react-bootstrap/Dropdown';

import { ICompany } from 'types/company.types';
import Logo from 'assets/images/pesakan-logo.png';
import Avatar from 'assets/images/avatar-m.png';

export interface IHeader {
  screenId: string;
  email: string;
  username: string;
  navControl: {
    title: string;
    icon: string;
  };
  company: ICompany | null;
  companyList: Array<ICompany>;
  handleCompanySelect: (company_id: string) => void;
  handleLogout: () => void;
  handleProfile: () => void;
  toggleNavigation: () => void;
  moveNavigation: () => void;
  mobileViewOff:  () => void;
}

const Header: React.FC<IHeader> = ({
  screenId,
  email,
  username,
  navControl,
  company,
  companyList,
  toggleNavigation,
  moveNavigation,
  handleLogout,
  handleProfile,
  handleCompanySelect,
}) => {
  return (
    <header className="page-header" role="banner">
      <div className="page-logo">
        <Link href="/">
          <img src={Logo} alt="Pesakan Laundry Services" />
        </Link>
      </div>
      <div className="dropdown-icon-menu position-relative">
        <button
          onClick={toggleNavigation}
          className="header-btn btn js-waves-off"
          data-action="toggle"
          data-class="nav-function-hidden"
          title="Hide Navigation"
        >
          <i className="ni ni-menu"></i>
        </button>
      </div>
      <div className="ml-auto d-flex">
        {!['/', 'dashboard'].includes(screenId) && (
          <div className="hidden-md-down">
            <button className="header-icon" title={navControl.title} onClick={moveNavigation}>
              <i className={navControl.icon}></i>
            </button>
          </div>
        )}
        {company && (
          <Dropdown>
            <Dropdown.Toggle as="button" id="dropdown-basic" className="header-icon">
              <i className="fal fa-washer color-white"></i>&nbsp;&nbsp;&nbsp; <span className="fw-800 color-white-300">{company?.company_name}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-sm">
              {map(companyList, (comp) => (
                <Dropdown.Item key={comp.id} className="dropdown-item fw-500 pt-3 pb-3" onClick={() => handleCompanySelect(comp.id)}>
                  {comp.company_name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        <Dropdown>
          <Dropdown.Toggle as="button" id="dropdown-basic" className="header-icon">
            <img src={Avatar} className="profile-image rounded-circle" alt={username} />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-lg">
            <Dropdown className="dropdown-header bg-primary-300 bg-primary-gradient d-flex flex-row py-4 rounded-top">
              <div className="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                <span className="mr-2">
                  <img src={Avatar} className="profile-image rounded-circle" alt={username} />
                </span>
                <div className="info-card-text">
                  <div className="fs-lg text-truncate text-truncate-lg">{username}</div>
                  <span className="text-truncate text-truncate-md opacity-80">{email}</span>
                </div>
              </div>
            </Dropdown>
            <Dropdown.Item className="dropdown-item fw-500 pt-3 pb-3" onClick={handleProfile}>
              <span data-i18n="drpdwn.fullscreen">Profile</span>
              <span className="float-right fw-n">{username}</span>
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item fw-500 pt-3 pb-3 d-right" onClick={handleLogout}>
              <span data-i18n="drpdwn.page-logout">Logout</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  );
};

export default memo(Header);
