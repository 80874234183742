import { IFetch } from 'constants/types';

export const UOM_CREATE = 'UOM_CREATE';
export const UOM_UPDATE = 'UOM_UPDATE';
export const UOM_FIND_BY_ID = 'UOM_FIND_BY_ID';
export const UOM_FIND_BY_CODE = 'UOM_FIND_BY_CODE';
export const UOM_FIND_FIRST = 'UOM_FIND_FIRST';
export const UOM_FIND_LAST = 'UOM_FIND_LAST';
export const UOM_FIND_ALL = 'UOM_FIND_ALL';
export const UOM_CONVERT = 'UOM_CONVERT';
export const UOM_CLEAR_DATA = 'UOM_CLEAR_DATA';

export interface UnitOfMeasureState extends IFetch {
  data: IUnitOfMeasure | undefined;
  list: IUnitOfMeasure[];
  total: number;
}

export type IUnitOfMeasure = {
  id: number;
  unit_code: string;
  unit_name: string;
  is_valid: number;
  company_id: number;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
  conversions?: Array<IUnitConversionComplete>;
};

export type IUnitOfMeasureCreate = {
  unit_code: string;
  unit_name: string;
  is_valid: number;
};

export interface IUnitOfMeasureUpdate extends IUnitOfMeasureCreate {
  id: number;
}

export type IUnitOfMeasures = {
  uoms: Array<IUnitOfMeasure>;
  total: number;
};

export type IUnitConversion = {
  id: number;
  item_id?: string;
  unit_id: number;
  to_unit_id: number;
  quantity: number;
  company_id: string;
  created_by: string;
  created_date: string;
  last_saved_by: string;
  last_saved_date: string;
  line: number;
  toDelete?: boolean | number;
};

export interface IUnitConversionComplete extends IUnitConversion {
  item_code?: string;
  item_name?: string;
  from_unit_code: string;
  from_unit_name: string;
  to_unit_code: string;
  to_unit_name: string;
  converted?: number;
}

export type IUOMConvert = {
  item_id?: string;
  quantity: number;
  from_unit_id: number;
  to_unit_id: number;
}

export interface UnitConversionForm extends IUnitOfMeasure {
}
