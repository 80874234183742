import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { ItemCategoryState } from 'types/item.category.types';
import ItemCategoryAction from 'store/actions/item.category.action';

export const ItemCategoryInitialState: ItemCategoryState = {
  data: undefined,
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type ItemCategoryType = typeof ItemCategoryInitialState;

const handleItemCategory = (state: ItemCategoryState, action: any) => {
  const { item_category = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: item_category,
    next,
    previous,
  };
};

const itemCategoryReducer = createSlice({
  name: 'ItemCategory',
  initialState: ItemCategoryInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ItemCategoryAction.itemCategoryFindAll.fulfilled, (state, action) => {
        const { item_categories: list = [], total = 0 } = get(action, 'payload.data', { item_categories: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(ItemCategoryAction.itemCategoryCreate.fulfilled, handleItemCategory)
      .addCase(ItemCategoryAction.itemCategoryUpdate.fulfilled, handleItemCategory)
      .addCase(ItemCategoryAction.itemCategoryFindByID.fulfilled, handleItemCategory)
      .addCase(ItemCategoryAction.itemCategoryFindByCode.fulfilled, handleItemCategory)
      .addCase(ItemCategoryAction.itemCategoryFindFirst.fulfilled, handleItemCategory)
      .addCase(ItemCategoryAction.itemCategoryFindLast.fulfilled, handleItemCategory)
      .addCase(ItemCategoryAction.itemCategoryClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default itemCategoryReducer.reducer;
