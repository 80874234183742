import React from 'react';
import { Link } from 'raviger';
import { find, map, sortBy } from 'lodash';
import ClassNames from 'classnames';
import Collapse from 'react-bootstrap/Collapse';

import { IRouteLinks } from 'constants/interface';
import { IAccess } from 'types/access.types';
import AppLinks from 'router/AppLinks';

export interface INavigation {
  role_id?: number;
  access: Array<IAccess>;
  selectedMenu: string;
  selectedParent: string;
  expandedMenu: string;
  handleChildItem: (path: string) => void;
  handleParentItem: (path: string) => void;
}

const collapseUpSign = (
  <b className="collapse-sign">
    <em className="fal fa-angle-up" />
  </b>
);

const collapseDownSign = (
  <b className="collapse-sign">
    <em className="fal fa-angle-down" />
  </b>
);

const Navigation: React.FC<INavigation> = ({
  role_id,
  access,
  selectedMenu,
  selectedParent,
  expandedMenu,
  handleChildItem,
  handleParentItem,
}) => {
  const generateLink = (router: IRouteLinks, key: string) => {
    // Limit access
    if (role_id && role_id > 0) {
      if (
        !find(access, (o: IAccess) => {
          if (router.id === 'dashboard') return true;
          return o.screen_id.startsWith(router.id);
        })
      ) {
        return false;
      }
    }

    const isOpen = selectedParent === router.title;
    const liClass = ClassNames({
      active: isOpen || selectedMenu === router.title,
      open: isOpen,
    });

    let collapseSign = collapseDownSign;
    if (expandedMenu === router.path) {
      collapseSign = collapseUpSign;
    }

    return (
      <li key={key} className={liClass}>
        <Link
          className="js-waves-on"
          href={router.path ? router.path : '#'}
          title={router.title}
          onClick={() => handleParentItem(router.title)}
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          <i className={`fal fa-${router.icon}`} />
          <span className="nav-link-text">{router.title}</span>
          {router.routes && collapseSign}
        </Link>
        {router.routes && (
          <Collapse in={isOpen}>
            <ul id={`colapse-${key}`} style={{ display: isOpen ? 'block' : 'none' }}>
              {map(router.routes, (subRoute, subKey) => subLinks(subRoute, subKey))}
            </ul>
          </Collapse>
        )}
      </li>
    );
  };

  const subLinks = (router: IRouteLinks, key: number) => {
    // Limit access
    if (role_id && role_id > 0) {
      if (!find(access, (o: IAccess) => o.screen_id.endsWith(router.id))) {
        return false;
      }
    }

    const isActive = selectedMenu === router.path;
    const liClass = ClassNames({
      active: isActive,
    });

    return (
      <li key={key} className={liClass}>
        <Link href={router.path ? router.path : '#'} title={router.title} onClick={() => router.path && handleChildItem(router.path)}>
          <span className="nav-link-text">{router.title}</span>
        </Link>
      </li>
    );
  };

  return (
    <ul id="js-nav-menu" className="nav-menu" style={{ marginLeft: '0px' }}>
      {map(sortBy(AppLinks, ['sort']), (router: IRouteLinks, key: string) => generateLink(router, key))}
    </ul>
  );
};

export default Navigation;
