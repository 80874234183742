import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  IAccount,
  ICreateAccount,
  IUpdateAccount,
  ACCOUNT_CREATE,
  ACCOUNT_UPDATE,
  ACCOUNT_FIND_BY_ID,
  ACCOUNT_FIND_BY_CODE,
  ACCOUNT_FIND_FIRST,
  ACCOUNT_FIND_LAST,
  ACCOUNT_FIND_ALL,
  ACCOUNT_CLEAR_DATA,
} from 'types/account.types';

const accountCreate = createAsyncThunk<IAccount, ICreateAccount>(ACCOUNT_CREATE, async (data) => request.post(`/account`, data));
const accountUpdate = createAsyncThunk<IAccount, IUpdateAccount>(ACCOUNT_UPDATE, async (data) =>
  request.put(`/account/id/${data.id}`, data)
);
const accountFindByID = createAsyncThunk<IAccount, string | number>(ACCOUNT_FIND_BY_ID, async (id) => request.get(`/account/id/${id}`));
const accountFindByCode = createAsyncThunk<IAccount, string>(ACCOUNT_FIND_BY_CODE, async (code) => request.get(`/account/${code}`));
const accountFindFirst = createAsyncThunk<IAccount>(ACCOUNT_FIND_FIRST, async () => request.get('/account/find/first'));
const accountFindLast = createAsyncThunk<IAccount>(ACCOUNT_FIND_LAST, async () => request.get('/account/find/last'));
const accountFindAll = createAsyncThunk<IAccount[], Partial<ITable>>(ACCOUNT_FIND_ALL, async (query) => request.get('/account', query));
const accountClearData = createAction(ACCOUNT_CLEAR_DATA);

const AccountActions = {
  accountCreate,
  accountUpdate,
  accountFindByID,
  accountFindByCode,
  accountFindFirst,
  accountFindLast,
  accountFindAll,
  accountClearData,
};

export default AccountActions;
