import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  ICalendar,
  ICalendarYear,
  ICreateCalendar,
  IUpdateCalendar,
  CALENDAR_CREATE,
  CALENDAR_UPDATE,
  CALENDAR_CHECK_DATE,
  CALENDAR_FIND_BY_YEAR,
  CALENDAR_FIND_PERIODS,
  CALENDAR_FIND_FIRST,
  CALENDAR_FIND_LAST,
  CALENDAR_FIND_ALL,
  CALENDAR_CLEAR_DATA,
} from 'types/calendar.types';

const calendarCreate = createAsyncThunk<ICalendar, ICreateCalendar>(CALENDAR_CREATE, async (data) => request.post(`/calendar`, data));
const calendarUpdate = createAsyncThunk<ICalendar, IUpdateCalendar>(CALENDAR_UPDATE, async (data) =>
  request.put(`/calendar/${data.calendar_year}`, data)
);
const calendarFindByYear = createAsyncThunk<ICalendarYear, string>(CALENDAR_FIND_BY_YEAR, async (code) => request.get(`/calendar/${code}`));
const calendarFindFirst = createAsyncThunk<ICalendarYear>(CALENDAR_FIND_FIRST, async () => request.get('/calendar/find/first'));
const calendarFindLast = createAsyncThunk<ICalendarYear>(CALENDAR_FIND_LAST, async () => request.get('/calendar/find/last'));
const calendarFindAll = createAsyncThunk<ICalendarYear[], Partial<ITable>>(CALENDAR_FIND_ALL, async (query) =>
  request.get('/calendar', query)
);
const calendarFindPeriods = createAsyncThunk<ICalendarYear[], Partial<ITable>>(CALENDAR_FIND_PERIODS, async (query) =>
  request.get('/calendar/periods', query)
);
const calendarCheckDate = createAsyncThunk<{ is_valid: boolean }, string>(CALENDAR_CHECK_DATE, async (date: string) =>
  request.get(`/calendar/date/valid?date=${date}`)
);
const calendarClearData = createAction(CALENDAR_CLEAR_DATA);

const CompanyActions = {
  calendarCreate,
  calendarUpdate,
  calendarFindByYear,
  calendarFindFirst,
  calendarFindLast,
  calendarFindAll,
  calendarFindPeriods,
  calendarCheckDate,
  calendarClearData,
};

export default CompanyActions;
