import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { DocumentTypeState } from 'types/document.types';
import DocumentTypeAction from 'store/actions/document.action';

export const DocumentInitialState: DocumentTypeState = {
  documents: [],
  sequence: {},
  fetching: false,
  fetched: false,
  error: null,
};

export type DocumentType = typeof DocumentInitialState;

const handleDocuments = (state: DocumentTypeState, action: any) => ({
  ...state,
  documents: get(action, 'payload.data', []),
});

const documentTypeReducer = createSlice({
  name: 'DocumentType',
  initialState: DocumentInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DocumentTypeAction.documentSequence.fulfilled, (state, action) => {
        const docType = get(action, 'meta.arg', {});
        const { sequence } = get(action, 'payload.data', { sequence: '00001' });

        if (!docType) return { ...state };

        return {
          ...state,
          sequence: {
            ...state.sequence,
            [docType as string]: sequence,
          },
        };
      })
      .addCase(DocumentTypeAction.documentTypeCreate.fulfilled, handleDocuments)
      .addCase(DocumentTypeAction.documentTypeUpdate.fulfilled, handleDocuments)
      .addCase(DocumentTypeAction.documentTypeFindAll.fulfilled, handleDocuments);
  },
});

export default documentTypeReducer.reducer;
