import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  AccountSummaryTree,
  IAccountCombination,
  ICreateAccountCombination,
  IUpdateAccountCombination,
  ACCOUNT_COMBINATION_SUMMARY,
  ACCOUNT_COMBINATION_CREATE,
  ACCOUNT_COMBINATION_UPDATE,
  ACCOUNT_COMBINATION_FIND_BY_ID,
  ACCOUNT_COMBINATION_FIND_BY_CODE,
  ACCOUNT_COMBINATION_FIND_FIRST,
  ACCOUNT_COMBINATION_FIND_LAST,
  ACCOUNT_COMBINATION_FIND_ALL,
  ACCOUNT_COMBINATION_CLEAR_DATA,
} from 'types/account.combination.types';

const accountCombinationCreate = createAsyncThunk<IAccountCombination, ICreateAccountCombination>(
  ACCOUNT_COMBINATION_CREATE,
  async (data) => request.post(`/account_combination`, data)
);
const accountCombinationUpdate = createAsyncThunk<IAccountCombination, IUpdateAccountCombination>(
  ACCOUNT_COMBINATION_UPDATE,
  async (data) => request.put(`/account_combination/id/${data.id}`, data)
);
const accountCombinationFindByID = createAsyncThunk<IAccountCombination, string | number>(ACCOUNT_COMBINATION_FIND_BY_ID, async (id) =>
  request.get(`/account_combination/id/${id}`)
);
const accountCombinationFindByCode = createAsyncThunk<IAccountCombination, string>(ACCOUNT_COMBINATION_FIND_BY_CODE, async (code) =>
  request.get(`/account_combination/${code}`)
);
const accountCombinationFindFirst = createAsyncThunk<IAccountCombination>(ACCOUNT_COMBINATION_FIND_FIRST, async () =>
  request.get('/account_combination/find/first')
);
const accountCombinationFindLast = createAsyncThunk<IAccountCombination>(ACCOUNT_COMBINATION_FIND_LAST, async () =>
  request.get('/account_combination/find/last')
);
const accountCombinationFindAll = createAsyncThunk<IAccountCombination[], Partial<ITable>>(ACCOUNT_COMBINATION_FIND_ALL, async (query) =>
  request.get('/account_combination', query)
);
const accountCombinationSummary = createAsyncThunk<AccountSummaryTree[]>(ACCOUNT_COMBINATION_SUMMARY, async () =>
  request.get('/account_combination/summary_tree')
);
const accountCombinationClearData = createAction(ACCOUNT_COMBINATION_CLEAR_DATA);

const AccountCombinationActions = {
  accountCombinationCreate,
  accountCombinationUpdate,
  accountCombinationFindByID,
  accountCombinationFindByCode,
  accountCombinationFindFirst,
  accountCombinationFindLast,
  accountCombinationFindAll,
  accountCombinationSummary,
  accountCombinationClearData,
};

export default AccountCombinationActions;
