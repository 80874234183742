import { IFetch } from 'constants/types';

export const ITEM_GROUP_CREATE = 'ITEM_GROUP_CREATE';
export const ITEM_GROUP_UPDATE = 'ITEM_GROUP_UPDATE';
export const ITEM_GROUP_FIND_BY_ID = 'ITEM_GROUP_FIND_BY_ID';
export const ITEM_GROUP_FIND_BY_CODE = 'ITEM_GROUP_FIND_BY_CODE';
export const ITEM_GROUP_FIND_FIRST = 'ITEM_GROUP_FIND_FIRST';
export const ITEM_GROUP_FIND_LAST = 'ITEM_GROUP_FIND_LAST';
export const ITEM_GROUP_FIND_ALL = 'ITEM_GROUP_FIND_ALL';
export const ITEM_GROUP_CLEAR_DATA = 'ITEM_GROUP_CLEAR_DATA';

export interface ItemGroupState extends IFetch {
  data: IItemGroup | undefined;
  list: IItemGroup[];
  total: number;
}

export type IItemGroup = {
  id: number;
  group_code: string;
  group_name: string;
  is_valid: number;
  company_id: number;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};

export type IItemGroupCreate = {
  group_code: string;
  group_name: string;
  is_valid: number;
};

export interface IItemGroupUpdate extends IItemGroupCreate {
  id: number;
};

export type IItemGroups = {
  item_groups: Array<IItemGroup>;
  total: number;
};
