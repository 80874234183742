export interface IFormOptions {
  ref: any;
  label: string | any;
  value: string | number;
  defaultChecked?: boolean;
}

export interface IFormRadio {
  options?: IFormOptions[];
}

export interface IFormSearch {
  hiddenID?: string;
  hiddenRef?: any;
  hiddenDefaultValue?: any;
  descriptionID?: string;
  descriptionRef?: any;
  descriptionDefaultValue?: any;
  onSearch?: (fromInput?: boolean) => void;
  onChange?: (value: string, name?: string, deps?: string[]) => void;
  onBlur?: (value: string, name?: string, deps?: string[]) => void;
  onClick?: () => void;
  disbableSearch?: boolean;
  withDescription?: boolean;
}

export enum FormControlType {
  Input = 'input',
  Code = 'code',
  Search = 'search',
  Amount = 'amount',
  Number = 'number',
  Radio = 'radio',
  CheckBox = 'checkbox',
  Select = 'select',
}

export interface IFormControl extends IFormSearch, IFormRadio {
  control: any;
  id: string;
  name: string;
  label: string;
  rules?: Record<any, any>;
  placeholder?: string;
  type?: string;
  readonly?: boolean;
  defaultValue?: any;
  controlType?: FormControlType;
  formClass?: string;
  isNumber?: boolean;
  isText?: boolean;
  labelClass?: string;
  inputClass?: string;
  prependClass?: string;
  appendClass?: string;
  selectOptions?: Record<string, string>;
  errors?: Record<string, any>;
  withoutContainer?: boolean;
  theme?: 'primary' | 'danger' | 'info';
}
