import React from 'react';

import useRoleAccess from 'hooks/useRoleAccess';

type MenuList = {
  path: string;
  name: string;
  icon: string;
  restricted?: boolean;
};

interface IAdminMenu extends Pick<IMenuIcon, 'handleMenuItem'> {
  company?: string;
}

interface IMenuIcon extends MenuList {
  company?: string;
  handleMenuItem: (path: string) => void;
}

const adminList: Array<MenuList> = [
  {
    path: '/admin/users',
    name: 'Users',
    icon: 'fad fa-user',
  },
  {
    path: '/admin/roles',
    name: 'Roles',
    icon: 'fad fa-user-hard-hat',
  },
  {
    path: '/admin/backup',
    name: 'Backup',
    icon: 'fad fa-usb-drive',
  },
  {
    path: '/admin/upload',
    name: 'Upload',
    icon: 'fad fa-upload',
    restricted: true,
  },
];

const AdminIcon: React.FC<IMenuIcon> = ({ path, name, icon, restricted, company, handleMenuItem }) => {
  return (
    <div className="d-flex flex-column justify-content-center ">
      <div
        className={`d-flex flex-column justify-content-center btn waves-effect waves-themed ${
          !company && restricted ? 'disabled' : 'cursor-pointer'
        }`}
        onClick={!company && restricted ? undefined : () => handleMenuItem(path)}
      >
        <span className="fa-4x d-inline l-h-n">
        <i className={`${icon}${!company && restricted ? ' disabled' : ''}`} />
        </span>
        <span>{name}</span>
      </div>
    </div>
  );
};

const AdminMenu: React.FC<IAdminMenu> = ({ company, handleMenuItem }) => {
  const { filterMenu } = useRoleAccess();
  return filterMenu(adminList).length > 0 ? (
    <div className="panel">
      <div className="panel-hdr bg-warning-400 bg-warning-gradient">
        <h2 className="white">Administration</h2>
      </div>
      <div className="panel-container show">
        <div className="panel-content row justify-content-around bd-highlight">
          {filterMenu(adminList).map((menu) => (
            <AdminIcon key={menu.path} {...menu} company={company} handleMenuItem={handleMenuItem} />
          ))}
        </div>
      </div>
    </div>
  ) : <></>;
};

export default AdminMenu;
