import { IFetch } from 'constants/types';

export const DOCUMENT_TYPE_CREATE = 'DOCUMENT_TYPE_CREATE';
export const DOCUMENT_TYPE_UPDATE = 'DOCUMENT_TYPE_UPDATE';
export const DOCUMENT_TYPE_GET_ALL = 'DOCUMENT_TYPE_GET_ALL';
export const DOCUMENT_TYPE_SEQUENCE = 'DOCUMENT_TYPE_SEQUENCE';

export interface DocumentTypeState extends IFetch {
  documents: Array<IDocumentType>;
  sequence: Record<string, string>;
}

export type IDocumentType = {
  document_id: string;
  document_code: string;
  document_description: string;
  sequence?: string;
  with_sequence: number;
  readonly: number;
  company_id: string;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};

export type IDocumentTypeCreate = {
  company_id: string;
  documents: Array<{
    document_id: string;
    document_code: string;
    document_description: string;
    sequence: string;
    with_sequence: number;
    readonly: number;
  }>;
};

export interface IDocumentTypeUpdate extends IDocumentTypeCreate {}

export type IAccountMappingParams = {
  document_id: string;
  item_id?: string;
  bank_id?: number;
  customer_id?: string;
  supplier_id?: number;
}

export type IPartialAccountMappingResult = {
  account_id: number;
  combination_code: string;
  combination_name: string;
}