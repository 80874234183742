import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { RoleState } from 'types/role.types';
import RoleAction from 'store/actions/role.action';

export const RoleInitialState: RoleState = {
  role: undefined,
  data: undefined,
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type RoleType = typeof RoleInitialState;

const resultById = (state: RoleState, action: any) => {
  const data = get(action, 'payload.data');
  return { ...state, data };
};

const handleRole = (state: RoleState, action: any) => {
  const { role = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: role,
    next,
    previous,
  };
};

const roleReducer = createSlice({
  name: 'Role',
  initialState: RoleInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(RoleAction.roleUserSession.fulfilled, (state, action) => ({
        ...state,
        role: get(action, 'payload.data'),
      }))
      .addCase(RoleAction.roleFindAll.fulfilled, (state, action) => {
        const { roles: list = [], total = 0 } = get(action, 'payload.data', { roles: [], total: 0});
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(RoleAction.roleCreate.fulfilled, handleRole)
      .addCase(RoleAction.roleUpdate.fulfilled, handleRole)
      .addCase(RoleAction.roleFindByID.fulfilled, resultById)
      .addCase(RoleAction.roleFindByCode.fulfilled, handleRole)
      .addCase(RoleAction.roleFindFirst.fulfilled, handleRole)
      .addCase(RoleAction.roleFindLast.fulfilled, handleRole)
      .addCase(RoleAction.roleClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default roleReducer.reducer;
