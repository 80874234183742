import React from 'react';

import { IRoute, IRouteLinks } from 'constants/interface';

export const HomeLinks: IRouteLinks = {
  id: 'home',
  path: '/',
  title: 'Home',
  icon: 'home',
  sort: 1,
  noDelete: true,
  noEdit: true,
};

const HomeRouter: IRoute[] = [
  {
    path: '/',
    component: React.lazy(() => import('containers/HomeContainer')),
  }, {
    path: '/dashboard',
    component: React.lazy(() => import('containers/DashboardContainer')),
  },
];

export default HomeRouter;
