import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { VoucherState } from 'types/voucher.types';
import VoucherAction from 'store/actions/voucher.action';

export const VoucherInitialState: VoucherState = {
  data: undefined,
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type VoucherType = typeof VoucherInitialState;

const handleVoucher = (state: VoucherState, action: any) => {
  const { voucher = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: voucher,
    next,
    previous,
  };
};

const voucherReducer = createSlice({
  name: 'Voucher',
  initialState: VoucherInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(VoucherAction.voucherFindAll.fulfilled, (state, action) => {
        const { vouchers: list = [], total = 0 } = get(action, 'payload.data', { vouchers: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(VoucherAction.voucherCreate.fulfilled, handleVoucher)
      .addCase(VoucherAction.voucherUpdate.fulfilled, handleVoucher)
      .addCase(VoucherAction.voucherFindByID.fulfilled, handleVoucher)
      .addCase(VoucherAction.voucherFindByCode.fulfilled, handleVoucher)
      .addCase(VoucherAction.voucherFindFirst.fulfilled, handleVoucher)
      .addCase(VoucherAction.voucherFindLast.fulfilled, handleVoucher)
      .addCase(VoucherAction.voucherClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default voucherReducer.reducer;
