import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { CompanyState } from 'types/company.types';
import CompanyAction from 'store/actions/company.action';

export const CompanyInitialState: CompanyState = {
  company: null,
  data: undefined,
  list: [],
  company_list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type CompanyType = typeof CompanyInitialState;

const handleCompany = (state: CompanyState, action: any) => {
  const { company = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: company,
    next,
    previous,
  };
};

const companyReducer = createSlice({
  name: 'Company',
  initialState: CompanyInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CompanyAction.companySelect.fulfilled, (state, action) => {
        const company = get(action, 'payload.data', null);
        return {
          ...state,
          company,
        };
      })
      .addCase(CompanyAction.companyFindAll.fulfilled, (state, action) => {
        const { companies: list = [], total = 0 } = get(action, 'payload.data', { companies: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(CompanyAction.companyFindActive.fulfilled, (state, action) => {
        const { companies: company_list = [], total = 0 } = get(action, 'payload.data', { companies: [], total: 0 });
        return {
          ...state,
          company_list,
          total,
        };
      })
      .addCase(CompanyAction.companyCreate.fulfilled, handleCompany)
      .addCase(CompanyAction.companyUpdate.fulfilled, handleCompany)
      .addCase(CompanyAction.companyFindByID.fulfilled, handleCompany)
      .addCase(CompanyAction.companyFindByCode.fulfilled, handleCompany)
      .addCase(CompanyAction.companyFindFirst.fulfilled, handleCompany)
      .addCase(CompanyAction.companyFindLast.fulfilled, handleCompany)
      .addCase(CompanyAction.companyClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }))
      .addCase(CompanyAction.companyLogout, (state) => ({ ...state, company: null }));
  },
});

export default companyReducer.reducer;
