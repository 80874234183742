import React from 'react';
import Moment from 'moment';

import { JwtIuser } from 'types/user.types';
import CompanyList, { ICompanyList } from 'pages/Home/CompanyList';
import AdminMenu from 'pages/Home/AdminMenu';
import MainMenu, { IMainMenu } from 'pages/Home/MainMenu';
import Configuration from 'pages/Home/Configuration';
import MasterData from 'pages/Home/MasterData';

interface IHome extends ICompanyList, IMainMenu {
  auth: JwtIuser | null;
}

const Home: React.FC<IHome> = ({ auth, company, companyList, handleSelectCompany, handleEnterCompany, handleMenuItem }) => {
  return (
    <div>
      <div className="subheader row">
        <h1 className="subheader-title col-xs-12 col-sm-6">
          <i className="subheader-icon fal fa-home"></i> Welcome!{' '}
          <span className="fw-300">{auth && `${auth.first_name} ${auth.last_name}`}</span>
        </h1>
        <div className="subheader-block col-xs-12 col-sm-6 text-left text-sm-right ml-3 pl-3">Today is {Moment().format('LLLL')}</div>
      </div>

      <CompanyList
        company={company}
        companyList={companyList}
        handleSelectCompany={handleSelectCompany}
        handleEnterCompany={handleEnterCompany}
      />
      {company && <MainMenu handleMenuItem={handleMenuItem} />}
      {company && <MasterData company={company} handleMenuItem={handleMenuItem} />}
      <Configuration company={company} handleMenuItem={handleMenuItem} />
      <AdminMenu company={company} handleMenuItem={handleMenuItem} />
    </div>
  );
};

export default Home;
