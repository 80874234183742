import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { SubledgerState } from 'types/subledger.types';
import SubledgerAction from 'store/actions/subledger.action';

export const SubledgerInitialState: SubledgerState = {
  data: undefined,
  list: [],
  total: 0,
  page: 1,
  fetching: false,
  fetched: false,
  error: null,
};

export type SubledgerType = typeof SubledgerInitialState;

const handleSubledger = (state: SubledgerState, action: any) => {
  const { subledger = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: subledger,
    next,
    previous,
  };
};

const subledgerReducer = createSlice({
  name: 'Subledger',
  initialState: SubledgerInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SubledgerAction.subledgerFindAll.fulfilled, (state, action) => {
        const { subledgers: list = [], total = 0, page = 1 } = get(action, 'payload.data', { subledgers: [], total: 0, page: 1 });
        return {
          ...state,
          list,
          total,
          page,
        };
      })
      .addCase(SubledgerAction.subledgerImportEntries.fulfilled, handleSubledger)
      .addCase(SubledgerAction.subledgerCreate.fulfilled, handleSubledger)
      .addCase(SubledgerAction.subledgerUpdate.fulfilled, handleSubledger)
      .addCase(SubledgerAction.subledgerFindByID.fulfilled, handleSubledger)
      .addCase(SubledgerAction.subledgerFindByCode.fulfilled, handleSubledger)
      .addCase(SubledgerAction.subledgerFindFirst.fulfilled, handleSubledger)
      .addCase(SubledgerAction.subledgerFindLast.fulfilled, handleSubledger)
      .addCase(SubledgerAction.subledgerClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default subledgerReducer.reducer;
