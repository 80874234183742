export const format = (value: number): string =>
    new Intl.NumberFormat('en-PH', { minimumFractionDigits: 2 }).format(
        value ? (value as number) : 0,
    );

export const toNumber = (amount: string): number => {
    return Number(amount.replace(/[,]/g, ''));
};


export const arraySum = (list: Array<number>): number => {
    return list.reduce((a, b) => a + b, 0);
}

export const flattenJson = (input: Record<string, any>, reference?: string, output?: { [x: string]: any }) => {
    output = output || {};
    for (var key in input) {
      var value = input[key];
      key = reference ? reference + '.' + key : key;
      if (typeof value === 'object' && value !== null) {
        flattenJson(value, key, output);
      } else {
        output[key] = value;
      }
    }
    return output;
  };