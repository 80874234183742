import { IFetch } from 'constants/types';
import { IAccess, IAccessUpdate } from 'types/access.types';

export const ROLE_CREATE = 'ROLE_CREATE';
export const ROLE_UPDATE = 'ROLE_UPDATE';
export const ROLE_FIND_BY_ID = 'ROLE_FIND_BY_ID';
export const ROLE_FIND_BY_CODE = 'ROLE_FIND_BY_CODE';
export const ROLE_FIND_FIRST = 'ROLE_FIND_FIRST';
export const ROLE_FIND_LAST = 'ROLE_FIND_LAST';
export const ROLE_FIND_ALL = 'ROLE_FIND_ALL';
export const ROLE_CLEAR_DATA = 'ROLE_CLEAR_DATA';
export const ROLE_USER_SESSION = 'ROLE_USER_SESSION';

export const Roles = {
  Administrator: 0,
  Accounting: 2,
  Sales: 1
};


export interface RoleState extends IFetch {
  data?: IRole;
  role?: IRole;
  list: IRole[];
  total: number;
}

export type IRole = {
  id: number;
  role_code: string;
  role_description: string;
  access: Array<IAccess>;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};

export type IRoleCreate = {
  role_code: string;
  role_description: string;
  access: IAccessUpdate;
};

export type IRoleUpdate = {
  id: number;
  role_code: string;
  role_description: string;
  access: IAccessUpdate;
};

export type IRoles = {
  roles: Array<IRole>;
  total: number;
};
