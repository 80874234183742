import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { AccountDefaultState } from 'types/account.default.types';
import AccountDefaultAction from 'store/actions/account.default.action';

export const AccessInitialState: AccountDefaultState = {
  fetching: false,
  fetched: false,
  error: null,
};

export type AccessType = typeof AccessInitialState;

const handleDefaults = (state: AccountDefaultState, action: any) => {
  return {
    ...state,
    defaults: get(action, 'payload.data')
  }
}

const accessReducer = createSlice({
  name: 'Access',
  initialState: AccessInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AccountDefaultAction.save.fulfilled, handleDefaults)
      .addCase(AccountDefaultAction.get.fulfilled, handleDefaults);
  },
});

export default accessReducer.reducer;
