import { IFetch } from 'constants/types';

export const PAYABLE_CREATE = 'PAYABLE_CREATE';
export const PAYABLE_UPDATE = 'PAYABLE_UPDATE';
export const PAYABLE_FIND_BY_ID = 'PAYABLE_FIND_BY_ID';
export const PAYABLE_FIND_BY_CODE = 'PAYABLE_FIND_BY_CODE';
export const PAYABLE_FIND_FIRST = 'PAYABLE_FIND_FIRST';
export const PAYABLE_FIND_LAST = 'PAYABLE_FIND_LAST';
export const PAYABLE_FIND_ALL = 'PAYABLE_FIND_ALL';
export const PAYABLE_CLEAR_DATA = 'PAYABLE_CLEAR_DATA';

export type PayableStatus = 'applied' | 'unapplied' | 'posted' | 'cancelled';

export interface PayableState extends IFetch {
  data: IPayable | undefined;
  list: IPayable[];
  total: number;
}

export type IPayable = {
  id: number;
  payable_code: string;
  payable_date: string;
  payable_status: PayableStatus;
  total_amount: number;
  supplier_id: number;
  supplier_code?: string;
  supplier_name?: string;
  supplier_address?: string;
  notes?: string;
  account_id: number;
  combination_code?: string;
  combination_name?: string;
  company_id: string;
  created_by: string;
  created_date: string;
  last_saved_by: string;
  last_saved_date: string;
  details?: Array<IPayableDetail>;
};

export type IPayableDetail = {
  id: number;
  line: number;
  item_id: string;
  toDelete?: boolean | number;
  payable_status: PayableStatus;
  payable_amount: number;
  account_id: number;
  combination_code?: string;
  combination_name?: string;
  company_id: string;
  created_by: string;
  created_date: string;
  last_saved_by: string;
  last_saved_date: string;
};

export interface CreatePayableDetail
  extends Pick<
    IPayableDetail,
    'line' | 'item_id' | 'payable_amount' | 'payable_status' | 'account_id'
  > {
  id?: number;
}

export interface UpdatePayableDetail extends CreatePayableDetail {
  id?: number;
}

export interface IPayableCreate
  extends Pick<IPayable, 'payable_code' | 'payable_date' | 'payable_status' | 'total_amount' | 'supplier_id' | 'notes' | 'account_id'> {
  details: Array<CreatePayableDetail>;
}

export interface IPayableUpdate extends IPayableCreate {
  id?: number;
  details: Array<UpdatePayableDetail>;
  toDelete: Array<number>;
}

export interface PayableForm extends IPayable {
  payable_status_label: string;
  combination_code: string;
  combination_name: string;
}
