import { createAction } from '@reduxjs/toolkit';

import { IGLPostingProgress, IFileUploadProgress, WS_GL_POSTING, WS_FILE_UPLOAD, WS_CLEAR_DATA } from 'types/websocket.types';

const glPosting = createAction<IGLPostingProgress>(WS_GL_POSTING);
const fileUpload = createAction<IFileUploadProgress>(WS_FILE_UPLOAD);
const wsClearData = createAction(WS_CLEAR_DATA);

const WebSocketActions = {
  glPosting,
  fileUpload,
  wsClearData,
};

export default WebSocketActions;
