import { IFetch } from 'constants/types';

export const ITEM_CREATE = 'ITEM_CREATE';
export const ITEM_UPDATE = 'ITEM_UPDATE';
export const ITEM_FIND_BY_ID = 'ITEM_FIND_BY_ID';
export const ITEM_FIND_BY_CODE = 'ITEM_FIND_BY_CODE';
export const ITEM_FIND_FIRST = 'ITEM_FIND_FIRST';
export const ITEM_FIND_LAST = 'ITEM_FIND_LAST';
export const ITEM_FIND_ALL = 'ITEM_FIND_ALL';
export const ITEM_CLEAR_DATA = 'ITEM_CLEAR_DATA';

export interface ItemState extends IFetch {
  data: IItem | undefined;
  list: IItem[];
  total: number;
}

export type IItem = {
  id: string;
  lms_id: string;
  item_code: string;
  item_name: string;
  group_id: number;
  group_code?: string;
  category_id?: number;
  category_code?: string;
  primary_uom_id?: number;
  primary_uom_code?: string;
  primary_uom_name?: string;
  purchase_uom_id?: number;
  purchase_uom_code?: string;
  purchase_uom_name?: string;
  purchase_price?: number;
  selling_price?: number;
  with_inventory: number;
  inv_account_id?: number;
  inv_combination_code?: string;
  inv_combination_name?: string;
  cos_account_id?: number;
  cos_combination_code?: string;
  cos_combination_name?: string;
  sales_account_id?: number;
  sales_combination_code?: string;
  sales_combination_name?: string;
  company_id: string;
  is_valid: number;
  created_by: string;
  created_date: string;
  last_saved_by: string;
  last_saved_date: string;
};

export type IItemCreate = {
  lms_id: string;
  item_code: string;
  item_name: string;
  category_id?: number;
  group_id: number;
  primary_uom_id?: number;
  purchase_uom_id?: number;
  purchase_price?: number;
  selling_price?: number;
  with_inventory: number;
  is_valid: number;
  inv_account_id?: number;
  cos_account_id?: number;
  sales_account_id?: number;
};

export interface IItemUpdate extends IItemCreate {
  id: string;
}

export type IItems = {
  items: Array<IItem>;
  total: number;
};

export interface ItemForm extends Omit<IItem, 'with_inventory' | 'is_valid'> {
  is_valid: boolean;
  with_inventory: boolean;
}