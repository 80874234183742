import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState, AppDispatch } from 'store';
import { UserActions } from 'store/actions';
import { ILoginForm } from 'components/Authentication/LoginRightPanel';
import Notification, { AlertType } from 'components/Common/Notification';
import LoginPage from 'pages/Authentication/Login';

const LoginContainer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { fetching, message, fetched } = useSelector((state: RootState) => ({
    fetching: state.error.fetching,
    fetched: state.error.fetched,
    message: state.error.message,
  }));

  useEffect(() => {
    document.body.className = '';
  }, []);

  useEffect(() => {
    fetched && message && Notification({ type: AlertType.ERROR, message });
  }, [message, fetched]);

  const onSubmit = async (data: ILoginForm) => {
    dispatch(UserActions.userLogin(data));
  };

  return <LoginPage user={{ fetching }} onSubmit={onSubmit} />;
};

export default LoginContainer;
