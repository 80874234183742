import React from 'react';

const Spinner: React.FC = () => (
    <div className="mx-auto width-9">
        <div className="mx-auto spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>
);

export default Spinner;
