import get from 'lodash/get';
import jwt_decode from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';

import UserAction from 'store/actions/user.action';
import { IUser, UserState, IUserLoginResponse, JwtIuser } from 'types/user.types';

export const UserInitialState: UserState = {
  data: undefined,
  auth: null,
  list: [],
  total: 0,
  token: '',
  lms_token: undefined,
  fetching: false,
  fetched: false,
  error: null,
  infoData: undefined,
};

export type UserType = typeof UserInitialState;

const handleUser = (state: UserState, action: any) => {
  const { user: data, next, previous } = get(action, 'payload.data', { user: undefined, next: undefined, previous: undefined });
  return {
    ...state,
    data,
    next,
    previous,
  };
};

const setAuth = (state: UserState, action: any) => {
  const auth: JwtIuser = get(action, 'payload.data') || get(action, 'payload');
  return {
    ...state,
    auth: {
      ...auth,
      user_id: auth?.id || '',
    },
  };
};

const refreshAuth = (state: UserState, action: any) => {
  const token: IUserLoginResponse = get(action, 'payload.data', '');
  const auth = jwt_decode<JwtIuser>(token.access_token);
  let error = null;
  if (!auth.user_id || auth.user_id === '') {
    error = 'Invalid Token';
  }

  return {
    ...state,
    auth: {
      user_id: auth.user_id,
      email: auth.email,
      username: auth.username,
      first_name: auth.first_name,
      last_name: auth.last_name,
      role_id: auth.role_id,
      menu: auth.menu,
    },
    token,
    error,
  };
};

const resetAuth = (state: UserState) => ({
  ...state,
  data: undefined,
  auth: null,
  token: '',
  next: undefined,
  previous: undefined,
  company_id: undefined,
});

const userReducer = createSlice({
  name: 'User',
  initialState: UserInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UserAction.userLogin.fulfilled, (state, action) => { 
        const token = get(action, 'payload.data') as unknown as IUserLoginResponse;
        const auth = jwt_decode<JwtIuser>(token?.access_token);
        let error = null;
        if (!auth.user_id || auth.user_id === '') {
          error = 'Invalid Username or Password';
        }
        
        return {
          ...state,
          auth: {
            user_id: auth.user_id,
            email: auth.email,
            username: auth.username,
            first_name: auth.first_name,
            last_name: auth.last_name,
            role_id: auth.role_id,
            menu: auth.menu,
          },
          token,
          lms_token: get(token, 'lms_token'),
          error,
        };
      })
      .addCase(UserAction.userFindAll.fulfilled, (state, action) => {
        const list: IUser[] = get(action, 'payload.data.users', []);
        const total: number = get(action, 'payload.data.total', 0);
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(UserAction.userDataInfo.fulfilled, (state, action) => {
        return {
          ...state,
          infoData: get(action, 'payload.data'),
        };
      })
      .addCase(UserAction.userClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }))
      .addCase(UserAction.setLmsToken, (state, action) => ({
        ...state,
        lms_token: action.payload
      }))
      .addCase(UserAction.userCreate.fulfilled, handleUser)
      .addCase(UserAction.userUpdate.fulfilled, handleUser)
      .addCase(UserAction.userFindFirst.fulfilled, handleUser)
      .addCase(UserAction.userFindLast.fulfilled, handleUser)
      .addCase(UserAction.userFindData.fulfilled, handleUser)
      .addCase(UserAction.userUpdateSession, setAuth)
      .addCase(UserAction.userMenuPosition.fulfilled, setAuth)
      .addCase(UserAction.refreshToken, refreshAuth)
      .addCase(UserAction.userLogout, resetAuth)
      .addCase(UserAction.setCompany, (state, action) => ({
        ...state,
        company_id: action.payload,
      }));
  },
});

// console.log({ state });
        // const newState = {
        //   ...state
        // };
        // set(newState, 'token.lms_token', action.payload);
        // console.log({ newState });
        // return newState;
        // return state;

export default userReducer.reducer;
