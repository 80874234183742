import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  ISale,
  ISales,
  ISaleUpdate,
  ISaleUnpaid,
  ISaleDaily,
  SALE_LOCK,
  SALE_SELECT,
  SALE_UPDATE,
  SALE_SET_STATUS,
  SALE_FIND_BY_ID,
  SALE_FIND_BY_CODE,
  SALE_FIND_FIRST,
  SALE_FIND_LAST,
  SALE_FIND_ALL,
  SALE_FIND_DAILY,
  SALE_CLEAR_DATA,
  SALE_CLEAR_SALES,
  SALE_CLEAR_UNPAID,
  SALE_FIND_UNPAID,
  SALE_CREATE_PAYMENT,
  SALE_UPDATE_PAYMENT,
  SALE_LOYALTY,
  LMS_UPDATE_SALE,
  LMS_UPDATE_SALES,
  LMS_UPDATE_COLLECTION,
  LMS_UPDATE_UNPAID,
  SaleStatus,
  IPayment,
  ILoyalty,
} from 'types/sale.types';

const saleLock = createAsyncThunk<ISale, {id: string, isLocked: number }>(SALE_LOCK, async (data) =>
  request.put(`/sale/id/${data.id}/${data.isLocked}`, {})
);
const saleSetStatus = createAsyncThunk<ISale, {id: string, sale_status: SaleStatus }>(SALE_SET_STATUS, async (data) =>
  request.put(`/sale/status/${data.id}`, { sale_status: data.sale_status })
);
const saleUpdate = createAsyncThunk<ISale, ISaleUpdate>(SALE_UPDATE, async (data) =>
  request.put(`/sale/id/${data.id}`, { ...data })
);
const saleFindByID = createAsyncThunk<ISale, string>(SALE_FIND_BY_ID, async (id) =>
  request.get(`/sale/id/${id}`)
);
const saleFindByCode = createAsyncThunk<ISale, string>(SALE_FIND_BY_CODE, async (code) => request.get(`/sale/${code}`));
const saleFindFirst = createAsyncThunk<ISale>(SALE_FIND_FIRST, async () => request.get('/sale/find/first'));
const saleFindLast = createAsyncThunk<ISale>(SALE_FIND_LAST, async () => request.get('/sale/find/last'));
const saleFindAll = createAsyncThunk<ISales<ISale>, Partial<ITable>>(SALE_FIND_ALL, async (query) => request.get('/sale', query));
const saleClearData = createAction(SALE_CLEAR_DATA);
const saleClearSales = createAction(SALE_CLEAR_SALES);
const saleClearUnpaid = createAction(SALE_CLEAR_UNPAID);
const saleSelect = createAsyncThunk<ISale, number>(SALE_SELECT, async (id) => request.get(`/sale/id/${id}`));
const saleFindUnpaid = createAsyncThunk<ISaleUnpaid<ISale>, Partial<ITable>>(SALE_FIND_UNPAID, async (query) => request.get('/sale/unpaid', query));
const saleFindDaily = createAsyncThunk<ISales<ISaleDaily>, string>(SALE_FIND_DAILY, async (sale_date) => request.get('/sale/daily', { sale_date }));

const saleCreatePayment = createAsyncThunk<ISale, Partial<IPayment>>(SALE_CREATE_PAYMENT, async (payload) => request.post('/payments', payload));
const saleUpdatePayment = createAsyncThunk<ISale, IPayment>(SALE_UPDATE_PAYMENT, async (payload) => request.put(`/payments/id/${payload.id}`, payload));

const lmsUpdateSale = createAsyncThunk<ISale, string>(LMS_UPDATE_SALE, async (saleId) => request.put(`/lms-sale/${saleId}`, {}));
const lmsUpdateSales = createAsyncThunk<ISale, Partial<ITable>>(LMS_UPDATE_SALES, async (payload) => request.post('/lms-sales', payload));
const lmsUpdateUnpaid = createAsyncThunk<ISale, Partial<ITable>>(LMS_UPDATE_UNPAID, async (query) => request.get('/lms-sales-unpaid', query));
const lmsUpdateCollection = createAsyncThunk<void, {datePaid?: string}>(LMS_UPDATE_COLLECTION, async (payload) => request.post('/lms-collection', payload));

const saleLoyalty = createAsyncThunk<ILoyalty, {customer_id: string}>(SALE_LOYALTY, async (data) => request.get(`/sale/loyalty/${data.customer_id}`));


const SaleActions = {
  saleLock,
  saleSelect,
  saleUpdate,
  saleSetStatus,
  saleFindByID,
  saleFindByCode,
  saleFindFirst,
  saleFindLast,
  saleFindAll,
  saleClearData,
  saleClearSales,
  saleClearUnpaid,
  saleFindUnpaid,
  saleFindDaily,
  saleCreatePayment,
  saleUpdatePayment,
  saleLoyalty,
  lmsUpdateSale,
  lmsUpdateSales,
  lmsUpdateUnpaid,
  lmsUpdateCollection,
};

export default SaleActions;
