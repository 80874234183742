import get from 'lodash/get';
import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';

import { ErrorState } from 'types/error.types';

export const ErrorInitialState: ErrorState = {
  error: undefined,
  message: undefined,
  fetching: false,
  fetched: false,
};

export type ErrorType = typeof ErrorInitialState;

const errorReducer = createSlice({
  name: 'Error',
  initialState: ErrorInitialState,
  reducers: {
    onError: (state, action: PayloadAction<AnyAction>) => {
      const defaultError = 'An error has occured, please contact system admin';
      const payload = get(action, 'payload');
      return {
        ...state,
        fetching: false,
        fetched: true,
        message: payload?.error?.message ?? defaultError,
        error: payload?.error,
      };
    },
    apiFetched: (state) => ({
      ...ErrorInitialState,
    }),
    apiFetching: (state) => ({ ...state, fetching: true, fetched: false }),
  },
});

export const { onError, apiFetched, apiFetching } = errorReducer.actions;
export default errorReducer.reducer;
