import React, { useEffect } from 'react';
import { navigate } from 'raviger';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { UserActions } from 'store/actions';
import Logout from 'pages/Authentication/Logout';

const LogoutContainer: React.FC = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.user.auth);

  useEffect(() => {
    if (auth && auth.user_id) dispatch(UserActions.userLogout());
    navigate('/login');
  }, [auth, dispatch]);

  return <Logout />;
};

export default LogoutContainer;
