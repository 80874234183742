import { filter, find } from 'lodash';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { MenuList } from 'types';


const useRoleAccess = () => {
    const { access, auth } = useSelector((state: RootState) => ({
        access: state.access.access,
        auth: state.user.auth
    }));

    const filterMenu = (menuList: MenuList[]): MenuList[] => {
        return auth?.role_id === 0 ? menuList : filter(menuList, (menu) => {
            return !!find(access, { screen_id: menu.path.substring(1).split('/').join('_') });
        })
    }

    return { filterMenu }
}

export default useRoleAccess;