import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { AccountCompanyState } from 'types/account.types';
import AccountCompanyAction from 'store/actions/account.company.action';

export const AccountCompanyInitialState: AccountCompanyState = {
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type AccountCompanyType = typeof AccountCompanyInitialState;

const handleAccountCompany = (state: AccountCompanyState, action: any) => {
  const { accountCompany = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: accountCompany,
    next,
    previous,
  };
};

const accountCompanyReducer = createSlice({
  name: 'AccountCompany',
  initialState: AccountCompanyInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AccountCompanyAction.accountCompanyFindAll.fulfilled, (state, action) => {
        const { companies: list = [], total = 0 } = get(action, 'payload.data', { companies: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(AccountCompanyAction.accountCompanyCreate.fulfilled, handleAccountCompany)
      .addCase(AccountCompanyAction.accountCompanyUpdate.fulfilled, handleAccountCompany)
      .addCase(AccountCompanyAction.accountCompanyFindByID.fulfilled, handleAccountCompany)
      .addCase(AccountCompanyAction.accountCompanyFindByCode.fulfilled, handleAccountCompany)
      .addCase(AccountCompanyAction.accountCompanyFindFirst.fulfilled, handleAccountCompany)
      .addCase(AccountCompanyAction.accountCompanyFindLast.fulfilled, handleAccountCompany)
      .addCase(AccountCompanyAction.accountCompanyClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default accountCompanyReducer.reducer;
