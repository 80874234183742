import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState, AppDispatch } from 'store';
import { SaleActions, CustomerActions } from 'store/actions';
import CustomerLoyalty from 'pages/Public/CustomerLoyalty';


interface ICustomerLoyaltyContainerProps {
  customer_id: string;
}

const CustomerLoyaltyContainer: React.FC<ICustomerLoyaltyContainerProps> = ({ customer_id }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { sale, customer, loyalty } = useSelector((state: RootState) => ({
    customer: state.customer.data,
    loyalty: state.sale.loyalty,
    sale: state.sale.data
  }));

  const [totalCards, setTotalCards] = useState(1);
  const [showSaleDetails, setShowSaleDetails] = useState(false);

  useEffect(() => {
    if (customer_id) {
      dispatch(SaleActions.saleLoyalty({ customer_id }));
      const customers = customer_id.split(',');
      const id = customers[0];
      dispatch(CustomerActions.customerBasic(id));      
    }
  }, [dispatch, customer_id]);

  useEffect(() => {
    if (loyalty) {
      setTotalCards(loyalty.length);
    }
  }, [loyalty]);

  const handleClickCard = async (sale_id?: string) => {
    // Do nothing
  }

  const handleHideSaleDetails = () => setShowSaleDetails(!showSaleDetails)

  return <CustomerLoyalty
    sale={sale}
    loyalty={loyalty}
    customer={customer}
    totalCards={totalCards}
    showSaleDetails={showSaleDetails}
    handleClickCard={handleClickCard}
    handleHideSaleDetails={handleHideSaleDetails} />;
};

export default CustomerLoyaltyContainer;
