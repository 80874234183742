import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { PurchaseState } from 'types/purchase.types';
import PurchaseAction from 'store/actions/purchase.action';

export const PurchaseInitialState: PurchaseState = {
  data: undefined,
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type PurchaseType = typeof PurchaseInitialState;

const handlePurchase = (state: PurchaseState, action: any) => {
  const { purchase = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: purchase,
    next,
    previous,
  };
};

const purchaseReducer = createSlice({
  name: 'Purchase',
  initialState: PurchaseInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PurchaseAction.purchaseFindAll.fulfilled, (state, action) => {
        const { purchases: list = [], total = 0 } = get(action, 'payload.data', { purchases: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(PurchaseAction.purchaseCreate.fulfilled, handlePurchase)
      .addCase(PurchaseAction.purchaseUpdate.fulfilled, handlePurchase)
      .addCase(PurchaseAction.purchaseFindByID.fulfilled, handlePurchase)
      .addCase(PurchaseAction.purchaseFindByCode.fulfilled, handlePurchase)
      .addCase(PurchaseAction.purchaseFindFirst.fulfilled, handlePurchase)
      .addCase(PurchaseAction.purchaseFindLast.fulfilled, handlePurchase)
      .addCase(PurchaseAction.purchaseClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default purchaseReducer.reducer;
