import React from 'react';

import Header from 'components/Authentication/LoginHeader';
import LeftSide from 'components/Authentication/LoginLeftPanel';
import RightSide, { ILoginRightPanel } from 'components/Authentication/LoginRightPanel';

interface ILogin extends ILoginRightPanel {}

const Login: React.FC<ILogin> = ({ user, onSubmit }) => (
  <div className="page-wrapper">
    <div className="page-inner bg-brand-gradient">
      <div className="page-content-wrapper bg-transparent m-0">
        <Header />
        <div className="flex-1 pesakan-login">
          <div className="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
            <div className="row">
              <LeftSide />
              <RightSide user={user} onSubmit={onSubmit} />
            </div>

            <div className="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
              All Rights Reserved © Copyright 2023
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Login;
