import React from 'react';

import Footer from 'components/Layout/Footer';

const NotFound: React.FC = () => {
    return (
        <div className="page-wrapper alt">
            <main id="js-page-content" role="main" className="page-content">
                <div className="h-alt-f d-flex flex-column align-items-center justify-content-center text-center">
                    <h1 className="page-error color-fusion-500">
                        ERROR <span className="text-gradient">404</span>
                        <small className="fw-500">
                            Something <u>went</u> wrong!
                        </small>
                    </h1>
                    <h3 className="fw-500 mb-5">
                        You have experienced a technical error. I apologize.
                    </h3>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default NotFound;
