import React, { useState, useEffect } from 'react';
import { navigate } from 'raviger';
import { useSelector, useDispatch } from 'react-redux';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

import Main from 'pages/Layout/Main';
import Notification, { AlertType } from 'components/Common/Notification';

import { RootState, AppDispatch } from 'store';
import { IGLPostingProgress, IFileUploadProgress } from 'types';
import { UserActions, CompanyActions, WebSocketActions } from 'store/actions';

const domain = process.env.REACT_APP_BACKEND_DOMAIN || `${window.location.hostname}${window.location.port ? `:${window.location.port}/api` : '/api'}`;
const wsClient = new W3CWebSocket(`${process.env.REACT_APP_WEBSOCKET_COM}://${domain}`, 'echo-protocol');

const toggleIcon = {
  title: 'Move menu to left',
  icon: 'fal fa-angle-double-left',
};

interface IMainContainer {
  screenID: string;
  children: React.ReactNode;
}

const MainContainer: React.FC<IMainContainer> = ({ screenID, children }) => {
  const body = document.body;
  const dispatch = useDispatch<AppDispatch>();
  const { auth, role, error, access, company, companyList } = useSelector((state: RootState) => ({
    access: state.access.access,
    auth: state.user.auth,
    role: state.role.role,
    error: state.error,
    company: state.company.company,
    companyList: state.company.company_list,
  }));

  const [fullName, setFullName] = useState('');
  const [navControl, setNavControl] = useState(toggleIcon);
  const [expandedMenu, setExpandedMenu] = useState(screenID);
  const [selectedMenu, setSelectedMenu] = useState(screenID);
  const [selectedParent, setSelectedParent] = useState(screenID);
  const [isTopNavigation, setIsTopNavigation] = useState(body.classList.contains('nav-function-top'));

  useEffect(() => {
    const defaultPageClasses = [
      'mod-bg-1',
      'desktop',
      'chrome',
      'webkit',
      'pace-done',
      'header-function-fixed',
      'mod-lean-subheader',
      'mod-clean-page-bg',
      'blur',
    ];

    defaultPageClasses.forEach((pageClass: string) => {
      document.body.classList.add(pageClass);
    });
    setIsTopNavigation(true);
  }, []);

  useEffect(() => {
    error.message && error.fetched && Notification({ type: AlertType.ERROR, message: error.message });
  }, [error.message, error.fetched]);

  useEffect(() => {
    dispatch(
      CompanyActions.companyFindActive({
        filter: [
          {
            field: 'is_valid',
            values: ['1'],
          },
        ],
      })
    );
    // Websocket
    wsClient.onopen = () => {
      console.log('WebSocket Client Connected');
    };

    wsClient.onmessage = (message) => {
      if (typeof message.data === 'string') {
        const wsMessage = JSON.parse(message.data);
        if (wsMessage?.post) {
          dispatch(WebSocketActions.glPosting(wsMessage.post as IGLPostingProgress));
        }
        if (wsMessage?.upload) {
          dispatch(WebSocketActions.fileUpload(wsMessage.upload as IFileUploadProgress));
        }
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (auth) {
      setFullName(`${auth.first_name} ${auth.last_name}`);
      body.classList.remove('nav-function-hidden');
      if (auth.menu === 'left') {
        body.classList.remove('nav-function-top');
        setIsTopNavigation(false);
        setNavControl({
          title: 'Move menu to top',
          icon: 'fal fa-angle-double-up',
        });
      } else {
        body.classList.add('nav-function-top');
        setIsTopNavigation(true);
        setNavControl(toggleIcon);
      }
    }
  }, [auth, body]);

  const moveNavigation = () => {
    if (auth) {
      if (!body.classList.contains('nav-function-top')) {
        dispatch(UserActions.userMenuPosition({ id: auth.user_id, position: 'top' }));
      } else {
        dispatch(UserActions.userMenuPosition({ id: auth.user_id, position: 'left' }));
      }
    }
  };

  const toggleNavigation = () => {
    // alert('mobile-view-activated mobile-nav-on')
    if (!body.classList.contains('nav-function-top')) {
      body.classList.toggle('nav-function-hidden');
    }
    body.classList.add('mobile-view-activated');
    body.classList.add('mobile-nav-on');
  };

  const mobileViewOff = () => {
    body.classList.remove('mobile-view-activated');
    body.classList.remove('mobile-nav-on');
  };

  const handleCompanySelect = async (companyId: string) => {
    await dispatch(UserActions.setCompany(companyId));
    navigate('/dashboard');
  };
  const handleProfile = () => navigate('/profile');
  const handleLogout = () => {
    dispatch(CompanyActions.companyLogout());
    dispatch(UserActions.userLogout());
  };

  const handleParentItem = (path: string) => {
    setSelectedMenu(path);
    if (selectedParent === path) {
      setExpandedMenu('/');
      setSelectedParent('/');
    } else {
      setSelectedParent(path);
      setExpandedMenu(path);
    }
  };
  const handleChildItem = (path: string) => setSelectedMenu(path);

  return (
    <Main
      screenId={screenID}
      company={company}
      companyList={companyList}
      isLoading={false}
      isTopNavigation={isTopNavigation}
      role={role}
      username={fullName}
      navControl={navControl}
      email={auth?.email || ''}
      access={access}
      selectedMenu={selectedMenu}
      selectedParent={selectedParent}
      expandedMenu={expandedMenu}
      mobileViewOff={mobileViewOff}
      handleChildItem={handleChildItem}
      handleParentItem={handleParentItem}
      moveNavigation={moveNavigation}
      handleLogout={handleLogout}
      toggleNavigation={toggleNavigation}
      handleProfile={handleProfile}
      handleCompanySelect={handleCompanySelect}
    >
      {children}
    </Main>
  );
};

export default MainContainer;
