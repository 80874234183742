import React, { memo } from 'react';
import { Link } from 'raviger';
import Scrollbars from 'react-custom-scrollbars';

import Tooltip from 'components/Common/Tooltip';
import Navigation, { INavigation } from 'components/Common/Navigation';
import Logo from 'assets/images/pesakan-logo.png';
import Avatar from 'assets/images/avatar-m.png';
import CardCover from 'assets/images/card-backgrounds/cover-2-lg.png';
import { IRole } from 'types';

export interface ISidebar extends Exclude<INavigation, 'role_id'> {
  username: string;
  role?: IRole;
  isTopNavigation: boolean;
  handleLogout: () => void;
}

const Sidebar: React.FC<ISidebar> = ({
  username,
  role,
  access,
  selectedMenu,
  selectedParent,
  expandedMenu,
  isTopNavigation,
  handleLogout,
  handleChildItem,
  handleParentItem,
}) => {
  const body = document.body;
  const mainMenu = (
    <nav id="js-primary-nav" className="primary-nav" role="navigation">
      <div className="info-card">
        <img src={Avatar} className="profile-image rounded-circle" alt={username} />
        <div className="info-card-text">
          <div className="d-flex align-items-center text-white">
            <span className="text-truncate text-truncate-sm d-inline-block">{username}</span>
          </div>
          <span className="d-inline-block text-truncate text-truncate-sm">{role && role.role_code}</span>
        </div>
        <img src={CardCover} className="cover" alt="cover" />
      </div>
      {isTopNavigation && !body.classList.contains('mobile-view-activated') ? (
        <div id="js-nav-menu-wrapper" className="nav-menu-wrapper d-flex flex-grow-1 overflow-hidden">
          <Navigation
            role_id={role?.id}
            access={access}
            selectedMenu={selectedMenu}
            selectedParent={selectedParent}
            expandedMenu={expandedMenu}
            handleChildItem={handleChildItem}
            handleParentItem={handleParentItem}
          />
        </div>
      ) : (
        <Navigation
          role_id={role?.id}
          access={access}
          selectedMenu={selectedMenu}
          selectedParent={selectedParent}
          expandedMenu={expandedMenu}
          handleChildItem={handleChildItem}
          handleParentItem={handleParentItem}
        />
      )}
    </nav>
  );

  return (
    <aside className="page-sidebar">
      <div className="page-logo">
        <img src={Logo} alt="Pesakan Laundry Services" aria-roledescription="logo" />
      </div>

      {isTopNavigation ? mainMenu : <Scrollbars className="slimScrollBar">{mainMenu}</Scrollbars>}

      <div className="nav-footer shadow-top">
        <button type="button" title="Toggle Menu" data-action="toggle" data-class="nav-function-minify" className="hidden-md-down">
          <i className="ni ni-chevron-right"></i>
          <i className="ni ni-chevron-right"></i>
        </button>
        <ul className="list-table m-auto nav-footer-buttons">
          <li>
            <Link href="#" onClick={handleLogout}>
              <Tooltip placement="top" title="Logout">
                <i className="fal fa-sign-out"></i>
              </Tooltip>
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default memo(Sidebar);
