import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  IItemCategory,
  IItemCategories,
  IItemCategoryCreate,
  IItemCategoryUpdate,
  ITEM_CATEGORY_CREATE,
  ITEM_CATEGORY_UPDATE,
  ITEM_CATEGORY_FIND_BY_ID,
  ITEM_CATEGORY_FIND_BY_CODE,
  ITEM_CATEGORY_FIND_FIRST,
  ITEM_CATEGORY_FIND_LAST,
  ITEM_CATEGORY_FIND_ALL,
  ITEM_CATEGORY_CLEAR_DATA,
} from 'types/item.category.types';

const itemCategoryCreate = createAsyncThunk<IItemCategory, IItemCategoryCreate>(ITEM_CATEGORY_CREATE, async (data) => request.post('/item_categories', { ...data }));
const itemCategoryUpdate = createAsyncThunk<IItemCategory, IItemCategoryUpdate>(ITEM_CATEGORY_UPDATE, async (data) =>
  request.put(`/item_categories/id/${data.id}`, { ...data })
);
const itemCategoryFindByID = createAsyncThunk<IItemCategory, number, any>(ITEM_CATEGORY_FIND_BY_ID, async (id, query) =>
  request.get(`/item_categories/id/${id}`, query)
);
const itemCategoryFindByCode = createAsyncThunk<IItemCategory, string>(ITEM_CATEGORY_FIND_BY_CODE, async (code) => request.get(`/item_categories/${code}`));
const itemCategoryFindFirst = createAsyncThunk<IItemCategory>(ITEM_CATEGORY_FIND_FIRST, async () => request.get('/item_categories/find/first'));
const itemCategoryFindLast = createAsyncThunk<IItemCategory>(ITEM_CATEGORY_FIND_LAST, async () => request.get('/item_categories/find/last'));
const itemCategoryFindAll = createAsyncThunk<IItemCategories, Partial<ITable>>(ITEM_CATEGORY_FIND_ALL, async (query) => request.get('/item_categories', query));
const itemCategoryClearData = createAction(ITEM_CATEGORY_CLEAR_DATA);

const ItemCategoryActions = {
  itemCategoryCreate,
  itemCategoryUpdate,
  itemCategoryFindByID,
  itemCategoryFindByCode,
  itemCategoryFindFirst,
  itemCategoryFindLast,
  itemCategoryFindAll,
  itemCategoryClearData,
};

export default ItemCategoryActions;
