import { IFetch } from 'constants/types';

export const SUBLEDGER_IMPORT = 'SUBLEDGER_IMPORT';
export const SUBLEDGER_CREATE = 'SUBLEDGER_CREATE';
export const SUBLEDGER_UPDATE = 'SUBLEDGER_UPDATE';
export const SUBLEDGER_FIND_BY_ID = 'SUBLEDGER_FIND_BY_ID';
export const SUBLEDGER_FIND_BY_CODE = 'SUBLEDGER_FIND_BY_CODE';
export const SUBLEDGER_FIND_FIRST = 'SUBLEDGER_FIND_FIRST';
export const SUBLEDGER_FIND_LAST = 'SUBLEDGER_FIND_LAST';
export const SUBLEDGER_FIND_ALL = 'SUBLEDGER_FIND_ALL';
export const SUBLEDGER_CLEAR_DATA = 'SUBLEDGER_CLEAR_DATA';

export type SubledgerStatus = 'pending' | 'posted' | 'reversed';

export interface SubledgerState extends IFetch {
  data: ISubledger | undefined;
  list: ISubledger[];
  total: number;
  page: number;
}

export type ISubledger = {
  id: number;
  subledger_code: string;
  subledger_description: string;
  subledger_status: SubledgerStatus;
  transaction_year: number;
  transaction_line: number;
  is_imported?: number;
  source_subledger_id?: number;
  source_subledger_code?: string;
  reversal_id?: number;
  reversal_code?: string;
  company_id: string;
  created_by: string;
  created_date: string;
  last_saved_by: string;
  last_saved_date: string;
  details?: Array<ISubledgerDetail>;
};

export type ISubledgerImport = {
  subledger_code: string;
  subledger_description: string;
  transaction_year: number;
  transaction_line: number;
  document_ids: Array<string>;
}

export type ISubledgerDetail = {
  id: number;
  line: number;
  toDelete?: boolean | number;
  subledger_id: number;
  subledger_status: string;
  document_id?: string;
  transaction_id?: number;
  transaction_line_id?: number;
  transaction_date?: string;
  transaction_code?: string;
  debit: number;
  credit: number;
  account_id: number;
  combination_code?: string;
  combination_name?: string;
  company_id: string;
  created_by: string;
  created_date: string;
  last_saved_by: string;
  last_saved_date: string;
};

export interface CreateSubledgerDetail
  extends Pick<
    ISubledgerDetail,
    | 'line'
    | 'subledger_status'
    | 'document_id'
    | 'transaction_id'
    | 'transaction_line_id'
    | 'transaction_date'
    | 'debit'
    | 'credit'
    | 'account_id'
  > {
  id?: number;
}

export interface UpdateSubledgerDetail extends CreateSubledgerDetail {
  id?: number;
}

export interface ISubledgerCreate
  extends Pick<ISubledger, 'subledger_code' | 'subledger_description' | 'subledger_status' | 'transaction_year' | 'transaction_line'> {
  details: Array<CreateSubledgerDetail>;
  previous_status?: string;
}

export interface ISubledgerUpdate extends ISubledgerCreate {
  id?: number;
  details: Array<UpdateSubledgerDetail>;
  toDelete: Array<number>;
}

export interface SubledgerForm extends ISubledger {
  year_id: number;
  subledger_status_label: string;
  start_date: string;
  end_date: string;
}
