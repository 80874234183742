import { IFetch } from 'constants/types';

export const ACCOUNT_CREATE = 'ACCOUNT_CREATE';
export const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE';
export const ACCOUNT_FIND_BY_ID = 'ACCOUNT_FIND_BY_ID';
export const ACCOUNT_FIND_BY_CODE = 'ACCOUNT_FIND_BY_CODE';
export const ACCOUNT_FIND_FIRST = 'ACCOUNT_FIND_FIRST';
export const ACCOUNT_FIND_LAST = 'ACCOUNT_FIND_LAST';
export const ACCOUNT_FIND_ALL = 'ACCOUNT_FIND_ALL';
export const ACCOUNT_CLEAR_DATA = 'ACCOUNT_CLEAR_DATA';

export type IAccount = {
  id: number;
  account_code: string;
  account_description: string;
  company_id: string;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};

export interface AccountState extends IFetch {
  data?: IAccount;
  list: IAccount[];
  total: number;
}

export type ICreateAccount = {
  account_code: string;
  account_description: string;
  company_id: string;
};

export interface IUpdateAccount extends ICreateAccount {
  id: number;
}

export const ACCOUNT_COMPANY_CREATE = 'ACCOUNT_COMPANY_CREATE';
export const ACCOUNT_COMPANY_UPDATE = 'ACCOUNT_COMPANY_UPDATE';
export const ACCOUNT_COMPANY_FIND_BY_ID = 'ACCOUNT_COMPANY_FIND_BY_ID';
export const ACCOUNT_COMPANY_FIND_BY_CODE = 'ACCOUNT_COMPANY_FIND_BY_CODE';
export const ACCOUNT_COMPANY_FIND_FIRST = 'ACCOUNT_COMPANY_FIND_FIRST';
export const ACCOUNT_COMPANY_FIND_LAST = 'ACCOUNT_COMPANY_FIND_LAST';
export const ACCOUNT_COMPANY_FIND_ALL = 'ACCOUNT_COMPANY_FIND_ALL';
export const ACCOUNT_COMPANY_CLEAR_DATA = 'ACCOUNT_COMPANY_CLEAR_DATA';

export type IAccountCompany = {
  id: number;
  segment_code: string;
  segment_description: string;
  company_id: string;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};

export interface AccountCompanyState extends IFetch {
  data?: IAccountCompany;
  list: IAccountCompany[];
  total: number;
}

export type ICreateAccountCompany = {
  segment_code: string;
  segment_description: string;
  company_id: string;
};

export interface IUpdateAccountCompany extends ICreateAccountCompany {
  id: number;
}

export const ACCOUNT_DIVISION_CREATE = 'ACCOUNT_DIVISION_CREATE';
export const ACCOUNT_DIVISION_UPDATE = 'ACCOUNT_DIVISION_UPDATE';
export const ACCOUNT_DIVISION_FIND_BY_ID = 'ACCOUNT_DIVISION_FIND_BY_ID';
export const ACCOUNT_DIVISION_FIND_BY_CODE = 'ACCOUNT_DIVISION_FIND_BY_CODE';
export const ACCOUNT_DIVISION_FIND_FIRST = 'ACCOUNT_DIVISION_FIND_FIRST';
export const ACCOUNT_DIVISION_FIND_LAST = 'ACCOUNT_DIVISION_FIND_LAST';
export const ACCOUNT_DIVISION_FIND_ALL = 'ACCOUNT_DIVISION_FIND_ALL';
export const ACCOUNT_DIVISION_CLEAR_DATA = 'ACCOUNT_DIVISION_CLEAR_DATA';

export type IAccountDivision = {
  id: number;
  division_code: string;
  division_description: string;
  company_id: string;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};

export interface AccountDivisionState extends IFetch {
  data?: IAccountDivision;
  list: IAccountDivision[];
  total: number;
}

export type ICreateAccountDivision = {
  division_code: string;
  division_description: string;
  company_id: string;
};

export interface IUpdateAccountDivision extends ICreateAccountDivision {
  id: number | string;
}
