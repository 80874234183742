import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { PayableState } from 'types/payable.types';
import PayableAction from 'store/actions/payable.action';

export const PayableInitialState: PayableState = {
  data: undefined,
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type PayableType = typeof PayableInitialState;

const handlePayable = (state: PayableState, action: any) => {
  const { payable = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: payable,
    next,
    previous,
  };
};

const payableReducer = createSlice({
  name: 'Payable',
  initialState: PayableInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PayableAction.payableFindAll.fulfilled, (state, action) => {
        const { payables: list = [], total = 0 } = get(action, 'payload.data', { payables: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(PayableAction.payableCreate.fulfilled, handlePayable)
      .addCase(PayableAction.payableUpdate.fulfilled, handlePayable)
      .addCase(PayableAction.payableFindByID.fulfilled, handlePayable)
      .addCase(PayableAction.payableFindByCode.fulfilled, handlePayable)
      .addCase(PayableAction.payableFindFirst.fulfilled, handlePayable)
      .addCase(PayableAction.payableFindLast.fulfilled, handlePayable)
      .addCase(PayableAction.payableClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default payableReducer.reducer;
