import React from 'react';

import { IRoute, IRouteLinks } from 'constants/interface';

export const TransactionLinks: IRouteLinks = {
  id: 'transaction',
  title: 'Transactions',
  icon: 'dollar-sign',
  sort: 3,
  routes: [
    {
      id: 'sales',
      title: 'Sales',
      path: '/transaction/sales',
      noNew: true,
      noDelete: true,
      sort: 1,
      companyRestricted: true,
    },
    {
      id: 'purchases',
      title: 'Stocks Purchases',
      path: '/transaction/purchases',
      noDelete: true,
      sort: 2,
      companyRestricted: true,
    },
    {
      id: 'vouchers',
      title: 'Cash/Check Voucher',
      path: '/transaction/vouchers',
      noDelete: true,
      sort: 3,
      companyRestricted: true,
    },
    {
      id: 'loyalty',
      title: 'Customer Loyalty',
      path: '/transaction/loyalty',
      noDelete: true,
      sort: 4,
      companyRestricted: true,
    },
    // {
    //   id: 'payables',
    //   title: 'Account Payables',
    //   path: '/transaction/payables',
    //   noDelete: true,
    //   sort: 3,
    //   companyRestricted: true,
    // },
    // {
    //   id: 'customer_deposits',
    //   title: 'Customer Deposit',
    //   path: '/transaction/customer_deposits',
    //   noDelete: true,
    //   sort: 3,
    //   companyRestricted: true,
    // },
    // {
    //   id: 'collections',
    //   title: 'Collections',
    //   path: '/transaction/collections',
    //   noDelete: true,
    //   sort: 4,
    //   companyRestricted: true,
    // },
    // {
    //   id: 'deposits',
    //   title: 'Deposit Slips',
    //   path: '/transaction/deposits',
    //   noDelete: true,
    //   sort: 5,
    //   companyRestricted: true,
    // },
    {
      id: 'subledgers',
      title: 'Subledger Entries',
      path: '/transaction/subledgers',
      noDelete: true,
      sort: 6,
      companyRestricted: true,
    },
  ],
};

const TransactionRouter: IRoute[] = [
  // {
  //   path: '/transaction/payables',
  //   component: React.lazy(() => import('containers/Transactions/PayableContainer')),
  // },
  {
    path: '/transaction/vouchers',
    component: React.lazy(() => import('containers/Transactions/VoucherContainer')),
  },
  {
    path: '/transaction/sales',
    component: React.lazy(() => import('containers/Transactions/SaleContainer')),
  },
  {
    path: '/transaction/purchases',
    component: React.lazy(() => import('containers/Transactions/PurchaseContainer')),
  },
  {
    path: '/transaction/loyalty',
    component: React.lazy(() => import('containers/Transactions/LoyaltyContainer')),
  },
  {
    path: '/transaction/subledgers',
    component: React.lazy(() => import('containers/Transactions/SubledgerContainer')),
  },
];

export default TransactionRouter;
