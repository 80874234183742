import { AdminLinks } from 'router/admin.route';
import { HomeLinks } from 'router/home.route';
import { MasterLinks } from 'router/master.route';
// import { ReportLinks } from 'router/reports.route';
import { SetupLinks } from 'router/setup.route';
import { TransactionLinks } from 'router/transaction.route';

const AppLinks = {
    AdminLinks,
    HomeLinks,
    MasterLinks,
    // ReportLinks,
    SetupLinks,
    TransactionLinks,
};

export default AppLinks;