import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { AccountState } from 'types/account.types';
import AccountAction from 'store/actions/account.action';

export const AccountInitialState: AccountState = {
  data: undefined,
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type AccountType = typeof AccountInitialState;

const handleAccount = (state: AccountState, action: any) => {
  const { account = undefined, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: account,
    next,
    previous,
  };
};

const accountReducer = createSlice({
  name: 'Account',
  initialState: AccountInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AccountAction.accountFindAll.fulfilled, (state, action) => {
        const { accounts: list = [], total = 0 } = get(action, 'payload.data', { accounts: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(AccountAction.accountCreate.fulfilled, handleAccount)
      .addCase(AccountAction.accountUpdate.fulfilled, handleAccount)
      .addCase(AccountAction.accountFindByID.fulfilled, handleAccount)
      .addCase(AccountAction.accountFindByCode.fulfilled, handleAccount)
      .addCase(AccountAction.accountFindFirst.fulfilled, handleAccount)
      .addCase(AccountAction.accountFindLast.fulfilled, handleAccount)
      .addCase(AccountAction.accountClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default accountReducer.reducer;
