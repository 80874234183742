import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  ISubledger,
  ISubledgerCreate,
  ISubledgerUpdate,
  ISubledgerImport,
  SUBLEDGER_IMPORT,
  SUBLEDGER_CREATE,
  SUBLEDGER_UPDATE,
  SUBLEDGER_FIND_BY_ID,
  SUBLEDGER_FIND_BY_CODE,
  SUBLEDGER_FIND_FIRST,
  SUBLEDGER_FIND_LAST,
  SUBLEDGER_FIND_ALL,
  SUBLEDGER_CLEAR_DATA,
} from 'types/subledger.types';

const subledgerImportEntries = createAsyncThunk<ISubledger, ISubledgerImport>(SUBLEDGER_IMPORT, async (data) =>
  request.post('/subledgers/import', { ...data })
);
const subledgerCreate = createAsyncThunk<ISubledger, ISubledgerCreate>(SUBLEDGER_CREATE, async (data) =>
  request.post('/subledgers', { ...data })
);
const subledgerUpdate = createAsyncThunk<ISubledger, ISubledgerUpdate>(SUBLEDGER_UPDATE, async (data) =>
  request.put(`/subledgers/id/${data.id}`, { ...data })
);
const subledgerFindByID = createAsyncThunk<ISubledger, number, any>(SUBLEDGER_FIND_BY_ID, async (id, query) =>
  request.get(`/subledgers/id/${id}`, query)
);
const subledgerFindByCode = createAsyncThunk<ISubledger, string>(SUBLEDGER_FIND_BY_CODE, async (code) =>
  request.get(`/subledgers/${code}`)
);
const subledgerFindFirst = createAsyncThunk<ISubledger>(SUBLEDGER_FIND_FIRST, async () => request.get('/subledgers/find/first'));
const subledgerFindLast = createAsyncThunk<ISubledger>(SUBLEDGER_FIND_LAST, async () => request.get('/subledgers/find/last'));
const subledgerFindAll = createAsyncThunk<ISubledger[], Partial<ITable>>(SUBLEDGER_FIND_ALL, async (query) =>
  request.get('/subledgers', query)
);
const subledgerClearData = createAction(SUBLEDGER_CLEAR_DATA);

const SubledgerActions = {
  subledgerCreate,
  subledgerUpdate,
  subledgerFindByID,
  subledgerFindByCode,
  subledgerFindFirst,
  subledgerFindLast,
  subledgerFindAll,
  subledgerImportEntries,
  subledgerClearData,
};

export default SubledgerActions;
