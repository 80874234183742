import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  IPayable,
  IPayableCreate,
  IPayableUpdate,
  PAYABLE_CREATE,
  PAYABLE_UPDATE,
  PAYABLE_FIND_BY_ID,
  PAYABLE_FIND_BY_CODE,
  PAYABLE_FIND_FIRST,
  PAYABLE_FIND_LAST,
  PAYABLE_FIND_ALL,
  PAYABLE_CLEAR_DATA,
} from 'types/payable.types';

const payableCreate = createAsyncThunk<IPayable, IPayableCreate>(PAYABLE_CREATE, async (data) => request.post('/payables', { ...data }));
const payableUpdate = createAsyncThunk<IPayable, IPayableUpdate>(PAYABLE_UPDATE, async (data) =>
  request.put(`/payables/id/${data.id}`, { ...data })
);
const payableFindByID = createAsyncThunk<IPayable, number, any>(PAYABLE_FIND_BY_ID, async (id, query) =>
  request.get(`/payables/id/${id}`, query)
);
const payableFindByCode = createAsyncThunk<IPayable, string>(PAYABLE_FIND_BY_CODE, async (code) => request.get(`/payables/${code}`));
const payableFindFirst = createAsyncThunk<IPayable>(PAYABLE_FIND_FIRST, async () => request.get('/payables/find/first'));
const payableFindLast = createAsyncThunk<IPayable>(PAYABLE_FIND_LAST, async () => request.get('/payables/find/last'));
const payableFindAll = createAsyncThunk<IPayable[], Partial<ITable>>(PAYABLE_FIND_ALL, async (query) => request.get('/payables', query));
const payableClearData = createAction(PAYABLE_CLEAR_DATA);

const PayableActions = {
  payableCreate,
  payableUpdate,
  payableFindByID,
  payableFindByCode,
  payableFindFirst,
  payableFindLast,
  payableFindAll,
  payableClearData,
};

export default PayableActions;
