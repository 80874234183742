import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  ICompany,
  ICompanies,
  ICompanyCreate,
  ICompanyUpdate,
  COMPANY_SELECT,
  COMPANY_CREATE,
  COMPANY_UPDATE,
  COMPANY_FIND_BY_ID,
  COMPANY_FIND_BY_CODE,
  COMPANY_FIND_FIRST,
  COMPANY_FIND_LAST,
  COMPANY_FIND_ALL,
  COMPANY_ACTIVE_COMPANY,
  COMPANY_CLEAR_DATA,
  COMPANY_LOGOUT,
} from 'types/company.types';

const companyCreate = createAsyncThunk<ICompany, ICompanyCreate>(COMPANY_CREATE, async (data) => request.post('/company', { ...data }));
const companyUpdate = createAsyncThunk<ICompany, ICompanyUpdate>(COMPANY_UPDATE, async (data) =>
  request.put(`/company/id/${data.id}`, { ...data })
);
const companyFindByID = createAsyncThunk<ICompany, number, any>(COMPANY_FIND_BY_ID, async (id, query) =>
  request.get(`/company/id/${id}`, query)
);
const companyFindByCode = createAsyncThunk<ICompany, string>(COMPANY_FIND_BY_CODE, async (code) => request.get(`/company/${code}`));
const companyFindFirst = createAsyncThunk<ICompany>(COMPANY_FIND_FIRST, async () => request.get('/company/find/first'));
const companyFindLast = createAsyncThunk<ICompany>(COMPANY_FIND_LAST, async () => request.get('/company/find/last'));
const companyFindAll = createAsyncThunk<ICompanies, Partial<ITable>>(COMPANY_FIND_ALL, async (query) => request.get('/company', query));
const companyFindActive = createAsyncThunk<ICompanies, Partial<ITable>>(COMPANY_ACTIVE_COMPANY, async (query) =>
  request.get('/company', query)
);
const companyClearData = createAction(COMPANY_CLEAR_DATA);
const companyLogout = createAction(COMPANY_LOGOUT);
const companySelect = createAsyncThunk<ICompany, string>(COMPANY_SELECT, async (id) => request.get(`/company/id/${id}`));

const CompanyActions = {
  companySelect,
  companyCreate,
  companyUpdate,
  companyFindByID,
  companyFindByCode,
  companyFindFirst,
  companyFindLast,
  companyFindAll,
  companyFindActive,
  companyClearData,
  companyLogout,
};

export default CompanyActions;
