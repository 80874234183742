import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import CalendarAction from 'store/actions/calendar.action';
import { CalendarState, ICalendarYear, ICalendarPeriod } from 'types/calendar.types';

export const CalendarInitialState: CalendarState = {
  data: undefined,
  list: [],
  periods: [],
  total: 0,
  totalPeriods: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type CalendarType = typeof CalendarInitialState;

const handleCalendar = (state: CalendarState, action: any) => {
  const {
    calendarYear: data,
    next,
    previous,
  } = get(action, 'payload.data', { calendarYear: undefined, next: undefined, previous: undefined });
  return {
    ...state,
    data,
    next,
    previous,
  };
};

const calendarReducer = createSlice({
  name: 'User',
  initialState: CalendarInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CalendarAction.calendarFindAll.fulfilled, (state, action) => {
        const list: ICalendarYear[] = get(action, 'payload.data.calendarYears', []);
        const total: number = get(action, 'payload.data.total', 0);
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(CalendarAction.calendarFindPeriods.fulfilled, (state, action) => {
        const periods: ICalendarPeriod[] = get(action, 'payload.data.calendarPeriods', []);
        const totalPeriods: number = get(action, 'payload.data.total', 0);
        return {
          ...state,
          periods,
          totalPeriods,
        };
      })
      .addCase(CalendarAction.calendarCreate.fulfilled, handleCalendar)
      .addCase(CalendarAction.calendarUpdate.fulfilled, handleCalendar)
      .addCase(CalendarAction.calendarFindByYear.fulfilled, handleCalendar)
      .addCase(CalendarAction.calendarFindFirst.fulfilled, handleCalendar)
      .addCase(CalendarAction.calendarFindLast.fulfilled, handleCalendar)
      .addCase(CalendarAction.calendarClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default calendarReducer.reducer;
