import { IFileUploadProgress } from 'types/websocket.types';

export const UPLOAD_BANK = 'UPLOAD_BANK';
export const UPLOAD_CUSTOMER = 'UPLOAD_CUSTOMER';
export const UPLOAD_SUPPLIER = 'UPLOAD_SUPPLIER';
export const UPLOAD_DIVISION = 'UPLOAD_DIVISION';
export const UPLOAD_ACCOUNT = 'UPLOAD_ACCOUNT';
export const UPLOAD_ACCOUNT_BALANCE = 'UPLOAD_ACCOUNT_BALANCE';
export const UPLOAD_ACCOUNT_MAPPING = 'UPLOAD_ACCOUNT_MAPPING';
export const UPLOAD_ACCOUNT_COMBINATION = 'UPLOAD_ACCOUNT_COMBINATION';

export interface IUploadCard {
  progress: IFileUploadProgress;
  isLoading: boolean;
  handleUpload: (type: string, file: File | null) => void;
}
