import React from 'react';
import get from 'lodash/get';
import { Carousel } from 'react-bootstrap';

import CashOnDelivery from 'assets/images/ads/CashonDelivery.png';
import PickupDelivery from 'assets/images/ads/PickupDelivery.png';
import SpinningNow from 'assets/images/ads/SpinningNow.jpg';
import DryCleaning from 'assets/images/ads/DryCleaning.png';
import Ironing from 'assets/images/ads/Ironing.png';
import Honesty from 'assets/images/ads/Honesty.png';

const images = {
  'Spinning Now': SpinningNow,
  'Pickup and Delivery': PickupDelivery,
  'Cash on Delivery': CashOnDelivery,
  'Dry Cleaning': DryCleaning,
  Ironing,
  Honesty,
};

const LoginLeftPanel: React.FC = () => {
  return (
    <div className="col col-md-6 col-lg-7 hidden-sm-down">
      <Carousel nextLabel="" prevLabel="" variant="dark">
        {Object.entries(images).map((key, image) => (
          <Carousel.Item key={image}>
            <img className="d-block w-100" src={get(images, key[0])} alt={key[0]} />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default LoginLeftPanel;
