import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { ItemState } from 'types/item.types';
import ItemAction from 'store/actions/item.action';

export const ItemInitialState: ItemState = {
  data: undefined,
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type ItemType = typeof ItemInitialState;

const handleItem = (state: ItemState, action: any) => {
  const { item = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: item,
    next,
    previous,
  };
};

const itemReducer = createSlice({
  name: 'Item',
  initialState: ItemInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ItemAction.itemFindAll.fulfilled, (state, action) => {
        const { items: list = [], total = 0 } = get(action, 'payload.data', { items: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(ItemAction.itemCreate.fulfilled, handleItem)
      .addCase(ItemAction.itemUpdate.fulfilled, handleItem)
      .addCase(ItemAction.itemFindByID.fulfilled, handleItem)
      .addCase(ItemAction.itemFindByCode.fulfilled, handleItem)
      .addCase(ItemAction.itemFindFirst.fulfilled, handleItem)
      .addCase(ItemAction.itemFindLast.fulfilled, handleItem)
      .addCase(ItemAction.itemClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default itemReducer.reducer;
