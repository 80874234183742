import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

export enum AlertType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'danger',
}

export interface IAlert {
  type?: AlertType;
  message: string;
}

let alertList: any = [];

const PARENT_ELEMENT = 'pesakan-alert-wrapper';

// Create component container
const createContainer = () => {
  const parent: HTMLElement = document.createElement('div');
  parent.setAttribute('class', 'pesakan-alert-wrapper');
  document.body.appendChild(parent);

  const node = document.createElement('ul');
  node.setAttribute('id', PARENT_ELEMENT);
  parent.appendChild(node);

  return node;
};

const removeNode = (node: HTMLLIElement) => {
  ReactDOM.unmountComponentAtNode(node);
  // Removes the component parent node in the DOM
  node.remove();

  alertList = alertList.filter((alert: any) => alert.node !== node);

  // If no active alerts, remove the parent
  if (alertList.length === 0) {
    const element = document.getElementById(PARENT_ELEMENT);

    if (element && element.parentElement) {
      element.parentElement.remove();
    }
  }
};

interface IToastr {
  alert: IAlert;
  node: HTMLLIElement;
}

const Toastr: React.FC<IToastr> = (props) => {
  const [icon, setIcon] = useState('check');

  useEffect(() => {
    if (props.alert.type && props.alert.type === AlertType.ERROR) {
      setIcon('exclamation-circle');
    }

    alertList.push({
      node: props.node,
      message: props.alert.message,
    });

    animateCSS('bounceInUp');
    setTimeout(() => animateCSS('slideOutDown', () => removeNode(props.node)), 4000);
  }, [props.node, props.alert]);

  const animateCSS = (animation: string, callback?: () => void) => {
    const node = document.querySelector('.pesakan-alerts');

    if (node) {
      node.classList.add(animation);

      const handleAnimationEnd = () => {
        node.classList.remove(animation);
        node.removeEventListener('animationend', handleAnimationEnd);

        callback && callback();
      };

      node.addEventListener('animationend', handleAnimationEnd);
    }
  };

  const closeAlert = () => {
    animateCSS('slideOutDown', () => removeNode(props.node));
  };

  return (
    <div className={`pesakan-alerts bg-${props.alert.type || AlertType.SUCCESS}-400 text-white animated`}>
      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={closeAlert}>
        <span aria-hidden="true">
          <i className="fal fa-times"></i>
        </span>
      </button>
      <span>
        {icon && <i className={`fa fa-${icon}`} />} {props.alert.message}
      </span>
    </div>
  );
};

const Notification = (params: IAlert) => {
  const list = [...alertList];

  if (!params.message || !params.message.trim()) {
    return;
  }

  list.forEach((alert) => {
    if (alert.message === params.message) {
      removeNode(alert.node);
    }
  });

  let parent = document.getElementById(PARENT_ELEMENT);
  if (!parent) {
    parent = createContainer();
  }

  const node = document.createElement('li');
  parent.appendChild(node);

  ReactDOM.render(<Toastr node={node} alert={params} />, node);
};

export default Notification;
