import { IFetch } from 'constants/types';

export const ACCOUNT_COMBINATION_SUMMARY = 'ACCOUNT_COMBINATION_SUMMARY';
export const ACCOUNT_COMBINATION_CREATE = 'ACCOUNT_COMBINATION_CREATE';
export const ACCOUNT_COMBINATION_UPDATE = 'ACCOUNT_COMBINATION_UPDATE';
export const ACCOUNT_COMBINATION_FIND_BY_ID = 'ACCOUNT_COMBINATION_FIND_BY_ID';
export const ACCOUNT_COMBINATION_FIND_BY_CODE = 'ACCOUNT_COMBINATION_FIND_BY_CODE';
export const ACCOUNT_COMBINATION_FIND_FIRST = 'ACCOUNT_COMBINATION_FIND_FIRST';
export const ACCOUNT_COMBINATION_FIND_LAST = 'ACCOUNT_COMBINATION_FIND_LAST';
export const ACCOUNT_COMBINATION_FIND_ALL = 'ACCOUNT_COMBINATION_FIND_ALL';
export const ACCOUNT_COMBINATION_CLEAR_DATA = 'ACCOUNT_COMBINATION_CLEAR_DATA';

export type AccountType = 'asset' | 'liabilities' | 'equity' | 'income' | 'cost of sales' | 'expenses';

export const AccountTypeLabel = {
  asset: 'Asset',
  liabilities: 'Liabilities',
  equity: 'Equity',
  income: 'Income',
  'cost of sales': 'Cost of Sales',
  expenses: 'Expenses',
};

export type AccountSummaryTree = {
  id: number;
  combination_code: string;
  combination_name: string;
  parent_id: number;
  account_type: AccountType;
  children?: Array<AccountSummaryTree>;
};

export type IAccountCombination = {
  id: number;
  company_id: number;
  segment_id: number;
  segment_code: string;
  segment_description: string;
  division_id: number;
  division_code: string;
  division_description: string;
  account_id: number;
  account_code: string;
  account_description: string;
  account_type: AccountType;
  combination_code: string;
  combination_name: string;
  parent_id: number | null;
  parent_code: string;
  parent_name: string;
  is_parent_account: number;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};

export interface AccountCombinationState extends IFetch {
  data?: IAccountCombination;
  list: IAccountCombination[];
  summary: AccountSummaryTree[];
  total: number;
}

export type ICreateAccountCombination = {
  company_id: string;
  segment_id: number;
  division_id: number;
  account_id: number;
  parent_id: number;
  combination_name: string;
  account_type: AccountType;
};

export interface IUpdateAccountCombination extends ICreateAccountCombination {
  id: number;
}
