import React from 'react';
import { useForm } from 'react-hook-form';

export type ILoginForm = {
  username: string;
  password: string;
};
export interface ILoginRightPanel {
  user: {
    fetching: boolean;
  };
  onSubmit: (data: ILoginForm) => void;
}

const LoginRightPanel: React.FC<ILoginRightPanel> = ({ user, onSubmit }) => {
  const { register, handleSubmit } = useForm<ILoginForm>();
  return (
    <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4 ml-auto">
      <h2 className="fs-xxl fw-500 mt-4 text-white">
        Delivering quality laundry services.
        <small className="h3 fw-300 mt-3 mb-5 text-white opacity-60">Pesakan is Pangasinan's Quality Laundry Services.</small>
      </h2>
      <div className="card p-4 rounded-plus bg-faded">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label className="form-label" htmlFor="username">
              Username
            </label>
            <input
              {...register('username', { required: true })}
              autoComplete="off"
              type="text"
              id="username"
              name="username"
              className="form-control form-control-lg"
            />
            <div className="help-block">Your unique username to app</div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="password">
              Password
            </label>
            <input
              {...register('password', { required: true })}
              type="password"
              id="password"
              name="password"
              autoComplete="off"
              className="form-control form-control-lg"
            />
            <div className="help-block">Your password</div>
          </div>

          <div className="row no-gutters">
            <div className="col-lg-6 pr-lg-1 my-2">
              <button type="submit" className="btn btn-danger btn-block btn-lg waves-effect waves-themed" disabled={user.fetching}>
                {user.fetching && <span className="spinner-border spinner-border-sm" role="status" />}
                <span> Sign in</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginRightPanel;
