import React from 'react';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';

import { LoyaltyItem } from 'types';

export type ICardItem = {
  card: number;
  wash: IItemAvailed | boolean;
  dry: IItemAvailed | boolean;
  full: IItemAvailed | boolean;
};

export type IItemAvailed = {
  sale_id: string;
  sale_code: string;
  sale_date: string;
  type?: 'wash' | 'dry' | 'full';
};

export interface ILoyaltyCard {
  card: LoyaltyItem[][];
  cardNumber: number;
  handleClickCard: (sale_id?: string) => Promise<void>;
}

const LoyaltyCard: React.FC<ILoyaltyCard> = ({ card, cardNumber, handleClickCard }) => {
  const displayCardRow = (cardRow: LoyaltyItem[], row: number) => {
    const unused = 'btn-outline-primary';
    const used = 'btn-danger';

    const freeUsed = 'btn-info';
    const freeUnused = 'btn-outline-primary';

    const maxItemPerRow = 7;
    const maxRegularItem = 6;
    let cardItemCount = row * maxItemPerRow - maxRegularItem - 1;
    return (
      <div key={`${row}-${cardNumber}`} className="d-flex justify-content-around bd-highlight mb-3">
        {cardRow.map((item, index) => {
          let itemStyle = item ? used : unused;
          const itemNumber = cardItemCount++;
          if (index === 6) {
            itemStyle = !item ? freeUnused : freeUsed;
          }

          return (
            <button
              key={index}
              className={`btn ${itemStyle} btn-lg btn-icon rounded-circle waves-effect waves-themed`}
              data-tooltip-id={item?.sale_code ? 'tooltip-record' : undefined}
              data-tooltip-content={item?.sale_code || undefined}
              data-sale-date={item?.sale_date ? moment(item?.sale_date).format('DD MMM YYYY') : undefined}
              data-tooltip-place="top"
              onDoubleClick={item?.sale_id ? () => handleClickCard(item.sale_id) : undefined}
            >
              {index === 6 ? (
                item?.type === 'wash' || row === 1 ? (
                  <i className="fal fa-washer"></i>
                ) : item?.type === 'dry' || row === 2 ? (
                  <i className="fal fa-dryer"></i>
                ) : (
                  <i className="fal fa-trophy"></i>
                )
              ) : (
                itemNumber + 1
              )}
            </button>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className="panel-content flex-fill w-100">
        <div className="card m-auto border">
          <div className="card-header py-2 bg-primary">
            <div className="card-title white">Card #{cardNumber}</div>
          </div>
          <div className="card-body">{card && card.map((cardRow, index) => displayCardRow(cardRow, index + 1))}</div>
        </div>
      </div>
      <Tooltip
        className="reacttooltip"
        id="tooltip-record"
        render={({ content, activeAnchor }) => (
          <span>
            JO: #{content}
            <br />
            Date: {activeAnchor?.getAttribute('data-sale-date')}
          </span>
        )}
      />
    </>
  );
};

export default LoyaltyCard;
