import React from 'react';

import { IRoute, IRouteLinks } from 'constants/interface';

export const SetupLinks: IRouteLinks = {
    id: 'setup',
    title: 'Setup',
    icon: 'cog',
    sort: 5,
    routes: [
        {
            id: 'accounting_calendar',
            title: 'Accounting Calendar',
            path: '/setup/accounting_calendar',
            noDelete: true,
            sort: 1,
            companyRestricted: true,
        },
        {
            id: 'document_type',
            title: 'Document Type',
            noDelete: true,
            path: '/setup/document_type',
            sort: 2,
            companyRestricted: true,
        },
        {
            id: 'account_charts',
            title: 'Chart of Accounts',
            path: '/setup/account_charts',
            noDelete: true,
            sort: 3,
            companyRestricted: true,
        },
        {
            id: 'account_combination',
            title: 'Account Combination',
            path: '/setup/account_combination',
            noDelete: true,
            sort: 4,
            companyRestricted: true,
        },
        {
            id: 'account_company',
            title: '1st Segment - Company',
            path: '/setup/account_company',
            noDelete: true,
            sort: 5,
            companyRestricted: true,
        },
        {
            id: 'account_division',
            title: '2nd Segment - Division',
            path: '/setup/account_division',
            noDelete: true,
            sort: 6,
            companyRestricted: true,
        },
        {
            id: 'account',
            title: '3rd Segment - Account',
            path: '/setup/account',
            noDelete: true,
            sort: 7,
            companyRestricted: true,
        },
        {
            id: 'account_mapping',
            title: 'Account Mapping',
            path: '/setup/account_mapping',
            noDelete: true,
            sort: 8,
            companyRestricted: true,
        },
        {
            id: 'item_categories',
            title: 'Item Categories',
            path: '/setup/item_categories',
            sort: 9,
            companyRestricted: true,
        },
        {
            id: 'item_groups',
            title: 'Item Groups',
            path: '/setup/item_groups',
            sort: 10,
            companyRestricted: true,
        },
        {
            id: 'unit_of_measure',
            title: 'Unit of Measure',
            path: '/setup/unit_of_measure',
            sort: 11,
            companyRestricted: true,
        },
    ],
};

const SetupRouter: IRoute[] = [
    {
        path: '/setup/accounting_calendar',
        component: React.lazy(() => import('containers/Setup/AccountingCalendarContainer')),
    },
    {
        path: '/setup/document_type',
        component: React.lazy(() => import('containers/Setup/DocumentTypeContainer')),
    },
    {
        path: '/setup/account_charts',
        component: React.lazy(() => import('containers/Setup/AccountSummaryContainer')),
    },
    {
        path: '/setup/account_combination',
        component: React.lazy(() => import('containers/Setup/AccountCombinationContainer')),
    },
    {
        path: '/setup/account_company',
        component: React.lazy(() => import('containers/Setup/AccountCompanyContainer')),
    },
    {
        path: '/setup/account_division',
        component: React.lazy(() => import('containers/Setup/AccountDivisionContainer')),
    },
    {
        path: '/setup/account',
        component: React.lazy(() => import('containers/Setup/AccountContainer')),
    },
    {
        path: '/setup/unit_of_measure',
        component: React.lazy(() => import('containers/Setup/UnitOfMeasureContainer')),
    },
    {
        path: '/setup/item_groups',
        component: React.lazy(() => import('containers/Setup/ItemGroupContainer')),
    },
    {
        path: '/setup/item_categories',
        component: React.lazy(() => import('containers/Setup/ItemCategoryContainer')),
    },
    {
        path: '/setup/account_mapping',
        component: React.lazy(() => import('containers/Setup/AccountMappingContainer')),
    },
];

export default SetupRouter;
