import { IFetch } from 'constants/types';

export const COMPANY_SELECT = 'COMPANY_SELECT';
export const COMPANY_ACTIVE_COMPANY = 'COMPANY_ACTIVE_COMPANY';
export const COMPANY_CREATE = 'COMPANY_CREATE';
export const COMPANY_UPDATE = 'COMPANY_UPDATE';
export const COMPANY_FIND_BY_ID = 'COMPANY_FIND_BY_ID';
export const COMPANY_FIND_BY_CODE = 'COMPANY_FIND_BY_CODE';
export const COMPANY_FIND_FIRST = 'COMPANY_FIND_FIRST';
export const COMPANY_FIND_LAST = 'COMPANY_FIND_LAST';
export const COMPANY_FIND_ALL = 'COMPANY_FIND_ALL';
export const COMPANY_CLEAR_DATA = 'COMPANY_CLEAR_DATA';
export const COMPANY_LOGOUT = 'COMPANY_LOGOUT'

export interface CompanyState extends IFetch {
  data: ICompany | undefined;
  company: ICompany | null;
  company_list: ICompany[];
  list: ICompany[];
  total: number;
}

export type ICompany = {
  id: string;
  company_code: string;
  company_name: string;
  address: string;
  is_valid: number;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};

export type ICompanyCreate = {
  company_code: string;
  company_name: string;
  address: string;
  is_valid: number;
};

export interface ICompanyUpdate extends ICompanyCreate {
  id: string;
};

export type ICompanies = {
  companys: Array<ICompany>;
  total: number;
};
