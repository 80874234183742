import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  IAccountDivision,
  ICreateAccountDivision,
  IUpdateAccountDivision,
  ACCOUNT_DIVISION_CREATE,
  ACCOUNT_DIVISION_UPDATE,
  ACCOUNT_DIVISION_FIND_BY_ID,
  ACCOUNT_DIVISION_FIND_BY_CODE,
  ACCOUNT_DIVISION_FIND_FIRST,
  ACCOUNT_DIVISION_FIND_LAST,
  ACCOUNT_DIVISION_FIND_ALL,
  ACCOUNT_DIVISION_CLEAR_DATA,
} from 'types/account.types';

const accountDivisionCreate = createAsyncThunk<IAccountDivision, ICreateAccountDivision>(ACCOUNT_DIVISION_CREATE, async (data) =>
  request.post(`/account_division`, data)
);
const accountDivisionUpdate = createAsyncThunk<IAccountDivision, IUpdateAccountDivision>(ACCOUNT_DIVISION_UPDATE, async (data) =>
  request.put(`/account_division/id/${data.id}`, data)
);
const accountDivisionFindByID = createAsyncThunk<IAccountDivision, string | number>(ACCOUNT_DIVISION_FIND_BY_ID, async (id) =>
  request.get(`/account_division/id/${id}`)
);
const accountDivisionFindByCode = createAsyncThunk<IAccountDivision, string>(ACCOUNT_DIVISION_FIND_BY_CODE, async (code) =>
  request.get(`/account_division/${code}`)
);
const accountDivisionFindFirst = createAsyncThunk<IAccountDivision>(ACCOUNT_DIVISION_FIND_FIRST, async () =>
  request.get('/account_division/find/first')
);
const accountDivisionFindLast = createAsyncThunk<IAccountDivision>(ACCOUNT_DIVISION_FIND_LAST, async () =>
  request.get('/account_division/find/last')
);
const accountDivisionFindAll = createAsyncThunk<IAccountDivision[], Partial<ITable>>(ACCOUNT_DIVISION_FIND_ALL, async (query) =>
  request.get('/account_division', query)
);
const accountDivisionClearData = createAction(ACCOUNT_DIVISION_CLEAR_DATA);

const AccountDivisionActions = {
  accountDivisionCreate,
  accountDivisionUpdate,
  accountDivisionFindByID,
  accountDivisionFindByCode,
  accountDivisionFindFirst,
  accountDivisionFindLast,
  accountDivisionFindAll,
  accountDivisionClearData,
};

export default AccountDivisionActions;
