import { IFetch } from 'constants/types';

export const BANK_CREATE = 'BANK_CREATE';
export const BANK_UPDATE = 'BANK_UPDATE';
export const BANK_FIND_BY_ID = 'BANK_FIND_BY_ID';
export const BANK_FIND_BY_CODE = 'BANK_FIND_BY_CODE';
export const BANK_FIND_FIRST = 'BANK_FIND_FIRST';
export const BANK_FIND_LAST = 'BANK_FIND_LAST';
export const BANK_FIND_ALL = 'BANK_FIND_ALL';
export const BANK_CLEAR_DATA = 'BANK_CLEAR_DATA';

export interface BankState extends IFetch {
  data: IBank | undefined;
  list: IBank[];
  total: number;
}

export type IBank = {
  id: number;
  bank_code: string;
  bank_name: string;
  account_number: string;
  payment_method: PaymentMethod;
  is_valid: number;
  account_id: number;
  combination_code?: string;
  combination_name: string;
  company_id: number;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};

export type IBankCreate = {
  bank_code: string;
  bank_name: string;
  account_number: string;
  payment_method: PaymentMethod;
  is_valid: number;
  account_id: number;
};

export interface IBankUpdate extends IBankCreate {
  id: number;
};

export type IBanks = {
  banks: Array<IBank>;
  total: number;
};

export type PaymentMethod = 'cash' | 'gcash' | 'maya' | '';