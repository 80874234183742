import { IFetch } from 'constants/types';

export const LMS_TOKEN = 'LMS_TOKEN';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SESSION = 'USER_SESSION';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_FIND_DATA = 'USER_FIND_DATA';
export const USER_FIND_FIRST = 'USER_FIND_FIRST';
export const USER_FIND_LAST = 'USER_FIND_LAST';
export const USER_FIND_ALL = 'USER_FIND_ALL';
export const USER_DATA_INFO = 'USER_DATA_INFO';
export const USER_CLEAR_DATA = 'USER_CLEAR_DATA';
export const USER_CLEAR_TOKEN = 'USER_CLEAR_TOKEN';
export const USER_MENU_POSITION = 'USER_MENU_POSITION';
export const USER_SET_COMPANY = 'USER_SET_COMPANY';
export const USER_REFRESH_TOKEN = 'USER_REFRESH_TOKEN';

export interface JwtIuser extends Omit<IUser, 'id' | 'logins' | 'last_login'> {
  id?: string;
  user_id: string;
  logins?: number;
  last_login?: string;
}

export type InfoUser = {
  created_by: string;
  create_name: string;
  last_saved_by: string;
  last_saved_name: string;
};

export interface UserState extends IFetch {
  data: IUser | undefined;
  auth: JwtIuser | null;
  list: IUser[];
  total: number;
  token: IUserLoginResponse | string;
  company_id?: string;
  infoData?: InfoUser
  lms_token?: string;
}

export type IUser = {
  id: string;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  password?: string;
  role_id: number;
  logins: number;
  last_login: string;
  menu: string;
  created_by?: number;
  created_date?: string;
  last_saved_by?: number;
  last_saved_date?: string;
}

export type IUserLogin = {
  username: string;
  password: string;
};

export type IUserLoginResponse = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: 'password' | 'client_credentials' | 'refresh_token';
};

export type IUserCreate = {
  username: string;
  email?: string;
  first_name: string;
  last_name: string;
  password: string;
  role_id: number;
};

export type IUserUpdate = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  password?: string;
  role_id: number;
};
