import React from 'react';

import useRoleAccess from 'hooks/useRoleAccess';

type MenuList = {
  path: string;
  name: string;
  icon: string;
  restricted?: boolean;
};

export interface IConfiguration extends Pick<IMenuIcon, 'handleMenuItem'> {
  company?: string;
}

interface IMenuIcon extends MenuList {
  company?: string;
  handleMenuItem: (path: string) => void;
}

const mainMenuList: MenuList[] = [
  {
    path: '/admin/companies',
    name: 'Companies',
    icon: 'fad fa-building',
  },
  {
    path: '/setup/document_type',
    name: 'Document Type',
    icon: 'fad fa-file-alt',
    restricted: true,
  },
  {
    path: '/setup/account_charts',
    name: 'Chart of Accounts',
    icon: 'fad fa-chart-line',
    restricted: true,
  },
  {
    path: '/setup/accounting_calendar',
    name: 'Accounting Calendar',
    restricted: true,
    icon: 'fad fa-calendar-alt',
  },
  {
    path: '/setup/unit_of_measure',
    name: 'Unit of Measure',
    restricted: true,
    icon: 'fad fa-prescription-bottle',
  },
  {
    path: '/setup/item_categories',
    name: 'Item Categories',
    restricted: true,
    icon: 'fad fa-box',
  },
  {
    path: '/setup/item_groups',
    name: 'Item Groups',
    restricted: true,
    icon: 'fad fa-boxes',
  },
];

const MenuIcon: React.FC<IMenuIcon> = ({ path, name, company, restricted, icon, handleMenuItem }) => {
  return (
    <div className="d-flex flex-column justify-content-center ">
      <div
        className={`d-flex flex-column justify-content-center btn waves-effect waves-themed ${
          !company && restricted ? 'disabled' : 'cursor-pointer'
        }`}
        onClick={!company && restricted ? undefined : () => handleMenuItem(path)}
      >
        <span className="fa-4x d-inline l-h-n">
          <i className={`${icon}${!company && restricted ? ' disabled' : ''}`} />
        </span>
        <span>{name}</span>
      </div>
    </div>
  );
};

const Configuration: React.FC<IConfiguration> = ({ company, handleMenuItem }) => {
  const { filterMenu } = useRoleAccess();
  return filterMenu(mainMenuList).length > 0 ? (
    <div className="panel">
      <div className="panel-hdr bg-info-500 bg-info-gradient">
        <h2 className="white">Configuration</h2>
      </div>
      <div className="panel-container show">
        <div className="panel-content row justify-content-around bd-highlight">
          {filterMenu(mainMenuList).map((menu: MenuList) => (
            <MenuIcon key={menu.path} {...menu} company={company} handleMenuItem={handleMenuItem} />
          ))}
        </div>
      </div>
    </div>
  ) : <></>;
};

export default Configuration;
