import React from 'react';
import { uniqueId } from 'lodash';
import { OverlayTrigger } from 'react-bootstrap';
import ReactTooltip from 'react-bootstrap/Tooltip';

import { Placement } from 'constants/types';

interface ITooltip {
  title: string;
  placement?: Placement;
  children: JSX.Element;
}

const Tooltip: React.FC<ITooltip> = ({ children, placement, title }) => {
  const overlay = <ReactTooltip id={uniqueId('tooltip-')}>{title}</ReactTooltip>;

  return (
    <OverlayTrigger placement={placement || 'bottom'} overlay={overlay}>
      {children}
    </OverlayTrigger>
  );
};

export default Tooltip;
