import React, { useState, useEffect } from 'react';

import { format } from 'utilities/parser';
import { ILagoonItems, TableColumn, TableColumnClass } from 'types';
import { IColumn } from 'components/Common/DataTable';

const columns: IColumn<ILagoonItems>[] = [
  {
    id: 'name',
    title: 'Name',
    display: (row) => row.name,
  },
  {
    id: 'price',
    title: 'Unit Price',
    type: TableColumn.Currency,
    display: (row) => (row.unit_price > 0 ? format(row.unit_price) : 'FREE'),
  },
  {
    id: 'quantity',
    title: 'Quantity',
    type: TableColumn.Number,
    display: (row) => row.quantity,
  },
  {
    id: 'total',
    title: 'Total',
    type: TableColumn.Currency,
    display: (row) => (row?.total_price && row?.total_price > 0 ? format(row.total_price) : 'FREE'),
  },
];

interface IProps {
  itemType: string;
  items?: any[];
  theme: 'primary' | 'danger' | 'info';
}

const Lagoon: React.FC<IProps> = ({ itemType, items, theme }) => {
  const [totals, setTotals] = useState({ quantity: 0, amount: 0 });

  useEffect(() => {
    if (items) {
      setTotals(
        items.reduce(
          (total, item) => ({
            quantity: total.quantity + 1,
            amount: total.amount + item.total_price,
          }),
          { quantity: 0, amount: 0 }
        )
      );
    }
  }, [items]);

  return (
    <table className="table mt-3 table-hover table-striped table-sm table-responsive-sm">
      <thead className={`bg-${theme} text-white`}>
        <tr className="text-center">
          <th colSpan={columns.length}>{itemType.toUpperCase()}</th>
        </tr>
        <tr>
          {columns.map((row: IColumn<any>, key: number) => (
            <th key={key}>{row.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items &&
          items.map((d, rowKey) => (
            <tr key={rowKey} className={`tr-${theme}`}>
              {columns.map((row: IColumn<any>, colKey) => {
                let className: Array<string> = [];
                row.type && className.push(TableColumnClass[row.type]);
                colKey === 0 && className.push('fw-500');
                row.nowrap && className.push('no-wrap');

                return (
                  <td key={colKey} className={className.join(' ')}>
                    {row.display(d, rowKey + 1)} {''}
                  </td>
                );
              })}
            </tr>
          ))}
      </tbody>
      <tfoot>
        <tr className={`white bg-${theme}`}>
          <th colSpan={2} className="h5">
            Total
          </th>
          <th className="text-right h5">{totals.quantity}</th>
          <th className="text-right h5">{format(totals.amount)}</th>
        </tr>
      </tfoot>
    </table>
  );
};

export default Lagoon;
