import React, { useEffect } from 'react';
import { navigate } from 'raviger';
import { useSelector, useDispatch } from 'react-redux';

import { RootState, AppDispatch } from 'store';
import Home from 'pages/Home';
import { AccessActions, RoleActions, UserActions } from 'store/actions';

const HomeContainer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { auth, company_id, companyList, user_id } = useSelector((state: RootState) => ({
    auth: state.user.auth,
    user_id: state.user.auth?.user_id,
    company_id: state.user.company_id,
    companyList: state.company.company_list,
  }));

  useEffect(() => {
    user_id && dispatch(UserActions.userMenuPosition({ id: user_id, position: 'top' }));
  }, [user_id, dispatch]);

  useEffect(() => {
    if (auth?.user_id) {
      dispatch(AccessActions.accessUserSession(auth.role_id));
      dispatch(RoleActions.roleUserSession(auth.role_id));
    }
  }, [auth, dispatch]);

  const handleMenuItem = (path: string) => navigate(path);
  const handleSelectCompany = (companyId: string) => dispatch(UserActions.setCompany(companyId));
  const handleEnterCompany = () => company_id && navigate('/dashboard');

  return (
    <Home
      auth={auth}
      company={company_id}
      companyList={companyList}
      handleSelectCompany={handleSelectCompany}
      handleEnterCompany={handleEnterCompany}
      handleMenuItem={handleMenuItem}
    />
  );
};

export default HomeContainer;
