import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';

import { ITable } from 'constants/interface';
import {
  IRole,
  IRoles,
  IRoleCreate,
  IRoleUpdate,
  ROLE_CREATE,
  ROLE_UPDATE,
  ROLE_FIND_BY_ID,
  ROLE_FIND_BY_CODE,
  ROLE_FIND_FIRST,
  ROLE_FIND_LAST,
  ROLE_FIND_ALL,
  ROLE_CLEAR_DATA,
  ROLE_USER_SESSION,
} from 'types/role.types';

const roleUserSession = createAsyncThunk<IRole, number>(ROLE_USER_SESSION, async (id) => request.get(`/roles/id/${id}`));
const roleCreate = createAsyncThunk<IRole, IRoleCreate>(ROLE_CREATE, async (data) => request.post('/roles', { ...data }));
const roleUpdate = createAsyncThunk<IRole, IRoleUpdate>(ROLE_UPDATE, async (data) => request.put(`/roles/id/${data.id}`, { ...data }));
const roleFindByID = createAsyncThunk<IRole, number>(ROLE_FIND_BY_ID, async (id) => request.get(`/roles/id/${id}`));
const roleFindByCode = createAsyncThunk<IRole, string>(ROLE_FIND_BY_CODE, async (code) => request.get(`/roles/${code}`));
const roleFindFirst = createAsyncThunk<IRole>(ROLE_FIND_FIRST, async () => request.get('/roles/find/first'));
const roleFindLast = createAsyncThunk<IRole>(ROLE_FIND_LAST, async () => request.get('/roles/find/last'));
const roleFindAll = createAsyncThunk<IRoles, ITable>(ROLE_FIND_ALL, async (query) => request.get('/roles', query));
const roleClearData = createAction(ROLE_CLEAR_DATA);

const RoleActions = {
  roleCreate,
  roleUpdate,
  roleFindByID,
  roleFindByCode,
  roleFindFirst,
  roleFindLast,
  roleFindAll,
  roleClearData,
  roleUserSession,
};

export default RoleActions;
