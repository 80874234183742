import { IFetch } from 'constants/types';

export const ACCOUNT_DEFAULT_SAVE = 'ACCOUNT_DEFAULT_SAVE';
export const ACCOUNT_DEFAULT_GET = 'ACCOUNT_DEFAULT_GET';

export type IAccountDefault = {
  id: number;
  company_id: string;
  income_account: number;
  income_account_code: string;
  income_account_description: string;
  created_by: string;
  created_date: string;
  last_saved_by: string;
  last_saved_date: string;
};

export interface AccountDefaultState extends IFetch {
  defaults?: IAccountDefault;
}


export type CreateDefaultAccount = {
  income_account: number;
}