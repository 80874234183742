import React from 'react';

import { IRoute, IRouteLinks } from 'constants/interface';

export const AdminLinks: IRouteLinks = {
    id: 'admin',
    title: 'Admin',
    icon: 'cogs',
    sort: 7,
    routes: [
        {
            id: 'companies',
            title: 'Companies',
            path: '/admin/companies',
            noDelete: true,
            sort: 1,
        },
        {
            id: 'users',
            title: 'Users',
            path: '/admin/users',
            noDelete: true,
            sort: 2,
        },
        {
            id: 'roles',
            title: 'Roles',
            path: '/admin/roles',
            noDelete: true,
            sort: 3,
        },
        {
            id: 'backup',
            title: 'Backup',
            path: '/admin/backup',
            noDelete: true,
            sort: 3,
        },
        {
            id: 'upload',
            title: 'Upload',
            path: '/admin/upload',
            noDelete: true,
            sort: 3,
        },
    ],
};

const AdminRouter: IRoute[] = [
    {
        path: '/admin/companies',
        component: React.lazy(() => import('containers/Admin/CompanyContainer')),
    },
    {
        path: '/admin/users',
        component: React.lazy(() => import('containers/Admin/UserContainer')),
    },
    {
        path: '/admin/roles',
        component: React.lazy(() => import('containers/Admin/RoleContainer')),
    },
    // {
    //     path: '/admin/backup',
    //     component: React.lazy(() => import('containers/Admin/BackupContainer')),
    // },
    {
        path: '/profile',
        component: React.lazy(() => import('containers/Admin/ProfileContainer')),
    },
    {
        path: '/admin/upload',
        component: React.lazy(() => import('containers/Admin/UploadContainer')),
    },
];

export default AdminRouter;
