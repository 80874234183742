import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { BankState } from 'types/bank.types';
import BankAction from 'store/actions/bank.action';

export const BankInitialState: BankState = {
  data: undefined,
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type BankType = typeof BankInitialState;

const handleBank = (state: BankState, action: any) => {
  const { bank = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: bank,
    next,
    previous,
  };
};

const bankReducer = createSlice({
  name: 'Bank',
  initialState: BankInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(BankAction.bankFindAll.fulfilled, (state, action) => {
        const { banks: list = [], total = 0 } = get(action, 'payload.data', { banks: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(BankAction.bankCreate.fulfilled, handleBank)
      .addCase(BankAction.bankUpdate.fulfilled, handleBank)
      .addCase(BankAction.bankFindByID.fulfilled, handleBank)
      .addCase(BankAction.bankFindByCode.fulfilled, handleBank)
      .addCase(BankAction.bankFindFirst.fulfilled, handleBank)
      .addCase(BankAction.bankFindLast.fulfilled, handleBank)
      .addCase(BankAction.bankClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default bankReducer.reducer;
