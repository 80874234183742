import { createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';

import {
  IDocumentType,
  IDocumentTypeCreate,
  IDocumentTypeUpdate,
  DOCUMENT_TYPE_CREATE,
  DOCUMENT_TYPE_UPDATE,
  DOCUMENT_TYPE_GET_ALL,
  DOCUMENT_TYPE_SEQUENCE,
} from 'types/document.types';

const documentTypeCreate = createAsyncThunk<IDocumentType[], IDocumentTypeCreate>(DOCUMENT_TYPE_CREATE, async (data) =>
  request.post('/documents', { ...data })
);
const documentTypeUpdate = createAsyncThunk<IDocumentType[], IDocumentTypeUpdate>(DOCUMENT_TYPE_UPDATE, async (data) =>
  request.put(`/documents/${data.company_id}`, { ...data })
);
const documentTypeFindAll = createAsyncThunk<IDocumentType[], string>(DOCUMENT_TYPE_GET_ALL, async (company_id) =>
  request.get(`/documents/${company_id}`)
);
const documentSequence = createAsyncThunk<IDocumentType, string>(DOCUMENT_TYPE_SEQUENCE, async (document_id) =>
  request.get(`/documents/sequence/${document_id}`)
);

const DocumentTypeActions = {
  documentTypeCreate,
  documentTypeUpdate,
  documentTypeFindAll,
  documentSequence,
};

export default DocumentTypeActions;
