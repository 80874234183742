import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  IBank,
  IBanks,
  IBankCreate,
  IBankUpdate,
  BANK_CREATE,
  BANK_UPDATE,
  BANK_FIND_BY_ID,
  BANK_FIND_BY_CODE,
  BANK_FIND_FIRST,
  BANK_FIND_LAST,
  BANK_FIND_ALL,
  BANK_CLEAR_DATA,
} from 'types/bank.types';

const bankCreate = createAsyncThunk<IBank, IBankCreate>(BANK_CREATE, async (data) => request.post('/banks', { ...data }));
const bankUpdate = createAsyncThunk<IBank, IBankUpdate>(BANK_UPDATE, async (data) =>
  request.put(`/banks/id/${data.id}`, { ...data })
);
const bankFindByID = createAsyncThunk<IBank, number, any>(BANK_FIND_BY_ID, async (id, query) =>
  request.get(`/banks/id/${id}`, query)
);
const bankFindByCode = createAsyncThunk<IBank, string>(BANK_FIND_BY_CODE, async (code) => request.get(`/banks/${code}`));
const bankFindFirst = createAsyncThunk<IBank>(BANK_FIND_FIRST, async () => request.get('/banks/find/first'));
const bankFindLast = createAsyncThunk<IBank>(BANK_FIND_LAST, async () => request.get('/banks/find/last'));
const bankFindAll = createAsyncThunk<IBanks, Partial<ITable>>(BANK_FIND_ALL, async (query) => request.get('/banks', query));
const bankClearData = createAction(BANK_CLEAR_DATA);

const BankActions = {
  bankCreate,
  bankUpdate,
  bankFindByID,
  bankFindByCode,
  bankFindFirst,
  bankFindLast,
  bankFindAll,
  bankClearData,
};

export default BankActions;
