import React from 'react';

import useRoleAccess from 'hooks/useRoleAccess';

type MenuList = {
  path: string;
  name: string;
  restricted?: boolean;
  icon: string;
};

export interface IMainMenu extends Pick<IMenuIcon, 'handleMenuItem'> {}

interface IMenuIcon extends MenuList {
  handleMenuItem: (path: string) => void;
}

const mainMenuList: MenuList[] = [
  {
    name: 'Customer Loyalty',
    path: '/transaction/loyalty',
    icon: 'fad fa-stars',
  }, {
    name: 'Cash/Check Voucher',
    path: '/transaction/vouchers',
    icon: 'fad fa-money-check-alt',
  },
  {
    name: 'Sales',
    path: '/transaction/sales',
    icon: 'fad fa-money-bill',
  },
  {
    name: 'Deposit Slips',
    path: '/transaction/deposits',
    icon: 'fad fa-piggy-bank',
  },
  {
    name: 'Subledger Entries',
    path: '/transaction/subledgers',
    icon: 'fad fa-books',
  },
  {
    name: 'Stocks Purchases',
    path: '/transaction/purchases',
    icon: 'fad fa-shopping-cart',
  },
];

const MenuIcon: React.FC<IMenuIcon> = ({ path, name, restricted, icon, handleMenuItem }) => {
  return (
    <div className="d-flex flex-column justify-content-center ">
      <div
        className={'d-flex flex-column justify-content-center btn waves-effect waves-themed cursor-pointer'}
        onClick={restricted ? undefined : () => handleMenuItem(path)}
      >
        <span className="fa-4x d-inline l-h-n">
          <i className={`${icon}${restricted ? ' disabled' : ''}`} />
        </span>
        <span>{name}</span>
      </div>
    </div>
  );
};

const MainMenu: React.FC<IMainMenu> = ({ handleMenuItem }) => {
  const { filterMenu } = useRoleAccess();
  return filterMenu(mainMenuList).length > 0 ? (
    <div className="panel">
      <div className="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2 className="white">Transactions</h2>
      </div>
      <div className="panel-container show">
        <div className="panel-content row justify-content-around bd-highlight">
          {filterMenu(mainMenuList).map((menu: MenuList) => (
            <MenuIcon key={menu.path} {...menu} handleMenuItem={handleMenuItem} />
          ))}
        </div>
      </div>
    </div>
  ) : <></>;
};

export default MainMenu;
