import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { ItemGroupState } from 'types/item.group.types';
import ItemGroupAction from 'store/actions/item.group.action';

export const ItemGroupInitialState: ItemGroupState = {
  data: undefined,
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type ItemGroupType = typeof ItemGroupInitialState;

const handleItemGroup = (state: ItemGroupState, action: any) => {
  const { item_group = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: item_group,
    next,
    previous,
  };
};

const itemGroupReducer = createSlice({
  name: 'ItemGroup',
  initialState: ItemGroupInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ItemGroupAction.itemGroupFindAll.fulfilled, (state, action) => {
        const { item_groups: list = [], total = 0 } = get(action, 'payload.data', { item_groups: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(ItemGroupAction.itemGroupCreate.fulfilled, handleItemGroup)
      .addCase(ItemGroupAction.itemGroupUpdate.fulfilled, handleItemGroup)
      .addCase(ItemGroupAction.itemGroupFindByID.fulfilled, handleItemGroup)
      .addCase(ItemGroupAction.itemGroupFindByCode.fulfilled, handleItemGroup)
      .addCase(ItemGroupAction.itemGroupFindFirst.fulfilled, handleItemGroup)
      .addCase(ItemGroupAction.itemGroupFindLast.fulfilled, handleItemGroup)
      .addCase(ItemGroupAction.itemGroupClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default itemGroupReducer.reducer;
