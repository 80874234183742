import { get, map, reverse, uniqBy, findIndex } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

import { ISale, SaleState } from 'types/sale.types';
import SaleAction from 'store/actions/sale.action';

export const SaleInitialState: SaleState = {
  sale: null,
  data: undefined,
  unpaid_list: [],
  list: [],
  daily: [],
  total: 0,
  total_amount: 0,
  total_unpaid: 0,
  total_amount_unpaid: 0,
  fetching: false,
  fetched: false,
  error: null,
  loyalty: [],
};

export type SaleType = typeof SaleInitialState;

const handleSale = (state: SaleState, action: any) => {
  const { sale = null, next, previous } = get(action, 'payload.data');
  const newList = [...state.list];
  const unpaidList = [...state.unpaid_list];

  if (sale) {
    const index = findIndex(newList, { id: sale.id });
    if (index >= 0) {
      newList[index] = sale;
    }

    const index2 = findIndex(unpaidList, { id: sale.id });
    if (index2 >= 0) {
      unpaidList[index2] = sale;
    }
  }

  return {
    ...state,
    data: sale,
    list: newList,
    unpaid_list: unpaidList,
    next,
    previous,
  };
};

const handlePending = (state: SaleState) => ({
  ...state,
  fetching: true,
  fetched: false
});

const saleReducer = createSlice({
  name: 'Sale',
  initialState: SaleInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SaleAction.saleLoyalty.fulfilled, (state, action) => ({
        ...state,
        loyalty: get(action, 'payload.data', [])
      }))
      .addCase(SaleAction.saleSelect.fulfilled, (state, action) => {
        const sale = get(action, 'payload.data', null);
        return {
          ...state,
          sale,
        };
      })
      .addCase(SaleAction.lmsUpdateSale.pending, handlePending)
      .addCase(SaleAction.lmsUpdateSales.pending, handlePending)
      .addCase(SaleAction.lmsUpdateUnpaid.pending, handlePending)
      .addCase(SaleAction.lmsUpdateSale.fulfilled, (state, action) => {
        const sale = get(action, 'payload.data', {}) as unknown as ISale;
        return {
          ...state,
          list: map(state.list, (ldata) => ldata.id === sale.id ? sale : ldata),
          fetching: false,
          fetched: true
        };
      })

      .addCase(SaleAction.saleFindAll.pending, handlePending)
      .addCase(SaleAction.saleFindAll.fulfilled, (state, action) => {
        const { sales: list = [], total = 0, total_amount = 0 } = get(action, 'payload.data', { sales: [], total: 0, total_amount: 0 });


        return {
          ...state,
          list: reverse(uniqBy(reverse([...state.list.concat(list)]), "id")),
          total,
          total_amount,
          fetching: false,
          fetched: true
        };
      })
      .addCase(SaleAction.saleFindUnpaid.pending, handlePending)
      .addCase(SaleAction.saleFindUnpaid.fulfilled, (state, action) => {
        const { sales: unpaid_list = [], total: total_unpaid = 0, total_amount: total_amount_unpaid = 0 } = get(action, 'payload.data', { sales: [], total: 0, total_amount: 0 });
        return {
          ...state,
          unpaid_list: state.unpaid_list.concat(unpaid_list),
          total_unpaid,
          total_amount_unpaid,
          fetching: false,
          fetched: true
        };
      })
      .addCase(SaleAction.saleFindDaily.pending, handlePending)
      .addCase(SaleAction.saleFindDaily.fulfilled, (state, action) => {
        const { sales: daily = [] } = get(action, 'payload.data', { sales: [] });
        return {
          ...state,
          daily,
          fetching: false,
          fetched: true
        };
      })
      .addCase(SaleAction.saleSetStatus.fulfilled, handleSale)
      .addCase(SaleAction.saleLock.fulfilled, handleSale)
      .addCase(SaleAction.saleUpdate.fulfilled, handleSale)
      .addCase(SaleAction.saleFindByCode.fulfilled, handleSale)
      .addCase(SaleAction.saleFindFirst.fulfilled, handleSale)
      .addCase(SaleAction.saleFindLast.fulfilled, handleSale)
      .addCase(SaleAction.saleFindByID.fulfilled, (state, action) => {
        const data = get(action, 'payload.data');
        return {
          ...state,
          data
        }
      })
      .addCase(SaleAction.saleClearSales, (state) => ({
        ...state,
        list: [],
        daily: [],
        total: 0,
        loyalty: []
      }))
      .addCase(SaleAction.saleClearUnpaid, (state) => ({
        ...state,
        unpaid_list: [],
        total_unpaid: 0,
        total_amount_unpaid: 0
      }))
      .addCase(SaleAction.saleClearData, (state) => ({
        ...state,
        unpaid_list: [],
        list: [],
        data: undefined,
        next: undefined,
        previous: undefined,
        total: 0,
        total_unpaid: 0,
        total_amount_unpaid: 0,
        loyalty: []
      }))
  },
});

export default saleReducer.reducer;

