import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { UnitOfMeasureState } from 'types/uom.types';
import UnitOfMeasureAction from 'store/actions/uom.action';

export const UnitOfMeasureInitialState: UnitOfMeasureState = {
  data: undefined,
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type UnitOfMeasureType = typeof UnitOfMeasureInitialState;

const handleUnitOfMeasure = (state: UnitOfMeasureState, action: any) => {
  const { uom = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: uom,
    next,
    previous,
  };
};

const uomReducer = createSlice({
  name: 'UnitOfMeasure',
  initialState: UnitOfMeasureInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UnitOfMeasureAction.uomFindAll.fulfilled, (state, action) => {
        const { uoms: list = [], total = 0 } = get(action, 'payload.data', { uoms: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(UnitOfMeasureAction.uomCreate.fulfilled, handleUnitOfMeasure)
      .addCase(UnitOfMeasureAction.uomUpdate.fulfilled, handleUnitOfMeasure)
      .addCase(UnitOfMeasureAction.uomFindByID.fulfilled, handleUnitOfMeasure)
      .addCase(UnitOfMeasureAction.uomFindByCode.fulfilled, handleUnitOfMeasure)
      .addCase(UnitOfMeasureAction.uomFindFirst.fulfilled, handleUnitOfMeasure)
      .addCase(UnitOfMeasureAction.uomFindLast.fulfilled, handleUnitOfMeasure)
      .addCase(UnitOfMeasureAction.uomClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default uomReducer.reducer;
