import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  IItemGroup,
  IItemGroups,
  IItemGroupCreate,
  IItemGroupUpdate,
  ITEM_GROUP_CREATE,
  ITEM_GROUP_UPDATE,
  ITEM_GROUP_FIND_BY_ID,
  ITEM_GROUP_FIND_BY_CODE,
  ITEM_GROUP_FIND_FIRST,
  ITEM_GROUP_FIND_LAST,
  ITEM_GROUP_FIND_ALL,
  ITEM_GROUP_CLEAR_DATA,
} from 'types/item.group.types';

const itemGroupCreate = createAsyncThunk<IItemGroup, IItemGroupCreate>(ITEM_GROUP_CREATE, async (data) => request.post('/item_groups', { ...data }));
const itemGroupUpdate = createAsyncThunk<IItemGroup, IItemGroupUpdate>(ITEM_GROUP_UPDATE, async (data) =>
  request.put(`/item_groups/id/${data.id}`, { ...data })
);
const itemGroupFindByID = createAsyncThunk<IItemGroup, number, any>(ITEM_GROUP_FIND_BY_ID, async (id, query) =>
  request.get(`/item_groups/id/${id}`, query)
);
const itemGroupFindByCode = createAsyncThunk<IItemGroup, string>(ITEM_GROUP_FIND_BY_CODE, async (code) => request.get(`/item_groups/${code}`));
const itemGroupFindFirst = createAsyncThunk<IItemGroup>(ITEM_GROUP_FIND_FIRST, async () => request.get('/item_groups/find/first'));
const itemGroupFindLast = createAsyncThunk<IItemGroup>(ITEM_GROUP_FIND_LAST, async () => request.get('/item_groups/find/last'));
const itemGroupFindAll = createAsyncThunk<IItemGroups, Partial<ITable>>(ITEM_GROUP_FIND_ALL, async (query) => request.get('/item_groups', query));
const itemGroupClearData = createAction(ITEM_GROUP_CLEAR_DATA);

const ItemGroupActions = {
  itemGroupCreate,
  itemGroupUpdate,
  itemGroupFindByID,
  itemGroupFindByCode,
  itemGroupFindFirst,
  itemGroupFindLast,
  itemGroupFindAll,
  itemGroupClearData,
};

export default ItemGroupActions;
