import { createAsyncThunk } from '@reduxjs/toolkit';
import request from 'utilities/makeRequest';

import {
  UPLOAD_BANK,
  UPLOAD_CUSTOMER,
  UPLOAD_SUPPLIER,
  UPLOAD_DIVISION,
  UPLOAD_ACCOUNT,
  UPLOAD_ACCOUNT_BALANCE,
  UPLOAD_ACCOUNT_MAPPING,
  UPLOAD_ACCOUNT_COMBINATION,
} from 'types/upload.types';

const uploadBank = createAsyncThunk<{ requestId: string }, Blob>(UPLOAD_BANK, async (file) =>
  request.upload('/upload/bank', file)
);
const uploadCustomer = createAsyncThunk<{ requestId: string }, Blob>(UPLOAD_CUSTOMER, async (file) =>
  request.upload('/upload/customer', file)
);
const uploadSupplier= createAsyncThunk<{ requestId: string }, Blob>(UPLOAD_SUPPLIER, async (file) =>
  request.upload('/upload/supplier', file)
);
const uploadDivision = createAsyncThunk<{ requestId: string }, Blob>(UPLOAD_DIVISION, async (file) =>
  request.upload('/upload/division', file)
);
const uploadAccount = createAsyncThunk<{ requestId: string }, Blob>(UPLOAD_ACCOUNT, async (file) =>
  request.upload('/upload/account', file)
);
const uploadAccountCombination = createAsyncThunk<{ requestId: string }, Blob>(UPLOAD_ACCOUNT_COMBINATION, async (file) =>
  request.upload('/upload/account_combination', file)
);
const uploadAccountMapping = createAsyncThunk<{ requestId: string }, Blob>(UPLOAD_ACCOUNT_MAPPING, async (file) =>
  request.upload('/upload/account_mapping', file)
);
const uploadAccountBalance = createAsyncThunk<{ requestId: string }, Blob>(UPLOAD_ACCOUNT_BALANCE, async (file) =>
  request.upload('/upload/account_balance', file)
);

const UploadActions = {
  uploadBank,
  uploadCustomer,
  uploadSupplier,
  uploadDivision,
  uploadAccount,
  uploadAccountCombination,
  uploadAccountBalance,
  uploadAccountMapping,
};

export default UploadActions;
