import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  IVoucher,
  IVoucherCreate,
  IVoucherUpdate,
  VOUCHER_CREATE,
  VOUCHER_UPDATE,
  VOUCHER_FIND_BY_ID,
  VOUCHER_FIND_BY_CODE,
  VOUCHER_FIND_FIRST,
  VOUCHER_FIND_LAST,
  VOUCHER_FIND_ALL,
  VOUCHER_CLEAR_DATA,
} from 'types/voucher.types';

const voucherCreate = createAsyncThunk<IVoucher, IVoucherCreate>(VOUCHER_CREATE, async (data) => request.post('/vouchers', { ...data }));
const voucherUpdate = createAsyncThunk<IVoucher, IVoucherUpdate>(VOUCHER_UPDATE, async (data) =>
  request.put(`/vouchers/id/${data.id}`, { ...data })
);
const voucherFindByID = createAsyncThunk<IVoucher, number, any>(VOUCHER_FIND_BY_ID, async (id, query) =>
  request.get(`/vouchers/id/${id}`, query)
);
const voucherFindByCode = createAsyncThunk<IVoucher, string>(VOUCHER_FIND_BY_CODE, async (code) => request.get(`/vouchers/${code}`));
const voucherFindFirst = createAsyncThunk<IVoucher>(VOUCHER_FIND_FIRST, async () => request.get('/vouchers/find/first'));
const voucherFindLast = createAsyncThunk<IVoucher>(VOUCHER_FIND_LAST, async () => request.get('/vouchers/find/last'));
const voucherFindAll = createAsyncThunk<IVoucher[], Partial<ITable>>(VOUCHER_FIND_ALL, async (query) => request.get('/vouchers', query));
const voucherClearData = createAction(VOUCHER_CLEAR_DATA);

const VoucherActions = {
  voucherCreate,
  voucherUpdate,
  voucherFindByID,
  voucherFindByCode,
  voucherFindFirst,
  voucherFindLast,
  voucherFindAll,
  voucherClearData,
};

export default VoucherActions;
