import { persistReducer, persistStore } from 'redux-persist';
import { configureStore, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { AccessState, RoleState, UserState } from 'types';
import AccessReducer from 'store/reducers/AccessReducer';
import AccountReducer from 'store/reducers/AccountReducer';
import AccountCompanyReducer from 'store/reducers/AccountCompanyReducer';
import AccountDefaultReducer from 'store/reducers/AccountDefaultReducer';
import AccountDivisionReducer from 'store/reducers/AccountDivisionReducer';
import AccountCombinationReducer from 'store/reducers/AccountCombinationReducer';
import AccountMappingReducer from 'store/reducers/AccountMappingReducer';
import BankReducer from 'store/reducers/BankReducer';
import CalendarReducer from 'store/reducers/CalendarReducer';
import CompanyReducer from 'store/reducers/CompanyReducer';
import CustomerReducer from 'store/reducers/CustomerReducer';
import DocumentTypeReducer from 'store/reducers/DocumentTypeReducer';
import ErrorReducer, { onError, apiFetched, apiFetching } from 'store/reducers/ErrorReducer';
import ItemReducer from 'store/reducers/ItemReducer';
import ItemCategoryReducer from 'store/reducers/ItemCategoryReducer';
import ItemGroupReducer from 'store/reducers/ItemGroupReducer';
import PayableReducer from 'store/reducers/PayableReducer';
import PurchaseReducer from 'store/reducers/PurchaseReducer';
import RoleReducer from 'store/reducers/RoleReducer';
import SaleReducer from 'store/reducers/SaleReducer';
import SubledgerReducer from 'store/reducers/SubledgerReducer';
import SupplierReducer from 'store/reducers/SupplierReducer';
import UnitOfMeasureReducer from './reducers/UnitOfMeasureReducer';
import UserReducer from 'store/reducers/UserReducer';
import VoucherReducer from 'store/reducers/VoucherReducer';
import WebSocketReducer from 'store/reducers/WebSocketReducer';

export const userPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'token', 'company_id'],
  stateReconciler: autoMergeLevel2,
};

export const accessPersistConfig = {
  key: 'access',
  storage,
  whitelist: ['access'],
  stateReconciler: autoMergeLevel2,
};

export const rolePersistConfig = {
  key: 'role',
  storage,
  whitelist: ['role'],
  stateReconciler: autoMergeLevel2,
};

const errorHandler: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (action.type.endsWith('/rejected')) {
    api.dispatch(onError(action));
  }
  if (action.type.endsWith('/fulfilled')) {
    api.dispatch(apiFetched());
  }
  if (action.type.endsWith('/pending')) {
    api.dispatch(apiFetching());
  }
  next(action);
};

export const store = configureStore({
  reducer: {
    access: persistReducer<AccessState, any>(accessPersistConfig, AccessReducer),
    account: AccountReducer,
    accountCompany: AccountCompanyReducer,
    accountDefault: AccountDefaultReducer,
    accountDivision: AccountDivisionReducer,
    accountMapping: AccountMappingReducer,
    bank: BankReducer,
    company: CompanyReducer,
    calendar: CalendarReducer,
    category: ItemCategoryReducer,
    combination: AccountCombinationReducer,
    customer: CustomerReducer,
    documentType: DocumentTypeReducer,
    error: ErrorReducer,
    item: ItemReducer,
    itemGroup: ItemGroupReducer,
    payable: PayableReducer,
    purchase: PurchaseReducer,
    role: persistReducer<RoleState, any>(rolePersistConfig, RoleReducer),
    sale: SaleReducer,
    subledger: SubledgerReducer,
    supplier: SupplierReducer,
    voucher: VoucherReducer,
    uom: UnitOfMeasureReducer,
    user: persistReducer<UserState, any>(userPersistConfig, UserReducer),
    websocket: WebSocketReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(errorHandler)
      .concat(logger),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;


// eslint-disable-next-line import/no-anonymous-default-export
export default { store, persistor };
