import React from 'react';

export interface ICompanyList extends Pick<ICompanyIcon, 'handleSelectCompany' | 'handleEnterCompany'> {
  company?: string;
  companyList: Array<ICompany>;
}

interface ICompany {
  id: string;
  company_code: string;
  company_name: string;
}

interface ICompanyIcon {
  isSelected?: boolean;
  detail: ICompany;
  handleSelectCompany: (companyId: string) => void;
  handleEnterCompany: () => void;
}

const CompanyIcon: React.FC<ICompanyIcon> = ({ detail, isSelected, handleSelectCompany, handleEnterCompany }) => {
  const iconClassName = 'd-flex flex-column justify-content-center btn waves-effect waves-themed';

  return (
    <div className="d-flex flex-column justify-content-center ">
      <div
        className={`${iconClassName} ${isSelected ? 'bg-danger' : 'btn-faded cursor-pointer'}`}
        onClick={() => handleSelectCompany(detail.id)}
      >
        <span className="fa-4x d-inline l-h-n">
          <i className={`fad fa-washer${isSelected ? ' white' : ''}`} />
        </span>
        <span className={isSelected ? 'white' : ''}>{detail.company_name}</span>
      </div>
      {isSelected && (
        <button type="button" className="btn btn-xs btn-outline-danger waves-effect waves-themed mt-2" onClick={handleEnterCompany}>
          Enter Company
        </button>
      )}
    </div>
  );
};

const CompanyList: React.FC<ICompanyList> = ({ companyList, company, handleSelectCompany, handleEnterCompany }) => {
  return (
    <div className="panel">
      <div className="panel-hdr bg-danger-400 bg-danger-gradient">
        <h2 className="white">Select Shop</h2>
      </div>
      <div className="panel-container show">
        <div className="panel-content row justify-content-around bd-highlight">
          {companyList &&
            companyList.length > 0 &&
            companyList.map((detail) => (
              <CompanyIcon
                key={detail.id}
                detail={detail}
                isSelected={company === detail.id}
                handleSelectCompany={handleSelectCompany}
                handleEnterCompany={handleEnterCompany}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default CompanyList;
