import { IFetch } from 'constants/types';

// export const CUSTOMER_CREATE = 'CUSTOMER_CREATE';
export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE';
export const CUSTOMER_BASIC = 'CUSTOMER_BASIC';
export const CUSTOMER_FIND_BY_ID = 'CUSTOMER_FIND_BY_ID';
export const CUSTOMER_FIND_BY_CODE = 'CUSTOMER_FIND_BY_CODE';
export const CUSTOMER_FIND_FIRST = 'CUSTOMER_FIND_FIRST';
export const CUSTOMER_FIND_LAST = 'CUSTOMER_FIND_LAST';
export const CUSTOMER_FIND_ALL = 'CUSTOMER_FIND_ALL';
export const CUSTOMER_CLEAR_DATA = 'CUSTOMER_CLEAR_DATA';

export interface CustomerState extends IFetch {
  data: ICustomer | undefined;
  list: ICustomer[];
  total: number;
}

export type ICustomer = {
  id: string;
  customer_id: string;
  customer_code: string;
  customer_name: string;
  contact_number?: string;
  email?: string;
  organization?: string;
  address?: string;
  remarks?: string;
  account_id?: number;
  combination_code?: string;
  combination_name?: string;
  company_id: string;
  first_visit?: string;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
  deleted_by?: number;
  deleted_date?: string;
  is_valid: number;
};

export interface ICustomerUpdate  {
  id: string;
  customer_code: string;
  customer_name: string;
  contact_number?: string;
  email?: string;
  organization?: string;
  address?: string;
  remarks?: string;
  account_id?: number;
  deleted_by?: number;
  deleted_date?: string;
  is_valid: number;
};

export type ICustomers = {
  customers: Array<ICustomer>;
  total: number;
};
