import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  IPurchase,
  IPurchaseCreate,
  IPurchaseUpdate,
  PURCHASE_CREATE,
  PURCHASE_UPDATE,
  PURCHASE_FIND_BY_ID,
  PURCHASE_FIND_BY_CODE,
  PURCHASE_FIND_FIRST,
  PURCHASE_FIND_LAST,
  PURCHASE_FIND_ALL,
  PURCHASE_CLEAR_DATA,
} from 'types/purchase.types';

const purchaseCreate = createAsyncThunk<IPurchase, IPurchaseCreate>(PURCHASE_CREATE, async (data) => request.post('/purchases', { ...data }));
const purchaseUpdate = createAsyncThunk<IPurchase, IPurchaseUpdate>(PURCHASE_UPDATE, async (data) =>
  request.put(`/purchases/id/${data.id}`, { ...data })
);
const purchaseFindByID = createAsyncThunk<IPurchase, number, any>(PURCHASE_FIND_BY_ID, async (id, query) =>
  request.get(`/purchases/id/${id}`, query)
);
const purchaseFindByCode = createAsyncThunk<IPurchase, string>(PURCHASE_FIND_BY_CODE, async (code) => request.get(`/purchases/${code}`));
const purchaseFindFirst = createAsyncThunk<IPurchase>(PURCHASE_FIND_FIRST, async () => request.get('/purchases/find/first'));
const purchaseFindLast = createAsyncThunk<IPurchase>(PURCHASE_FIND_LAST, async () => request.get('/purchases/find/last'));
const purchaseFindAll = createAsyncThunk<IPurchase[], Partial<ITable>>(PURCHASE_FIND_ALL, async (query) => request.get('/purchases', query));
const purchaseClearData = createAction(PURCHASE_CLEAR_DATA);

const PurchaseActions = {
  purchaseCreate,
  purchaseUpdate,
  purchaseFindByID,
  purchaseFindByCode,
  purchaseFindFirst,
  purchaseFindLast,
  purchaseFindAll,
  purchaseClearData,
};

export default PurchaseActions;
