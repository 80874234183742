import React from 'react';

import Header, { IHeader } from 'components/Layout/Header';
import Sidebar, { ISidebar } from 'components/Layout/Sidebar';
import Spinner from 'components/Common/Spinner';
import Footer from 'components/Layout/Footer';

interface IProps extends ISidebar, IHeader {
  isLoading: boolean;
  children: React.ReactNode;
}

const Main: React.FC<IProps> = ({
  children,
  company,
  companyList,
  isTopNavigation,
  navControl,
  isLoading,
  role,
  username,
  email,
  screenId,
  access,
  selectedMenu,
  selectedParent,
  expandedMenu,
  handleLogout,
  mobileViewOff,
  toggleNavigation,
  moveNavigation,
  handleProfile,
  handleChildItem,
  handleParentItem,
  handleCompanySelect,
}) => {
  return (
    <div className="page-wrapper">
      <div className="page-inner">
        {!['/', 'dashboard'].includes(screenId) && (
          <Sidebar
            access={access}
            selectedMenu={selectedMenu}
            selectedParent={selectedParent}
            expandedMenu={expandedMenu}
            handleChildItem={handleChildItem}
            handleParentItem={handleParentItem}
            isTopNavigation={isTopNavigation}
            role={role}
            username={username}
            handleLogout={handleLogout}
          />
        )}

        <div className="page-content-wrapper" onFocus={() => mobileViewOff()}>
          <Header
            company={company}
            companyList={companyList}
            screenId={screenId}
            email={email}
            username={username}
            navControl={navControl}
            mobileViewOff={mobileViewOff}
            toggleNavigation={toggleNavigation}
            moveNavigation={moveNavigation}
            handleLogout={handleLogout}
            handleProfile={handleProfile}
            handleCompanySelect={handleCompanySelect}
          />

          <main className="page-content" role="main">
            {children}
          </main>
          <Footer />
        </div>
      </div>
      {isLoading && (
        <div className="main-backdrop modal-backdrop">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default Main;
