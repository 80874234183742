import { IFetch } from 'constants/types';

export const SALE_LOCK = 'SALE_LOCK';
export const SALE_SET_STATUS = 'SALE_SET_STATUS';
export const SALE_SELECT = 'SALE_SELECT';
export const SALE_UPDATE = 'SALE_UPDATE';
export const SALE_FIND_BY_ID = 'SALE_FIND_BY_ID';
export const SALE_FIND_BY_CODE = 'SALE_FIND_BY_CODE';
export const SALE_FIND_FIRST = 'SALE_FIND_FIRST';
export const SALE_FIND_LAST = 'SALE_FIND_LAST';
export const SALE_FIND_ALL = 'SALE_FIND_ALL';
export const SALE_CLEAR_DATA = 'SALE_CLEAR_DATA';
export const SALE_CLEAR_SALES = 'SALE_CLEAR_SALES';
export const SALE_CLEAR_UNPAID = 'SALE_CLEAR_UNPAID';
export const SALE_FIND_UNPAID = 'SALE_FIND_UNPAID';
export const SALE_FIND_DAILY = 'SALE_FIND_DAILY';
export const SALE_LOYALTY = 'SALE_LOYALTY';

export const SALE_CREATE_PAYMENT = 'SALE_CREATE_PAYMENT';
export const SALE_UPDATE_PAYMENT = 'SALE_UPDATE_PAYMENT';

export const LMS_UPDATE_SALE = 'LMS_UPDATE_SALE';
export const LMS_UPDATE_SALES = 'LMS_UPDATE_SALES';
export const LMS_UPDATE_UNPAID = 'LMS_UPDATE_UNPAID';
export const LMS_UPDATE_COLLECTION = 'LMS_UPDATE_COLLECTION';

export interface SaleState extends IFetch {
  data: ISale | undefined;
  sale: ISale | null;
  list: ISale[];
  daily: ISaleDaily[];
  unpaid_list: ISale[];
  total: number;
  total_amount: number;
  total_unpaid: number;
  total_amount_unpaid: number;
  loyalty: ILoyalty;
}

export type SaleStatus = 'pending' | 'unpaid' | 'paid' | 'cancelled' | 'posted';

export enum SaleStatusOptions {
  PENDING = 'pending',
  UNPAID = 'unpaid',
  PAID = 'paid',
  CANCELLED = 'cancelled',
  POSTED = 'posted'
}

export type ISale = {
  id: string;
  sale_code: string;
  sale_date: string;
  sale_status: SaleStatus;
  payment_date?: string;
  total_amount: number;
  customer_id: string;
  customer_code?: string;
  customer_name?: string;
  cancellation_remarks?: string;
  account_id: number;
  combination_code?: string;
  combination_name?: string;
  company_id: string;
  staff_name: string;
  created_by: number;
  created_date: string;
  last_saved_by?: number;
  last_saved_date?: string;
  deleted_by?: number;
  deleted_date?: string;
  service_items?: Array<IServiceItems>;
  product_items?: Array<IProductItems>;
  lagoon_items?: Array<ILagoonItems>;
  accounting?: Array<ISaleEntries>;
  payment?: IPayment;
  partial_payment?: IPartialPayment;
  remarks?: Array<ISaleRemarks>;
  locked: number;
};

export type ISaleRemarks = {
  added_by: string;
  created_at: string;
  deleted_at?: string;
  id: string;
  remarks: string;
  shop_id: string;
  transaction_id: string;
  updated_at: string;
}

export type IServiceItems = {
  id: string;
  name: string;
  category: 'drying' | 'washing' | 'other';
  drying_service_id: string;
  earning_points: number;
  full_service_id?: string;
  other_service_id?: string;
  price: number;
  saved: number;
  shop_id: string;
  transaction_id: string;
  updated_at: string;
  washing_service_id?: string;
  created_at: string;
  deleted_at?: string;
  quantity?: number;
  total?: number
}

export type IProductItems = {
  id: string;
  name: string;
  product_id: string;
  price: number;
  saved: number;
  shop_id: string;
  transaction_id: string;
  updated_at: string;
  created_at: string;
  deleted_at?: string;
}

export type ILagoonItems = {
  lagoon_id: string;
  name: string;
  quantity: number;
  total_price: number;
  unit_price: number;
}

export interface ISaleUpdate extends Omit<ISale, 'locked' | 'company_id' | 'created_by' | 'created_date' | 'last_saved_by' | 'last_saved_date'> {
};

export type ISales<T> = {
  sales: Array<T>;
  total: number;
  total_amount: number;
};

export interface ISaleUnpaid<T> extends ISales<T> {
  total_amount: number;
}

export type ISaleDaily = {
  sale_date: string;
  deposits: number;
  total_amount: number;
  unpaid_amount: number;
  gcash_collection: number;
  cash_collection: number;
}

export type IPayment = {
  id: string;
  sale_id: string;
  or_number?: string;
  customer_id: string;
  payment_date: string;
  payment_type: string;
  total_amount: number;
  payment_amount: number;
  cashless_amount?: number;
  cashless_remarks?: string;
  cash_amount: number;
  change_amount: number;
  discount_amount: number;
  discount_remarks: string;
  company_id: string;
  created_by: string;
  created_date: string;
  last_saved_by: string;
  last_saved_date: string;
  deleted_by?: string;
  deleted_date?: string;
  paid_to?: string;
  balance?: number;
};

export type IPartialPayment = {
  cash: number;
  balance: number;
  total_amount: number;
  total_cash: number;
  total_paid: number;
  user_id: string;
  date: string;
}

export type ISaleEntries = {
  id: number;
  sale_id: string;
  item_id: string;
  item_code?: string;
  item_name?: string;
  group_code?: string;
  group_name?: string;
  category_code?: string;
  category_name?: string;
  account_id: number;
  combination_code: string;
  combination_name: string;
  debit: number;
  credit: number;
  created_by: number;
  created_date: string;
  last_saved_by?: number;
  last_saved_date?: string;
  deleted_by?: number;
  deleted_date?: string;
};

export type LoyaltyItem = {
  sale_id: string;
  sale_code: string;
  sale_date: string;
  type?: 'wash' | 'dry' | 'full'
}

export type ILoyalty = Array<Array<Array<LoyaltyItem>>>;;