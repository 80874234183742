import axios, { AxiosError } from 'axios';
import { get, isObject } from 'lodash';

import { store } from 'store';
import UserAction from 'store/actions/user.action';

const Pace = document.getElementById('Pace');
const domain = process.env.REACT_APP_BACKEND_DOMAIN || `${window.location.hostname}${window.location.port ? `:${window.location.port}/api` : '/api'}`;
export type IRequest = {
  method: 'get' | 'put' | 'post' | 'delete';
  url: string;
  data?: Record<string, any>;
  file?: Blob;
  isFile?: boolean;
};

const requestNewToken = async (refreshToken?: string): Promise<string> => {
  try {
    const response = await axios({
      baseURL: `${process.env.REACT_APP_BACKEND_COM}://${domain}`,
      method: 'POST',
      url: '/token',
      data: {
        grant_type: 'refresh_token',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        refresh_token: refreshToken,
      },
    });

    store.dispatch(UserAction.refreshToken(response));
    return response.data.access_token;
  } catch (error) {
    store.dispatch(UserAction.clearToken());
    return '';
  }
};

const requestNewLMSToken = async (): Promise<string | undefined> => {
  try {
    const accessToken = get(store.getState().user, 'token.access_token');
    const response = await axios({
      baseURL: `${process.env.REACT_APP_BACKEND_COM}://${domain}`,
      method: 'POST',
      url: '/lms-token',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
    store.dispatch(UserAction.setLmsToken(response.data.lms_token));
    return response.data.lms_token;
  } catch (error) {
    return undefined;
  }
}

const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const accessToken = get(store.getState().user, 'token.access_token');
    const lmsToken = get(store.getState().user, 'lms_token');
    const companyId = store.getState().user.company_id;
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    } as any;


    if (companyId) {
      config.headers['companyId'] = companyId;
    }
    if (lmsToken) {
      config.headers['X-lmstoken'] = lmsToken;
    }
    if (!lmsToken && accessToken) {
      config.headers['X-lmstoken'] = await requestNewLMSToken();
    }

    if (!accessToken) delete config.headers.Authorization;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = get(store.getState().user, 'token.refresh_token');
      const access_token = await requestNewToken(refreshToken);
      if (access_token === '') return Promise.resolve('');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return axiosApiInstance(originalRequest);
    }

    return Promise.reject(error);
  }
);

const getBase64 = async (file: Blob): Promise<string> => {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL: string;
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      console.log('Called', reader);
      baseURL = reader.result as string;
      console.log(baseURL);
      resolve(baseURL);
    };
    console.log(fileInfo);
  });
};

const makeRequest = async (params: IRequest, baseDomain = domain): Promise<any> => {
  try {
    // @ts-ignore
    Pace.restart();
    let headers = undefined;
    // if (params.file) {
    //   headers = { 'Content-Type': 'multipart/form-data' };
    //   const formData = new FormData();
    //   formData.append('files', params.file);
    //   params.data = formData;
    // }

    // const base64: string = await getBase64(params.file as Blob);
    // params.data.base64 = base64;

    const response = await axiosApiInstance({
      baseURL: `${process.env.REACT_APP_BACKEND_COM}://${baseDomain}`,
      method: params.method,
      url: params.url,
      data: params.data,
      headers,
      responseType: params.isFile ? 'blob' : undefined,
    });

    if (response.status === 200 && get(response.data, 'error')) {
      const error = new Error(response.data.error.message);
      error.name = response.data.error.name;
      throw error;
    }
    return response;
  } catch (error) {
    const err = error as AxiosError;
    const errRes = get(err, 'response.data.error.message');
    if (errRes) {
      throw new Error(errRes);
    }
    throw error;
  }
};

const request = {
  get: async (url: string, query?: Record<string, any>, isFile?: boolean) => {
    if (query) {
      url = `${url}?${Object.keys(query)
        .map((key) => {
          const value = isObject(query[key]) ? JSON.stringify(query[key]) : query[key];
          return encodeURIComponent(key) + '=' + encodeURIComponent(value);
        })
        .join('&')}`;
    }
    return await makeRequest({ method: 'get', url, isFile });
  },
  post: async (url: string, data: Record<string, any>) => makeRequest({ method: 'post', url, data }),
  put: async (url: string, data: Record<string, any>) => makeRequest({ method: 'put', url, data }),
  upload: async (url: string, file: Blob) => makeRequest({ method: 'post', url, data: { base64: await getBase64(file) } }),
};

export default request;
