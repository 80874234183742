import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  IAccountMapping,
  IAccountMappings,
  IGetAccountMapping,
  IAccountMappingCreate,
  IAccountMappingUpdate,
  ACCOUNT_MAPPING_GET,
  ACCOUNT_MAPPING_CREATE,
  ACCOUNT_MAPPING_UPDATE,
  ACCOUNT_MAPPING_FIND_BY_ID,
  ACCOUNT_MAPPING_FIND_BY_CODE,
  ACCOUNT_MAPPING_FIND_FIRST,
  ACCOUNT_MAPPING_FIND_LAST,
  ACCOUNT_MAPPING_FIND_ALL,
  ACCOUNT_MAPPING_CLEAR_DATA,
} from 'types/account.mapping.types';

const mappingCreate = createAsyncThunk<IAccountMapping, IAccountMappingCreate>(ACCOUNT_MAPPING_CREATE, async (data) => request.post('/account_mappings', { ...data }));
const mappingUpdate = createAsyncThunk<IAccountMapping, IAccountMappingUpdate>(ACCOUNT_MAPPING_UPDATE, async (data) =>
  request.put(`/account_mappings/id/${data.id}`, { ...data })
);
const mappingFindByID = createAsyncThunk<IAccountMapping, number, any>(ACCOUNT_MAPPING_FIND_BY_ID, async (id, query) =>
  request.get(`/account_mappings/id/${id}`, query)
);
const mappingGetAccount = createAsyncThunk<IAccountMapping, IGetAccountMapping>(ACCOUNT_MAPPING_GET, async(params) => request.post('account_mapping', params))
const mappingFindByCode = createAsyncThunk<IAccountMapping, string>(ACCOUNT_MAPPING_FIND_BY_CODE, async (code) => request.get(`/account_mappings/${code}`));
const mappingFindFirst = createAsyncThunk<IAccountMapping>(ACCOUNT_MAPPING_FIND_FIRST, async () => request.get('/account_mappings/find/first'));
const mappingFindLast = createAsyncThunk<IAccountMapping>(ACCOUNT_MAPPING_FIND_LAST, async () => request.get('/account_mappings/find/last'));
const mappingFindAll = createAsyncThunk<IAccountMappings, Partial<ITable>>(ACCOUNT_MAPPING_FIND_ALL, async (query) => request.get('/account_mappings', query));
const mappingClearData = createAction(ACCOUNT_MAPPING_CLEAR_DATA);

const AccountMappingActions = {
  mappingGetAccount,
  mappingCreate,
  mappingUpdate,
  mappingFindByID,
  mappingFindByCode,
  mappingFindFirst,
  mappingFindLast,
  mappingFindAll,
  mappingClearData,
};

export default AccountMappingActions;
