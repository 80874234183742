import { IFetch } from 'constants/types';

export const SUPPLIER_CREATE = 'SUPPLIER_CREATE';
export const SUPPLIER_UPDATE = 'SUPPLIER_UPDATE';
export const SUPPLIER_FIND_BY_ID = 'SUPPLIER_FIND_BY_ID';
export const SUPPLIER_FIND_BY_CODE = 'SUPPLIER_FIND_BY_CODE';
export const SUPPLIER_FIND_FIRST = 'SUPPLIER_FIND_FIRST';
export const SUPPLIER_FIND_LAST = 'SUPPLIER_FIND_LAST';
export const SUPPLIER_FIND_ALL = 'SUPPLIER_FIND_ALL';
export const SUPPLIER_CLEAR_DATA = 'SUPPLIER_CLEAR_DATA';

export interface SupplierState extends IFetch {
  data: ISupplier | undefined;
  list: ISupplier[];
  total: number;
}

export type ISupplier = {
  id: number;
  supplier_code: string;
  supplier_name: string;
  address: string;
  is_valid: number;
  account_id: number;
  combination_code?: string;
  combination_name?: string;
  company_id: string;
  created_by: string;
  created_date: string;
  last_saved_by: string;
  last_saved_date: string;
};

export type ISupplierCreate = {
  supplier_code: string;
  supplier_name: string;
  address: string;
  is_valid: number;
  account_id: number;
};

export interface ISupplierUpdate extends ISupplierCreate {
  id: number;
}

export type ISuppliers = {
  suppliers: Array<ISupplier>;
  total: number;
};
