import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  IItem,
  IItems,
  IItemCreate,
  IItemUpdate,
  ITEM_CREATE,
  ITEM_UPDATE,
  ITEM_FIND_BY_ID,
  ITEM_FIND_BY_CODE,
  ITEM_FIND_FIRST,
  ITEM_FIND_LAST,
  ITEM_FIND_ALL,
  ITEM_CLEAR_DATA,
} from 'types/item.types';

const itemCreate = createAsyncThunk<IItem, IItemCreate>(ITEM_CREATE, async (data) => request.post('/items', { ...data }));
const itemUpdate = createAsyncThunk<IItem, IItemUpdate>(ITEM_UPDATE, async (data) =>
  request.put(`/items/id/${data.id}`, { ...data })
);
const itemFindByID = createAsyncThunk<IItem, string, any>(ITEM_FIND_BY_ID, async (id, query) =>
  request.get(`/items/id/${id}`, query)
);
const itemFindByCode = createAsyncThunk<IItem, string>(ITEM_FIND_BY_CODE, async (code) => request.get(`/items/${code}`));
const itemFindFirst = createAsyncThunk<IItem>(ITEM_FIND_FIRST, async () => request.get('/items/find/first'));
const itemFindLast = createAsyncThunk<IItem>(ITEM_FIND_LAST, async () => request.get('/items/find/last'));
const itemFindAll = createAsyncThunk<IItems, Partial<ITable>>(ITEM_FIND_ALL, async (query) => request.get('/items', query));
const itemClearData = createAction(ITEM_CLEAR_DATA);

const ItemActions = {
  itemCreate,
  itemUpdate,
  itemFindByID,
  itemFindByCode,
  itemFindFirst,
  itemFindLast,
  itemFindAll,
  itemClearData,
};

export default ItemActions;
