import { IFetch } from 'constants/types';

export const PURCHASE_CREATE = 'PURCHASE_CREATE';
export const PURCHASE_UPDATE = 'PURCHASE_UPDATE';
export const PURCHASE_FIND_BY_ID = 'PURCHASE_FIND_BY_ID';
export const PURCHASE_FIND_BY_CODE = 'PURCHASE_FIND_BY_CODE';
export const PURCHASE_FIND_FIRST = 'PURCHASE_FIND_FIRST';
export const PURCHASE_FIND_LAST = 'PURCHASE_FIND_LAST';
export const PURCHASE_FIND_ALL = 'PURCHASE_FIND_ALL';
export const PURCHASE_CLEAR_DATA = 'PURCHASE_CLEAR_DATA';

export type PurchaseStatus = 'pending' | 'received' | 'posted' | 'cancelled';

export interface PurchaseState extends IFetch {
  data: IPurchase | undefined;
  list: IPurchase[];
  total: number;
}

export type IPurchase = {
  id: number;
  purchase_code: string;
  purchase_date: string;
  purchase_status: PurchaseStatus;
  total_amount: number;
  voucher_id?: number;
  voucher_code?: string;
  supplier_id: number;
  supplier_code?: string;
  supplier_name?: string;
  supplier_address?: string;
  account_id?: number;
  combination_code?: string;
  combination_name?: string;
  notes?: string;
  company_id: number;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
  details?: Array<IPurchaseDetail>;
};

export type IPurchaseDetail = {
  id: number;
  line: number;
  toDelete?: boolean | number;
  purchase_id: number;
  purchase_status: string;
  item_id?: string;
  item_code?: string;
  item_name?: string;
  purchase_quantity: number;
  quantity: number;
  unit_price: number;
  total_price: number;
  uom_id: number;
  uom_code?: string;
  uom_name?: string;
  purchase_uom_id: number;
  purchase_uom_code?: string;
  purchase_uom_name?: string;
  account_id?: number;
  combination_code?: string;
  combination_name?: string;
  debit_account_id?: number;
  debit_combination_code?: string;
  debit_combination_name?: string;
  company_id: number;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};

export interface CreatePurchaseDetail
  extends Pick<
    IPurchaseDetail,
    | 'line'
    | 'item_id'
    | 'purchase_status'
    | 'purchase_quantity'
    | 'quantity'
    | 'unit_price'
    | 'total_price'
    | 'uom_id'
    | 'purchase_uom_id'
    | 'account_id'
    | 'debit_account_id'
  > {
  id?: number;
}

export interface UpdatePurchaseDetail extends CreatePurchaseDetail {
  id?: number;
}

export interface IPurchaseCreate
  extends Pick<IPurchase, 'purchase_code' | 'purchase_date' | 'purchase_status' | 'total_amount' | 'supplier_id' | 'account_id' | 'notes'> {
  details: Array<CreatePurchaseDetail>;
}

export interface IPurchaseUpdate extends IPurchaseCreate {
  id?: number;
  details: Array<UpdatePurchaseDetail>;
  toDelete: Array<number>;
}

export interface PurchaseForm extends IPurchase {
  purchase_status_label: string;
  combination_code: string;
  combination_name: string;
}
