import { IFetch } from 'constants/types';

export const ACCESS_FIND = 'ACCESS_FIND';
export const ACCESS_USER_SESSION = 'ACCESS_USER_SESSION';

export type IAccess = {
  id: number;
  role_id: number;
  screen_id: string;
  can_view: boolean;
  can_edit: boolean;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};

export type IAccessUpdate = Array<{
  screen_id: string;
  can_view: number;
  can_edit: number;
}>;

export interface AccessState extends IFetch {
  list: IAccess[] | [];
  access: IAccess[] | [];
}
