import { IFetch } from 'constants/types';

export const CALENDAR_CREATE = 'CALENDAR_CREATE';
export const CALENDAR_UPDATE = 'CALENDAR_UPDATE';
export const CALENDAR_FIND_BY_YEAR = 'CALENDAR_FIND_BY_YEAR';
export const CALENDAR_FIND_PERIODS = 'CALENDAR_FIND_PERIODS';
export const CALENDAR_FIND_FIRST = 'CALENDAR_FIND_FIRST';
export const CALENDAR_FIND_LAST = 'CALENDAR_FIND_LAST';
export const CALENDAR_FIND_ALL = 'CALENDAR_FIND_ALL';
export const CALENDAR_CHECK_DATE = 'CALENDAR_CHECK_DATE';
export const CALENDAR_CLEAR_DATA = 'CALENDAR_CLEAR_DATA';

export interface CalendarState extends IFetch {
  data: ICalendarYear | undefined;
  list: ICalendarYear[];
  periods: ICalendarPeriod[],
  total: number;
  totalPeriods: number;
}

export type ICalendarYear = {
  calendar_year: number;
  company_id: string;
  periods?: Array<ICalendarPeriod>;
};

export type IPeriodStatus = 'OPEN' | 'CLOSED';

export type ICalendarPeriod = {
  id: number;
  year: number;
  line: number;
  start_date: string;
  end_date: string;
  status: IPeriodStatus;
  is_closed?: number;
  company_id: string;
  created_by: number;
  created_date: string;
  last_saved_by: number;
  last_saved_date: string;
};
export interface ICalendar extends Omit<ICalendarYear, 'periods'> {
  periods?: Array<{
    period: number;
    start_date: string;
    end_date: string;
    status: IPeriodStatus;
  }>;
}

export type ICreateCalendar = {
  company_id: string;
  calendar_year: number;
  periods: Array<{
    period: number;
    start_date: string;
    end_date: string;
    status: IPeriodStatus;
  }>;
};
export type IUpdateCalendar = {
  company_id: string;
  calendar_year: number;
  periods: Array<{
    id: number;
    period: number;
    start_date: string;
    end_date: string;
    status: IPeriodStatus;
  }>;
};
