import { createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';

import { IAccess, ACCESS_USER_SESSION, ACCESS_FIND } from 'types/access.types';

const accessUserSession = createAsyncThunk<IAccess, number>(ACCESS_USER_SESSION, async (role_id) => request.get(`/access/role/${role_id}`));
const accessFindByID = createAsyncThunk<IAccess, number>(ACCESS_FIND, async (role_id) => request.get(`/access/role/${role_id}`));

const AccessActions = { accessUserSession, accessFindByID };

export default AccessActions;
