import React from 'react';

import { ICustomer, ILoyalty, ISale } from 'types';
import CustomerLoyaltyCard, { ILoyaltyCard } from 'components/LoyaltyCard';
import SaleInfo from 'pages/Public/SaleInfo';

interface IProps extends Pick<ILoyaltyCard, 'handleClickCard'> {
  loyalty: ILoyalty;
  sale?: ISale;
  customer?: ICustomer;
  totalCards: number;
  showSaleDetails: boolean;
  handleHideSaleDetails: () => void;
}

const CustomerLoyalty: React.FC<IProps> = ({
  sale,
  loyalty,
  customer,
  totalCards,
  showSaleDetails,
  handleHideSaleDetails,
  handleClickCard,
}) => (
  <div className="panel w-100">
    <SaleInfo sale={sale} show={showSaleDetails} handleExit={handleHideSaleDetails} />
    <div className="panel-hdr bg-primary-300 bg-primary-gradient">
      <h2 className="white card-title">Customer Loyalty Card </h2>
    </div>
    <div className="panel-container show">
      <div className="panel-content bd-highlight  border border-primary border-top-0">
        <div className="d-flex flex-wrap bd-highlight mb-3">
          <h3 className="card-title">{customer?.customer_name}</h3>
        </div>

        {loyalty && loyalty.map((card, index) => (
          <CustomerLoyaltyCard
            key={index + 1}
            card={card}
            cardNumber={totalCards - index}
            handleClickCard={handleClickCard} />
        ))}
      </div>
    </div>
  </div>
);


export default CustomerLoyalty;
