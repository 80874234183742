import React from 'react';

import useRoleAccess from 'hooks/useRoleAccess';

type MenuList = {
  path: string;
  name: string;
  icon: string;
  restricted?: boolean;
};

export interface IMasterData extends Pick<IMenuIcon, 'handleMenuItem'> {
  company?: string;
}

interface IMenuIcon extends MenuList {
  company?: string;
  handleMenuItem: (path: string) => void;
}

const mainMenuList: MenuList[] = [
  {
    path: '/master/customers',
    name: 'Customers',
    restricted: true,
    icon: 'fad fa-user-friends',
  },
  {
    path: '/master/suppliers',
    name: 'Suppliers',
    restricted: true,
    icon: 'fad fa-truck',
  },
  {
    path: '/master/banks',
    name: 'Banks',
    restricted: true,
    icon: 'fad fa-landmark',
  },
  {
    path: '/master/items',
    name: 'Items',
    restricted: true,
    icon: 'fad fa-box-full',
  },
];

const MenuIcon: React.FC<IMenuIcon> = ({ path, name, company, restricted, icon, handleMenuItem }) => {
  return (
    <div className="d-flex flex-column justify-content-center ">
      <div
        className={`d-flex flex-column justify-content-center btn waves-effect waves-themed ${
          !company && restricted ? 'disabled' : 'cursor-pointer'
        }`}
        onClick={!company && restricted ? undefined : () => handleMenuItem(path)}
      >
        <span className="fa-4x d-inline l-h-n">
          <i className={`${icon}${!company && restricted ? ' disabled' : ''}`} />
        </span>
        <span>{name}</span>
      </div>
    </div>
  );
};

const MasterData: React.FC<IMasterData> = ({ company, handleMenuItem }) => {
  const { filterMenu } = useRoleAccess();
  return filterMenu(mainMenuList).length > 0 ? (
    <div className="panel">
      <div className="panel-hdr bg-success-500 bg-success-gradient">
        <h2 className="white">Master Data</h2>
      </div>
      <div className="panel-container show">
        <div className="panel-content row justify-content-around bd-highlight">
          {filterMenu(mainMenuList).map((menu: MenuList) => (
            <MenuIcon key={menu.path} {...menu} company={company} handleMenuItem={handleMenuItem} />
          ))}
        </div>
      </div>
    </div>
  ) : <></>;
};

export default MasterData;
