import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { SupplierState } from 'types/supplier.types';
import SupplierAction from 'store/actions/supplier.action';

export const SupplierInitialState: SupplierState = {
  data: undefined,
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type SupplierType = typeof SupplierInitialState;

const handleSupplier = (state: SupplierState, action: any) => {
  const { supplier = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: supplier,
    next,
    previous,
  };
};

const supplierReducer = createSlice({
  name: 'Supplier',
  initialState: SupplierInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SupplierAction.supplierFindAll.fulfilled, (state, action) => {
        const { suppliers: list = [], total = 0 } = get(action, 'payload.data', { suppliers: [], total: 0 });
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(SupplierAction.supplierCreate.fulfilled, handleSupplier)
      .addCase(SupplierAction.supplierUpdate.fulfilled, handleSupplier)
      .addCase(SupplierAction.supplierFindByID.fulfilled, handleSupplier)
      .addCase(SupplierAction.supplierFindByCode.fulfilled, handleSupplier)
      .addCase(SupplierAction.supplierFindFirst.fulfilled, handleSupplier)
      .addCase(SupplierAction.supplierFindLast.fulfilled, handleSupplier)
      .addCase(SupplierAction.supplierClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default supplierReducer.reducer;
