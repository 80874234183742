export enum TableColumn {
    Text = 'text',
    Number = 'number',
    Currency = 'currency',
    Date = 'date',
    Select = 'select',
}

export const TableColumnClass = {
    [TableColumn.Number]: 'text-right width-xs',
    [TableColumn.Currency]: 'text-right',
    [TableColumn.Text]: '',
    [TableColumn.Date]: '',
    [TableColumn.Select]: '',
};