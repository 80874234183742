import React from 'react';

import { IRoute, IRouteLinks } from 'constants/interface';

export const MasterLinks: IRouteLinks = {
  id: 'master',
  title: 'Master Data',
  icon: 'books',
  sort: 2,
  routes: [
    {
      id: 'customers',
      title: 'Customers',
      path: '/master/customers',
      sort: 1, 
      noNew: true,
      noDelete: true,
      companyRestricted: true,
    },
    {
      id: 'suppliers',
      title: 'Suppliers',
      path: '/master/suppliers',
      noDelete: true,
      sort: 2,
      companyRestricted: true,
    },
    {
      id: 'banks',
      title: 'Banks',
      path: '/master/banks',
      noDelete: true,
      sort: 3,
      companyRestricted: true,
    },
    {
      id: 'items',
      title: 'Items',
      path: '/master/items',
      noDelete: true,
      sort: 4,
      companyRestricted: true,
    },
  ],
};

const MasterRouter: IRoute[] = [
  {
    path: '/master/customers',
    component: React.lazy(() => import('containers/MasterData/CustomerContainer')),
  },
  {
    path: '/master/suppliers',
    component: React.lazy(() => import('containers/MasterData/SupplierContainer')),
  },
  {
    path: '/master/banks',
    component: React.lazy(() => import('containers/MasterData/BankContainer')),
  },
  {
    path: '/master/items',
    component: React.lazy(() => import('containers/MasterData/ItemContainer')),
  },
];

export default MasterRouter;
