import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { AccessState } from 'types/access.types';
import AccessAction from 'store/actions/access.action';

export const AccessInitialState: AccessState = {
  list: [],
  access: [],
  fetching: false,
  fetched: false,
  error: null,
};

export type AccessType = typeof AccessInitialState;

const accessReducer = createSlice({
  name: 'Access',
  initialState: AccessInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AccessAction.accessUserSession.fulfilled, (state, action) => ({
        ...state,
        access: get(action, 'payload.data', []),
      }))
      .addCase(AccessAction.accessFindByID.fulfilled, (state, action) => ({
        ...state,
        list: get(action, 'payload.data', []),
      }));
  },
});

export default accessReducer.reducer;
